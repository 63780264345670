import { ArcadePartCategory, RewardType } from '@victgame/akiverse-backend/dist/quests/types'
import { GameInfo } from '../stores/Games/useGames'

type AccumulatorId =
  | 'HOKUTO_100_LX'
  | 'HOKUTO_120_LX'
  | 'HOKUTO_140_LX'
  | 'RYGEN_NA_1'
  | 'RYGEN_NA_2'
  | 'RYGEN_NA_3'
  | 'RYGEN_NB_1'
  | 'YAMABIKO_2200'
  | 'YAMABIKO_4400'
  | 'YAMABIKO_6600'
type CabinetCategoryId =
  | 'PLAIN'
  | 'MERCURY'
  | 'VENUS'
  | 'EARTH'
  | 'MARS'
  | 'JUPITER'
  | 'SATURN'
  | 'URANUS'
  | 'NEPTUNE'
  | 'PLUTO'

const ARCADE_PART_NAME = {
  ACCUMULATOR: {
    HOKUTO_100_LX: 'Hokuto100LX',
    HOKUTO_120_LX: 'Hokuto120LX',
    HOKUTO_140_LX: 'Hokuto140LX',
    RYGEN_NA_1: 'Rygen NA1',
    RYGEN_NA_2: 'Rygen NA2',
    RYGEN_NA_3: 'Rygen NA3',
    RYGEN_NB_1: 'Rygen NB1',
    YAMABIKO_2200: 'Yamabiko 2200',
    YAMABIKO_4400: 'Yamabiko 4400',
    YAMABIKO_6600: 'Yamabiko 6600',
  },
  UPPER_CABINET: {
    PLAIN: 'Plain',
    MERCURY: 'Mercury',
    VENUS: 'Venus',
    EARTH: 'Earth',
    MARS: 'Mars',
    JUPITER: 'Jupiter',
    SATURN: 'Saturn',
    URANUS: 'Uranus',
    NEPTUNE: 'Neptune',
    PLUTO: 'Pluto',
  },
  LOWER_CABINET: {
    PLAIN: 'Plain',
    MERCURY: 'Mercury',
    VENUS: 'Venus',
    EARTH: 'Earth',
    MARS: 'Mars',
    JUPITER: 'Jupiter',
    SATURN: 'Saturn',
    URANUS: 'Uranus',
    NEPTUNE: 'Neptune',
    PLUTO: 'Pluto',
  },
}

export const getArcadePartName = (
  category: ArcadePartCategory,
  subCategory: string,
  games: Array<GameInfo>,
) => {
  switch (category) {
    case 'ROM':
      const game = games.find((e) => e.id === subCategory)
      const rom = game?.name
      return rom ? `${rom} ROM` : ''
    case 'ACCUMULATOR':
      const acc = ARCADE_PART_NAME?.['ACCUMULATOR']?.[subCategory as AccumulatorId]
      return acc ? `${acc} Accumulator` : ''
    case 'UPPER_CABINET':
      const uc = ARCADE_PART_NAME?.['UPPER_CABINET']?.[subCategory as CabinetCategoryId]
      return uc ? `${uc} Upper Cabinet` : ''
    case 'LOWER_CABINET':
      const lc = ARCADE_PART_NAME?.['LOWER_CABINET']?.[subCategory as CabinetCategoryId]
      return lc ? `${lc} Lower Cabinet` : ''
  }
}

export const getJunkArcadePartName = (
  category: ArcadePartCategory,
  subCategory: string,
  games: Array<GameInfo>,
) => {
  const partName = getArcadePartName(category, subCategory, games)
  return partName ? `junk ${partName}` : ''
}

type CollectibleItemCategory = 'ICON' | 'TITLE' | 'FRAME'
export const getCollectibleItemName = (category: CollectibleItemCategory, subCategory: string) => {
  return category === 'ICON'
    ? getIconName(subCategory)
    : category === 'TITLE'
    ? getTitleName(subCategory)
    : category === 'FRAME'
    ? getFrameName(subCategory)
    : ''
}

type IconCollectibleItemType = {
  category: CollectibleItemCategory
  subCategory: string
  name: string
}

const icons: Record<string, IconCollectibleItemType> = {
  DEFAULT: {
    category: 'ICON',
    subCategory: 'DEFAULT',
    name: 'Default Icon',
  },
  YUMMY_JUMP: {
    category: 'ICON',
    subCategory: 'YUMMY_JUMP',
    name: 'Yummy Jump',
  },
  COMMEMORATION_YUMMY_JUMP: {
    category: 'ICON',
    subCategory: 'COMMEMORATION_YUMMY_JUMP',
    name: 'Profile Pic Commemoration YummyJump',
  },
}

export const getIconName = (subCategory: string) => {
  return icons?.[subCategory]?.name ?? ''
}

export type TitleType = 'Onward' | 'Event' | 'Default'

type TitleColor = { type: TitleType; startColor: string; endColor: string; middlePoint: string }

type TitleCollectibleItemType = {
  type: TitleType
  subCategory: string
  name: string
  startColor: string
  endColor: string
  middlePoint: string
  howToGet: string
}

const TITLE_SPARKER: TitleColor = {
  type: 'Onward',
  startColor: '#CADB00',
  endColor: '#38F159',
  middlePoint: '0.11%',
}

const TITLE_EVENT: TitleColor = {
  type: 'Event',
  startColor: '#0073D9',
  endColor: '#DD32AD',
  middlePoint: '0.12%',
}

const gettingTitleLabel = (name: string) => `Complete the entire "${name}" quest chain`

export const titles: Record<string, TitleCollectibleItemType> = {
  ONWARD_COOL_NEWBIE: {
    type: 'Onward',
    subCategory: 'ONWARD_COOL_NEWBIE',
    name: 'Cool Newbie',
    startColor: '#38F159',
    endColor: '#00D7D8',
    middlePoint: '0.11%',
    howToGet: gettingTitleLabel('Welcome Road'),
  },
  ONWARD_LITTLE_SPARKER: {
    subCategory: 'ONWARD_LITTLE_SPARKER',
    name: 'Little Sparker',
    howToGet: gettingTitleLabel('A New Journey'),
    ...TITLE_SPARKER,
  },
  // ONWARD_JUNIOR_SPARKER: {
  //   subCategory: 'ONWARD_JUNIOR_SPARKER',
  //   name: 'Junior Sparker',
  //   howToGet: gettingTitleLabel('A New Journey'),
  //   ...TITLE_SPARKER,
  // },
  // ONWARD_MIDDLE_SPARKER: {
  //   subCategory: 'ONWARD_MIDDLE_SPARKER',
  //   name: 'Middle Sparker',
  //   howToGet: gettingTitleLabel('A New Journey'),
  //   ...TITLE_SPARKER,
  // },
  // ONWARD_SENIOR_SPARKER: {
  //   subCategory: 'ONWARD_SENIOR_SPARKER',
  //   name: 'Senior Sparker',
  //   howToGet: gettingTitleLabel('A New Journey'),
  //   ...TITLE_SPARKER,
  // },
  EVENT_NIGERIA_202309: {
    subCategory: 'EVENT_NIGERIA_202309',
    name: 'Hello Nigeria!',
    howToGet: gettingTitleLabel('Hello Nigeria!'),
    ...TITLE_EVENT,
  },
  EVENT_BRAZIL_202311: {
    subCategory: 'EVENT_BRAZIL_202311',
    name: 'Hello Brazil!',
    howToGet: gettingTitleLabel('Hello Brazil!'),
    ...TITLE_EVENT,
  },
  DEFAULT: {
    type: 'Default',
    subCategory: 'DEFAULT',
    name: 'Marebito',
    startColor: '#38F159',
    endColor: '#00D7D8',
    middlePoint: '0.11%',
    howToGet: 'Signing up for the Akiverse',
  },
}

export const getTitleName = (subCategory: string) => titles?.[subCategory]?.name ?? ''

type FrameCollectibleItemType = {
  category: CollectibleItemCategory
  subCategory: string
  name: string
}

const frames: Record<string, FrameCollectibleItemType> = {
  DEFAULT: {
    category: 'FRAME',
    subCategory: 'DEFAULT',
    name: 'Default',
  },
  HOLY_STAR: {
    category: 'FRAME',
    subCategory: 'HOLY_STAR',
    name: 'Reward Frame Holy Star',
  },
}

export const getFrameName = (subCategory: string) => {
  return frames?.[subCategory]?.name ?? ''
}

export const getRandomName = (reward: RewardType) => {
  if (reward.itemType === 'ARCADE_PART_RANDOM') {
    return 'Random Arcade Parts'
  }

  if (reward.itemType === 'JUNK_PART_RANDOM') {
    return 'Random Junk Parts'
  }

  return ''
}
