import { GameCenterSize } from '../graphql/generated/types'

export const DISPLAY_VERSION = 'Open Beta v3.0'

export const INSTALLABLE_AM_COUNST: Record<GameCenterSize, number> = {
  LARGE: 64,
  MEDIUM: 16,
  SMALL: 4,
}

export const MAX_USERNAME_LENGTH = 20

export const S3_URL = 'https://assets.akiverse.io/front-end'

export const TERM_TIME_ZONE = 'Asia/Tokyo'
// UTC
export const UTC_TIME_ZONE = 'Etc/GMT'
