import React from 'react'
import NextHead from 'next/head'

type Props = {
  title?: string
  description?: string
}

export const Head: React.VFC<Props> = (props) => {
  return (
    <NextHead>
      <title>{props.title ? `${props.title} | ` : ''}Akiverse</title>
      <meta name="description" content={props.description} />
      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  )
}
