import React, { CSSProperties, PropsWithChildren } from 'react'

export const Center: React.FC<
  PropsWithChildren<{ className?: string | undefined; style?: CSSProperties | undefined }>
> = ({ children, className, style }) => {
  return (
    <div className={`flex flex-row items-center justify-center ${className ?? ''}`} style={style}>
      {children}
    </div>
  )
}
