import React, { CSSProperties, PropsWithChildren } from 'react'

export const VStack: React.FC<
  PropsWithChildren<{
    className?: string | undefined
    style?: CSSProperties | undefined
    onClick?: () => void
  }>
> = ({ children, className, style, onClick }) => {
  return (
    <div className={`relative flex w-full flex-col ${className ?? ''}`} style={style} onClick={onClick}>
      {children}
    </div>
  )
}
