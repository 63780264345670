import React, { CSSProperties, PropsWithChildren } from 'react'

export const Absolute: React.FC<
  PropsWithChildren<{ className?: string | undefined; style?: CSSProperties }>
> = ({ children, className, style }) => {
  return (
    <div className={`absolute flex ${className ?? ''}`} style={style}>
      {children}
    </div>
  )
}
