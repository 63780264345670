import React, { PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import { useWindowSize } from 'react-use'
import { Center } from '../parts/bases/Center'
import { VStack } from '../parts/bases/VStack'

const PC_BREAKPOINT = 800

export const AccessBlockingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const window = useWindowSize()

  return (
    <>
      {window.width <= PC_BREAKPOINT ? (
        children
      ) : (
        <Center className="h-screen w-screen">
          <VStack className="w-4/5 rounded-xl border-2 border-neon-blue bg-black p-6 text-center text-sm text-white">
            <FormattedMessage id="AccessBlockingProvider.Message" />
          </VStack>
        </Center>
      )}
    </>
  )
}
