import { request } from 'graphql-request'
import {
  LoginDocument,
  LoginQueryVariables,
  TokenRefreshDocument,
  TokenRefreshQueryVariables,
  SignupDocument,
  SignupMutationVariables,
  User,
} from './query.generated'

export const graphqlClient = {
  login: (params: { didToken: string }) =>
    request<{ login: { accessToken: string; refreshToken: string } }, LoginQueryVariables>(
      process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
      LoginDocument,
      { didToken: params.didToken },
    ),
  refreshToken: (params: { refreshToken: string }) =>
    request<{ tokenRefresh: { firebaseCustomToken: string } }, TokenRefreshQueryVariables>(
      process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
      TokenRefreshDocument,
      { refreshToken: params.refreshToken },
    ),
  signup: (params: { name?: string; idToken?: string }) =>
    request<{ createUser: { user: User } }, SignupMutationVariables>(
      process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
      SignupDocument,
      { input: { name: params.name, idToken: params.idToken } },
    ),
}
