export const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ''

export const AnalyticsService = {
  setUserId: (userId: string) => {
    window.dataLayer.push({ user_id: userId })
  },
  sendGameScore: (params: { score?: number; gameId: string }) => {
    window.dataLayer.push({
      gameId: params.gameId,
      score: params.score,
      event: 'get_score',
    })
  },
}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
  }
}
