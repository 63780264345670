import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import Image from 'next/image'
import { Center } from '../parts/bases/Center'
import { Absolute } from '../parts/bases/Absolute'
import { Text } from '../parts/bases/Text'
import { DISPLAY_VERSION } from '@/src/constants'

type Props = {
  children: ReactNode
  splashDuration?: number // seconds
}

const DEFAULT_SPLASH_DURATION = 1
export const SplashProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  splashDuration = DEFAULT_SPLASH_DURATION,
}) => {
  const [visibleSplash, setVisibleSplash] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setVisibleSplash(false)
    }, splashDuration * 1000)
  }, [splashDuration])

  return visibleSplash ? <SplashScreen /> : <>{children}</>
}

const SplashScreen = () => {
  return (
    <Center className="h-screen w-screen">
      <Center className="relative aspect-[862/207] w-[85%]">
        <Image priority src="/images/logo_blank.png" layout="fill" alt="Logo" />
        <Absolute className="-bottom-1">
          <Text
            className="text-base font-semibold text-white"
            style={{ textShadow: '0px 0px 6px rgba(255, 255, 255, 0.7)' }}
          >
            {DISPLAY_VERSION}
          </Text>
        </Absolute>
      </Center>
    </Center>
  )
}
