import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { AnalyticsService } from '../../services/AnalyticsService'
import { IconType, useLoginUserLazyQuery } from './query.generated'
import { S3URLService } from '@/src/services/S3URLService'
import { titles } from '@/src/beta/reward'
import { AuthService } from '@/src/services/AuthService'

type LoginUser = {
  email: string
  id: string
  name: string
  terasBalance: number
  iconType: IconType
  iconSubCategory: string
  frameSubCategory: string
  titleSubCategory: string
  userIconUrl: string
  userFrameUrl: string
  userTitle: {
    title: string
    icon: string
    startColor: string
    endColor: string
    middlePoint: string
  }
  reload: () => Promise<unknown>
}

const LoginUserContext = React.createContext<{
  loginUser?: LoginUser
  isLoading: boolean
  reload: () => Promise<unknown>
}>({} as any)

export const useLoginUser = () => useContext(LoginUserContext)

export const LoginUserProvider = ({ children }: PropsWithChildren) => {
  const [getLoginUser, { data, refetch, loading, error }] = useLoginUserLazyQuery()
  const [loginUserAuthChecked, setLoginUserAuthChecked] = useState(false)

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      getLoginUser()
    }
    setLoginUserAuthChecked(true)
  }, [getLoginUser])

  useEffect(() => {
    if (data?.currentUser?.id) {
      AnalyticsService.setUserId(data.currentUser.id)
    }
  }, [data])
  const reload = useCallback(() => refetch(), [refetch])
  return (
    <LoginUserContext.Provider
      value={{
        loginUser: error
          ? undefined
          : data?.currentUser
          ? {
              email: data.currentUser.email,
              id: data.currentUser.id,
              name: data.currentUser.name,
              terasBalance: Number(data.currentUser.terasBalance),
              iconType: data.currentUser.iconType,
              iconSubCategory: data.currentUser.iconSubCategory,
              frameSubCategory: data.currentUser.frameSubCategory,
              titleSubCategory: data.currentUser.titleSubCategory,
              userIconUrl: S3URLService.getIconURL(
                data.currentUser.iconType,
                data.currentUser.iconSubCategory,
              ),
              userFrameUrl: S3URLService.getFrameURL(data.currentUser.frameSubCategory),
              userTitle: {
                title: titles?.[data.currentUser.titleSubCategory]?.name ?? '',
                icon: S3URLService.getTitleURL(data.currentUser.titleSubCategory),
                startColor: titles?.[data.currentUser.titleSubCategory]?.startColor ?? '',
                endColor: titles?.[data.currentUser.titleSubCategory]?.endColor ?? '',
                middlePoint: titles?.[data.currentUser.titleSubCategory]?.middlePoint ?? '',
              },
              reload,
            }
          : undefined,
        reload,
        isLoading: loading || !loginUserAuthChecked,
      }}
    >
      {children}
    </LoginUserContext.Provider>
  )
}
