import Script from 'next/script'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { AuthService } from '../services/AuthService'
import { useLatestPlayLazyQuery } from './query.generated'
import { SortOrder } from '../graphql/generated/types'
import { useRouter } from 'next/router'
import { PagePaths } from '../utils/PagePaths'

const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT

declare global {
  interface Window {
    odencanWebLoadCallback: () => void
    odencanWeb: {
      OCWInitialize: (apiUrl: string, authorizationValue: string, arcadeMachineId: string) => Promise<void>
      OCWRegisterGameClosedCallback: (callabck: (playSessionId: string) => void) => void
    }
  }
}

export const OdencanSDKLoader: React.FC<PropsWithChildren> = ({ children }) => {
  const [jqueryLoaded, setJqueyLoaded] = useState(false)
  const [odencanLoaded, setOdencanLoaded] = useState(false)
  useEffect(() => {
    window.odencanWebLoadCallback = () => {
      setOdencanLoaded(true)
    }
  }, [])
  return (
    <>
      <Script
        id="jquery"
        src="https://code.jquery.com/jquery-3.6.0.min.js"
        onReady={() => {
          setJqueyLoaded(true)
        }}
      />
      <Script
        id="odencanWeb"
        dangerouslySetInnerHTML={{
          __html: `import('/scripts/odencanWeb.js').then(result => {
        window.odencanWeb = result
        window.odencanWebLoadCallback && window.odencanWebLoadCallback()
      })
    `,
        }}
      />
      <>{jqueryLoaded && odencanLoaded ? children : null}</>
    </>
  )
}

type GameFrameProps = {
  gameUrl: string
  arcadeMachineId: string
  onResult: (result: {
    score?: number
    playerTerasReward?: number
    megaSpark: boolean
    fever: boolean
  }) => void
}

export const OdencanGameView = ({ arcadeMachineId, gameUrl, onResult }: GameFrameProps) => {
  const router = useRouter()
  const [initialiezd, setInitialized] = useState(false)
  const initCount = useRef<number>(0) // odencanWeb.OCWInitializeが2回以上呼ばれるとエラーを吐くので必ず1回しか呼ばないようにするため
  const [latestPlayQuery] = useLatestPlayLazyQuery()
  useEffect(() => {
    if (!initialiezd && initCount.current === 0) {
      initCount.current = initCount.current + 1
      AuthService.getAuthorizationToken().then(async (token) => {
        await window.odencanWeb.OCWInitialize(GRAPHQL_ENDPOINT, token, arcadeMachineId)
        setInitialized(true)
      })
    }
  }, [arcadeMachineId, initialiezd])

  useEffect(() => {
    window.odencanWeb.OCWRegisterGameClosedCallback(async (playSessionId) => {
      const result = await latestPlayQuery({
        variables: { where: { id: playSessionId }, orderBy: [{ createdAt: SortOrder.Desc }], take: 1 },
      })
      if (result.error) {
        console.error('Error')
        router.push(PagePaths.top())
      } else {
        const play = result.data?.playSession?.plays[0] ?? {
          playerTerasReward: undefined,
          score: undefined,
          megaSpark: undefined,
        }
        const score: number | undefined =
          play.score === 0 || play.score === 1 ? undefined : play.score ?? undefined // scoreが0 or 1の場合はゲームばバトル形式のもんなのでスコアは表示しない
        onResult({
          score,
          playerTerasReward: play?.playerTerasReward ? Number(play?.playerTerasReward) : undefined,
          megaSpark: !!play?.megaSpark,
          fever: !!result.data?.playSession?.fever,
        })
      }
    })
  }, [latestPlayQuery, onResult, router])

  return initialiezd ? (
    <iframe
      src={gameUrl}
      className="fixed bottom-0 left-0 right-0 top-0 z-50 m-0 h-full w-full overflow-hidden p-0"
    />
  ) : null
}
