import React, { PropsWithChildren } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, createHttpLink } from '@apollo/client'
import { AuthService } from '../services/AuthService'
import { onError } from '@apollo/client/link/error'
import { LocalStorageService } from '../services/LocalStorageService'
import { graphqlClient } from './client'
import { signInWithCustomToken } from 'firebase/auth'
import { auth } from '../services/FirebaseService'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        if (extensions.code === 'USER_LOCKED') {
          LocalStorageService.saveAccountLock()
        }
      }
    })
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const cache = new InMemoryCache()
const client = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  link: ApolloLink.from([
    errorLink,
    createHttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
      fetch: async (uri, options = {}) => {
        LocalStorageService.removeAccountLock()
        const authorizationToken = await AuthService.getAuthorizationToken()
        const headers = options.headers
          ? { ...options.headers, Authorization: authorizationToken }
          : { Authorization: authorizationToken }

        // ログで確認しやすいようにoperationNameを付与する
        const { operationName } = JSON.parse(options.body as string)
        const customUri = `${uri}?operationName=${operationName}`

        const fetchResult = await fetch(customUri, { ...options, headers })
        // 2回中身を取り出すとエラーになるのでここではcloneしてから中身を確認する
        const res = await fetchResult.clone().json()

        // アクセストークンリフレッシュ処理
        const errorCode = res?.errors?.[0]?.extensions?.code
        const refreshToken = AuthService.getRefreshToken()
        if (errorCode === 'UNAUTHENTICATED' && !!refreshToken) {
          const refreshTokenRes = await graphqlClient.refreshToken({ refreshToken })
          const firebaseCustomToken = refreshTokenRes?.tokenRefresh?.firebaseCustomToken
          await AuthService.removeTokens()
          await signInWithCustomToken(auth, firebaseCustomToken)
          return fetch(customUri, {
            ...options,
            headers: { ...headers, Authorization: await AuthService.getAuthorizationToken() },
          })
        } else if (errorCode === 'CLAIM_UPDATED') {
          await auth.currentUser?.getIdToken(true)
          return fetch(customUri, {
            ...options,
            headers: { ...headers, Authorization: await AuthService.getAuthorizationToken() },
          })
        } else {
          return fetchResult
        }
      },
    }),
  ]),
  cache,
  defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
})

export const ApolloProviderWrapper: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
