//　TODO: pages から自動生成できるはず
// 引数なしはPrefetch用に"[id]"をもたせる

export const PagePaths = {
  top: () => '/top',
  error404: () => '/404',
  accountLocked: () => '/account-locked',
  mailSendComplete: () => '/mail-send-complete',
  gameCenter: (params: { gameCenterId: string }) => `/game-centers/${params.gameCenterId}`,
  arcademachineDetail: (params: { arcadeMachineId: string }) => `/arcade-machines/${params.arcadeMachineId}`,
  settings: () => '/settings',
  changeIcon: () => '/change-icon',
  changeTitle: () => '/change-title',
  login: () => `/login`,
  arcadeGamePlay: (params: { arcadeMachineId: string }) => `/arcade-machines/${params.arcadeMachineId}/play`,
  wallet: () => '/wallet',
  arcadeMachineSearch: (gameId: string) => `/arcade-machines/search/${gameId}`,
  quest: (tabInfo?: { tabType: 'ONWARD' | 'EVENT' | 'PREMIUM' | 'VIP'; questChainMasterId: string }) =>
    `/quest${tabInfo ? `?tabType=${tabInfo.tabType}&questChainMasterId=${tabInfo.questChainMasterId}` : ''}`,
  ranking: () => '/ranking',
}
