import React, { PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'
import { useRouter } from 'next/router'
import { strings } from './strings'

export const I18nProvider = ({ children }: PropsWithChildren) => {
  const locale = useRouter().locale ?? 'en' // TODO: デフォルトの場合enが出力されて欲しい
  const localeStrings = strings[locale]
  return (
    <IntlProvider messages={localeStrings} locale={locale}>
      {children}
    </IntlProvider>
  )
}
