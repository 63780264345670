import React, { CSSProperties, PropsWithChildren } from 'react'

export const Text: React.FC<
  PropsWithChildren<{ className?: string | undefined; style?: CSSProperties | undefined }>
> = ({ children, className, style }) => {
  return (
    <span className={`${className ?? ''}`} style={style}>
      {children}
    </span>
  )
}
