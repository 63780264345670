import * as Types from '../graphql/generated/types.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  JSON: any;
};

export type AcceptReward = {
  __typename?: 'AcceptReward';
  amount: Scalars['Float'];
  category?: Maybe<Scalars['String']>;
  itemType: Scalars['String'];
  name: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
};

export type AcceptRewardsOutput = {
  __typename?: 'AcceptRewardsOutput';
  rewards: Array<AcceptReward>;
};

export type ActiveBooster = {
  __typename?: 'ActiveBooster';
  category: BoosterCategory;
  createdAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  id: Scalars['String'];
  subCategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ActiveBoosterForTournament = {
  __typename?: 'ActiveBoosterForTournament';
  category: BoosterCategory;
  createdAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  id: Scalars['String'];
  paidTournamentId: Scalars['String'];
  subCategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ActiveBoosterForTournamentListRelationFilter = {
  every?: InputMaybe<ActiveBoosterForTournamentWhereInput>;
  none?: InputMaybe<ActiveBoosterForTournamentWhereInput>;
  some?: InputMaybe<ActiveBoosterForTournamentWhereInput>;
};

export type ActiveBoosterForTournamentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActiveBoosterForTournamentOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paidTournament?: InputMaybe<PaidTournamentOrderByWithRelationInput>;
  paidTournamentId?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum ActiveBoosterForTournamentScalarFieldEnum {
  Category = 'category',
  CreatedAt = 'createdAt',
  EndAt = 'endAt',
  Id = 'id',
  PaidTournamentId = 'paidTournamentId',
  SubCategory = 'subCategory',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ActiveBoosterForTournamentUserIdPaidTournamentIdCategorySubCategoryCompoundUniqueInput = {
  category: BoosterCategory;
  paidTournamentId: Scalars['String'];
  subCategory: Scalars['String'];
  userId: Scalars['String'];
};

export type ActiveBoosterForTournamentWhereInput = {
  AND?: InputMaybe<Array<ActiveBoosterForTournamentWhereInput>>;
  NOT?: InputMaybe<Array<ActiveBoosterForTournamentWhereInput>>;
  OR?: InputMaybe<Array<ActiveBoosterForTournamentWhereInput>>;
  category?: InputMaybe<EnumBoosterCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  paidTournament?: InputMaybe<PaidTournamentRelationFilter>;
  paidTournamentId?: InputMaybe<UuidFilter>;
  subCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type ActiveBoosterForTournamentWhereUniqueInput = {
  AND?: InputMaybe<Array<ActiveBoosterForTournamentWhereInput>>;
  NOT?: InputMaybe<Array<ActiveBoosterForTournamentWhereInput>>;
  OR?: InputMaybe<Array<ActiveBoosterForTournamentWhereInput>>;
  category?: InputMaybe<EnumBoosterCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  paidTournament?: InputMaybe<PaidTournamentRelationFilter>;
  paidTournamentId?: InputMaybe<UuidFilter>;
  subCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  userId_paidTournamentId_category_subCategory?: InputMaybe<ActiveBoosterForTournamentUserIdPaidTournamentIdCategorySubCategoryCompoundUniqueInput>;
};

export type ActiveBoosterListRelationFilter = {
  every?: InputMaybe<ActiveBoosterWhereInput>;
  none?: InputMaybe<ActiveBoosterWhereInput>;
  some?: InputMaybe<ActiveBoosterWhereInput>;
};

export type ActiveBoosterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActiveBoosterOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum ActiveBoosterScalarFieldEnum {
  Category = 'category',
  CreatedAt = 'createdAt',
  EndAt = 'endAt',
  Id = 'id',
  SubCategory = 'subCategory',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ActiveBoosterUnionOutput = ActiveBooster | ActiveBoosterForTournament;

export type ActiveBoosterUserIdCategorySubCategoryCompoundUniqueInput = {
  category: BoosterCategory;
  subCategory: Scalars['String'];
  userId: Scalars['String'];
};

export type ActiveBoosterWhereInput = {
  AND?: InputMaybe<Array<ActiveBoosterWhereInput>>;
  NOT?: InputMaybe<Array<ActiveBoosterWhereInput>>;
  OR?: InputMaybe<Array<ActiveBoosterWhereInput>>;
  category?: InputMaybe<EnumBoosterCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  subCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type ActiveBoosterWhereUniqueInput = {
  AND?: InputMaybe<Array<ActiveBoosterWhereInput>>;
  NOT?: InputMaybe<Array<ActiveBoosterWhereInput>>;
  OR?: InputMaybe<Array<ActiveBoosterWhereInput>>;
  category?: InputMaybe<EnumBoosterCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  subCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  userId_category_subCategory?: InputMaybe<ActiveBoosterUserIdCategorySubCategoryCompoundUniqueInput>;
};

export type AggregateArcadeMachine = {
  __typename?: 'AggregateArcadeMachine';
  _avg?: Maybe<ArcadeMachineAvgAggregate>;
  _count?: Maybe<ArcadeMachineCountAggregate>;
  _max?: Maybe<ArcadeMachineMaxAggregate>;
  _min?: Maybe<ArcadeMachineMinAggregate>;
  _sum?: Maybe<ArcadeMachineSumAggregate>;
};

export type AggregateArcadePart = {
  __typename?: 'AggregateArcadePart';
  _avg?: Maybe<ArcadePartAvgAggregate>;
  _count?: Maybe<ArcadePartCountAggregate>;
  _max?: Maybe<ArcadePartMaxAggregate>;
  _min?: Maybe<ArcadePartMinAggregate>;
  _sum?: Maybe<ArcadePartSumAggregate>;
};

export type AggregateCraft = {
  __typename?: 'AggregateCraft';
  _avg?: Maybe<CraftAvgAggregate>;
  _count?: Maybe<CraftCountAggregate>;
  _max?: Maybe<CraftMaxAggregate>;
  _min?: Maybe<CraftMinAggregate>;
  _sum?: Maybe<CraftSumAggregate>;
};

export type AggregateGameCenter = {
  __typename?: 'AggregateGameCenter';
  _avg?: Maybe<GameCenterAvgAggregate>;
  _count?: Maybe<GameCenterCountAggregate>;
  _max?: Maybe<GameCenterMaxAggregate>;
  _min?: Maybe<GameCenterMinAggregate>;
  _sum?: Maybe<GameCenterSumAggregate>;
};

export type AggregatePlay = {
  __typename?: 'AggregatePlay';
  _avg?: Maybe<PlayAvgAggregate>;
  _count?: Maybe<PlayCountAggregate>;
  _max?: Maybe<PlayMaxAggregate>;
  _min?: Maybe<PlayMinAggregate>;
  _sum?: Maybe<PlaySumAggregate>;
};

export type AggregatePlaySession = {
  __typename?: 'AggregatePlaySession';
  _avg?: Maybe<PlaySessionAvgAggregate>;
  _count?: Maybe<PlaySessionCountAggregate>;
  _max?: Maybe<PlaySessionMaxAggregate>;
  _min?: Maybe<PlaySessionMinAggregate>;
  _sum?: Maybe<PlaySessionSumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
};

export type ApplyBoosterItemInput = {
  boosterItemId: Scalars['String'];
  paidTournamentId?: InputMaybe<Scalars['String']>;
};

export type ArcadeMachine = {
  __typename?: 'ArcadeMachine';
  _count?: Maybe<ArcadeMachineCount>;
  accumulatorSubCategory: Scalars['String'];
  autoRenewLease: Scalars['Boolean'];
  boost: Scalars['Float'];
  craft?: Maybe<Craft>;
  createdAt: Scalars['DateTime'];
  destroyedAt?: Maybe<Scalars['DateTime']>;
  dismantle?: Maybe<Dismantle>;
  energy: Scalars['Int'];
  extractInfo: ExtractInfoOutput;
  extractedEnergy: Scalars['Int'];
  extracts: Array<Extract>;
  feverSparkRemain?: Maybe<Scalars['Int']>;
  game: Scalars['String'];
  gameCenter?: Maybe<GameCenter>;
  gameCenterId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  installedAt?: Maybe<Scalars['DateTime']>;
  lowerCabinetSubCategory: Scalars['String'];
  maxEnergy: Scalars['Int'];
  megaSparkUpcoming: Scalars['Boolean'];
  metadata: Metadata;
  playSessions: Array<PlaySession>;
  playing: Scalars['Boolean'];
  position?: Maybe<Scalars['Int']>;
  rewardForSparking: Scalars['Decimal'];
  state: NftState;
  upperCabinetSubCategory: Scalars['String'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type ArcadeMachineCraftArgs = {
  where?: InputMaybe<CraftWhereInput>;
};


export type ArcadeMachineDismantleArgs = {
  where?: InputMaybe<DismantleWhereInput>;
};


export type ArcadeMachineExtractsArgs = {
  cursor?: InputMaybe<ExtractWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExtractScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExtractOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractWhereInput>;
};


export type ArcadeMachineGameCenterArgs = {
  where?: InputMaybe<GameCenterWhereInput>;
};


export type ArcadeMachinePlaySessionsArgs = {
  cursor?: InputMaybe<PlaySessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaySessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaySessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type ArcadeMachineUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type ArcadeMachineAvgAggregate = {
  __typename?: 'ArcadeMachineAvgAggregate';
  boost?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Float']>;
  extractedEnergy?: Maybe<Scalars['Float']>;
  feverSparkRemain?: Maybe<Scalars['Float']>;
  lastBlock?: Maybe<Scalars['Float']>;
  lastTransactionIndex?: Maybe<Scalars['Float']>;
  maxEnergy?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

export type ArcadeMachineAvgOrderByAggregateInput = {
  boost?: InputMaybe<SortOrder>;
  energy?: InputMaybe<SortOrder>;
  extractedEnergy?: InputMaybe<SortOrder>;
  feverSparkRemain?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  maxEnergy?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
};

export type ArcadeMachineCount = {
  __typename?: 'ArcadeMachineCount';
  extracts: Scalars['Int'];
  playSessions: Scalars['Int'];
};


export type ArcadeMachineCountExtractsArgs = {
  where?: InputMaybe<ExtractWhereInput>;
};


export type ArcadeMachineCountPlaySessionsArgs = {
  where?: InputMaybe<PlaySessionWhereInput>;
};

export type ArcadeMachineCountAggregate = {
  __typename?: 'ArcadeMachineCountAggregate';
  _all: Scalars['Int'];
  accumulatorSubCategory: Scalars['Int'];
  autoRenewLease: Scalars['Int'];
  boost: Scalars['Int'];
  createdAt: Scalars['Int'];
  destroyedAt: Scalars['Int'];
  energy: Scalars['Int'];
  extractedEnergy: Scalars['Int'];
  feverSparkRemain: Scalars['Int'];
  game: Scalars['Int'];
  gameCenterId: Scalars['Int'];
  id: Scalars['Int'];
  installedAt: Scalars['Int'];
  lastBlock: Scalars['Int'];
  lastTransactionIndex: Scalars['Int'];
  lowerCabinetSubCategory: Scalars['Int'];
  maxEnergy: Scalars['Int'];
  ownerWalletAddress: Scalars['Int'];
  physicalWalletAddress: Scalars['Int'];
  position: Scalars['Int'];
  state: Scalars['Int'];
  updatedAt: Scalars['Int'];
  upperCabinetSubCategory: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ArcadeMachineCountOrderByAggregateInput = {
  accumulatorSubCategory?: InputMaybe<SortOrder>;
  autoRenewLease?: InputMaybe<SortOrder>;
  boost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrder>;
  energy?: InputMaybe<SortOrder>;
  extractedEnergy?: InputMaybe<SortOrder>;
  feverSparkRemain?: InputMaybe<SortOrder>;
  game?: InputMaybe<SortOrder>;
  gameCenterId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  installedAt?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  lowerCabinetSubCategory?: InputMaybe<SortOrder>;
  maxEnergy?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  upperCabinetSubCategory?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ArcadeMachineGameCenterIdPositionCompoundUniqueInput = {
  gameCenterId: Scalars['String'];
  position: Scalars['Int'];
};

export type ArcadeMachineGroupBy = {
  __typename?: 'ArcadeMachineGroupBy';
  _avg?: Maybe<ArcadeMachineAvgAggregate>;
  _count?: Maybe<ArcadeMachineCountAggregate>;
  _max?: Maybe<ArcadeMachineMaxAggregate>;
  _min?: Maybe<ArcadeMachineMinAggregate>;
  _sum?: Maybe<ArcadeMachineSumAggregate>;
  accumulatorSubCategory: Scalars['String'];
  autoRenewLease: Scalars['Boolean'];
  boost: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  destroyedAt?: Maybe<Scalars['DateTime']>;
  energy: Scalars['Int'];
  extractedEnergy: Scalars['Int'];
  feverSparkRemain?: Maybe<Scalars['Int']>;
  game: Scalars['String'];
  gameCenterId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  installedAt?: Maybe<Scalars['DateTime']>;
  lastBlock: Scalars['Int'];
  lastTransactionIndex: Scalars['Int'];
  lowerCabinetSubCategory: Scalars['String'];
  maxEnergy: Scalars['Int'];
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  state: NftState;
  updatedAt: Scalars['DateTime'];
  upperCabinetSubCategory: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type ArcadeMachineListRelationFilter = {
  every?: InputMaybe<ArcadeMachineWhereInput>;
  none?: InputMaybe<ArcadeMachineWhereInput>;
  some?: InputMaybe<ArcadeMachineWhereInput>;
};

export type ArcadeMachineMaxAggregate = {
  __typename?: 'ArcadeMachineMaxAggregate';
  accumulatorSubCategory?: Maybe<Scalars['String']>;
  autoRenewLease?: Maybe<Scalars['Boolean']>;
  boost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destroyedAt?: Maybe<Scalars['DateTime']>;
  energy?: Maybe<Scalars['Int']>;
  extractedEnergy?: Maybe<Scalars['Int']>;
  feverSparkRemain?: Maybe<Scalars['Int']>;
  game?: Maybe<Scalars['String']>;
  gameCenterId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installedAt?: Maybe<Scalars['DateTime']>;
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  lowerCabinetSubCategory?: Maybe<Scalars['String']>;
  maxEnergy?: Maybe<Scalars['Int']>;
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  state?: Maybe<NftState>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upperCabinetSubCategory?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ArcadeMachineMaxOrderByAggregateInput = {
  accumulatorSubCategory?: InputMaybe<SortOrder>;
  autoRenewLease?: InputMaybe<SortOrder>;
  boost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrder>;
  energy?: InputMaybe<SortOrder>;
  extractedEnergy?: InputMaybe<SortOrder>;
  feverSparkRemain?: InputMaybe<SortOrder>;
  game?: InputMaybe<SortOrder>;
  gameCenterId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  installedAt?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  lowerCabinetSubCategory?: InputMaybe<SortOrder>;
  maxEnergy?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  upperCabinetSubCategory?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ArcadeMachineMinAggregate = {
  __typename?: 'ArcadeMachineMinAggregate';
  accumulatorSubCategory?: Maybe<Scalars['String']>;
  autoRenewLease?: Maybe<Scalars['Boolean']>;
  boost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destroyedAt?: Maybe<Scalars['DateTime']>;
  energy?: Maybe<Scalars['Int']>;
  extractedEnergy?: Maybe<Scalars['Int']>;
  feverSparkRemain?: Maybe<Scalars['Int']>;
  game?: Maybe<Scalars['String']>;
  gameCenterId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  installedAt?: Maybe<Scalars['DateTime']>;
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  lowerCabinetSubCategory?: Maybe<Scalars['String']>;
  maxEnergy?: Maybe<Scalars['Int']>;
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  state?: Maybe<NftState>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  upperCabinetSubCategory?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ArcadeMachineMinOrderByAggregateInput = {
  accumulatorSubCategory?: InputMaybe<SortOrder>;
  autoRenewLease?: InputMaybe<SortOrder>;
  boost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrder>;
  energy?: InputMaybe<SortOrder>;
  extractedEnergy?: InputMaybe<SortOrder>;
  feverSparkRemain?: InputMaybe<SortOrder>;
  game?: InputMaybe<SortOrder>;
  gameCenterId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  installedAt?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  lowerCabinetSubCategory?: InputMaybe<SortOrder>;
  maxEnergy?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  upperCabinetSubCategory?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ArcadeMachineOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ArcadeMachineOrderByWithAggregationInput = {
  _avg?: InputMaybe<ArcadeMachineAvgOrderByAggregateInput>;
  _count?: InputMaybe<ArcadeMachineCountOrderByAggregateInput>;
  _max?: InputMaybe<ArcadeMachineMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArcadeMachineMinOrderByAggregateInput>;
  _sum?: InputMaybe<ArcadeMachineSumOrderByAggregateInput>;
  accumulatorSubCategory?: InputMaybe<SortOrder>;
  autoRenewLease?: InputMaybe<SortOrder>;
  boost?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrderInput>;
  energy?: InputMaybe<SortOrder>;
  extractedEnergy?: InputMaybe<SortOrder>;
  feverSparkRemain?: InputMaybe<SortOrderInput>;
  game?: InputMaybe<SortOrder>;
  gameCenterId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  installedAt?: InputMaybe<SortOrderInput>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  lowerCabinetSubCategory?: InputMaybe<SortOrder>;
  maxEnergy?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrderInput>;
  position?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrder>;
  upperCabinetSubCategory?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
};

export type ArcadeMachineOrderByWithRelationInput = {
  accumulatorSubCategory?: InputMaybe<SortOrder>;
  autoRenewLease?: InputMaybe<SortOrder>;
  boost?: InputMaybe<SortOrder>;
  craft?: InputMaybe<CraftOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrderInput>;
  dismantle?: InputMaybe<DismantleOrderByWithRelationInput>;
  energy?: InputMaybe<SortOrder>;
  extractedEnergy?: InputMaybe<SortOrder>;
  extracts?: InputMaybe<ExtractOrderByRelationAggregateInput>;
  feverSparkRemain?: InputMaybe<SortOrderInput>;
  game?: InputMaybe<SortOrder>;
  gameCenter?: InputMaybe<GameCenterOrderByWithRelationInput>;
  gameCenterId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  installedAt?: InputMaybe<SortOrderInput>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  lowerCabinetSubCategory?: InputMaybe<SortOrder>;
  maxEnergy?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrderInput>;
  playSessions?: InputMaybe<PlaySessionOrderByRelationAggregateInput>;
  position?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrder>;
  upperCabinetSubCategory?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export type ArcadeMachineRelationFilter = {
  is?: InputMaybe<ArcadeMachineWhereInput>;
  isNot?: InputMaybe<ArcadeMachineWhereInput>;
};

export enum ArcadeMachineScalarFieldEnum {
  AccumulatorSubCategory = 'accumulatorSubCategory',
  AutoRenewLease = 'autoRenewLease',
  Boost = 'boost',
  CreatedAt = 'createdAt',
  DestroyedAt = 'destroyedAt',
  Energy = 'energy',
  ExtractedEnergy = 'extractedEnergy',
  FeverSparkRemain = 'feverSparkRemain',
  Game = 'game',
  GameCenterId = 'gameCenterId',
  Id = 'id',
  InstalledAt = 'installedAt',
  LastBlock = 'lastBlock',
  LastTransactionIndex = 'lastTransactionIndex',
  LowerCabinetSubCategory = 'lowerCabinetSubCategory',
  MaxEnergy = 'maxEnergy',
  OwnerWalletAddress = 'ownerWalletAddress',
  PhysicalWalletAddress = 'physicalWalletAddress',
  Position = 'position',
  State = 'state',
  UpdatedAt = 'updatedAt',
  UpperCabinetSubCategory = 'upperCabinetSubCategory',
  UserId = 'userId'
}

export type ArcadeMachineScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ArcadeMachineScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ArcadeMachineScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ArcadeMachineScalarWhereWithAggregatesInput>>;
  accumulatorSubCategory?: InputMaybe<StringWithAggregatesFilter>;
  autoRenewLease?: InputMaybe<BoolWithAggregatesFilter>;
  boost?: InputMaybe<FloatWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  destroyedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  energy?: InputMaybe<IntWithAggregatesFilter>;
  extractedEnergy?: InputMaybe<IntWithAggregatesFilter>;
  feverSparkRemain?: InputMaybe<IntNullableWithAggregatesFilter>;
  game?: InputMaybe<StringWithAggregatesFilter>;
  gameCenterId?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  installedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  lastBlock?: InputMaybe<IntWithAggregatesFilter>;
  lastTransactionIndex?: InputMaybe<IntWithAggregatesFilter>;
  lowerCabinetSubCategory?: InputMaybe<StringWithAggregatesFilter>;
  maxEnergy?: InputMaybe<IntWithAggregatesFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableWithAggregatesFilter>;
  position?: InputMaybe<IntNullableWithAggregatesFilter>;
  state?: InputMaybe<EnumNftStateWithAggregatesFilter>;
  upperCabinetSubCategory?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<UuidNullableWithAggregatesFilter>;
};

export type ArcadeMachineSumAggregate = {
  __typename?: 'ArcadeMachineSumAggregate';
  boost?: Maybe<Scalars['Float']>;
  energy?: Maybe<Scalars['Int']>;
  extractedEnergy?: Maybe<Scalars['Int']>;
  feverSparkRemain?: Maybe<Scalars['Int']>;
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  maxEnergy?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

export type ArcadeMachineSumOrderByAggregateInput = {
  boost?: InputMaybe<SortOrder>;
  energy?: InputMaybe<SortOrder>;
  extractedEnergy?: InputMaybe<SortOrder>;
  feverSparkRemain?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  maxEnergy?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
};

export type ArcadeMachineWhereInput = {
  AND?: InputMaybe<Array<ArcadeMachineWhereInput>>;
  NOT?: InputMaybe<Array<ArcadeMachineWhereInput>>;
  OR?: InputMaybe<Array<ArcadeMachineWhereInput>>;
  accumulatorSubCategory?: InputMaybe<StringFilter>;
  autoRenewLease?: InputMaybe<BoolFilter>;
  boost?: InputMaybe<FloatFilter>;
  craft?: InputMaybe<CraftNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destroyedAt?: InputMaybe<DateTimeNullableFilter>;
  dismantle?: InputMaybe<DismantleNullableRelationFilter>;
  energy?: InputMaybe<IntFilter>;
  extractedEnergy?: InputMaybe<IntFilter>;
  extracts?: InputMaybe<ExtractListRelationFilter>;
  feverSparkRemain?: InputMaybe<IntNullableFilter>;
  game?: InputMaybe<StringFilter>;
  gameCenter?: InputMaybe<GameCenterNullableRelationFilter>;
  gameCenterId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  installedAt?: InputMaybe<DateTimeNullableFilter>;
  lastBlock?: InputMaybe<IntFilter>;
  lastTransactionIndex?: InputMaybe<IntFilter>;
  lowerCabinetSubCategory?: InputMaybe<StringFilter>;
  maxEnergy?: InputMaybe<IntFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableFilter>;
  playSessions?: InputMaybe<PlaySessionListRelationFilter>;
  position?: InputMaybe<IntNullableFilter>;
  state?: InputMaybe<EnumNftStateFilter>;
  upperCabinetSubCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
};

export type ArcadeMachineWhereUniqueInput = {
  AND?: InputMaybe<Array<ArcadeMachineWhereInput>>;
  NOT?: InputMaybe<Array<ArcadeMachineWhereInput>>;
  OR?: InputMaybe<Array<ArcadeMachineWhereInput>>;
  accumulatorSubCategory?: InputMaybe<StringFilter>;
  autoRenewLease?: InputMaybe<BoolFilter>;
  boost?: InputMaybe<FloatFilter>;
  craft?: InputMaybe<CraftNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destroyedAt?: InputMaybe<DateTimeNullableFilter>;
  dismantle?: InputMaybe<DismantleNullableRelationFilter>;
  energy?: InputMaybe<IntFilter>;
  extractedEnergy?: InputMaybe<IntFilter>;
  extracts?: InputMaybe<ExtractListRelationFilter>;
  feverSparkRemain?: InputMaybe<IntNullableFilter>;
  game?: InputMaybe<StringFilter>;
  gameCenter?: InputMaybe<GameCenterNullableRelationFilter>;
  gameCenterId?: InputMaybe<StringNullableFilter>;
  gameCenterId_position?: InputMaybe<ArcadeMachineGameCenterIdPositionCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
  installedAt?: InputMaybe<DateTimeNullableFilter>;
  lastBlock?: InputMaybe<IntFilter>;
  lastTransactionIndex?: InputMaybe<IntFilter>;
  lowerCabinetSubCategory?: InputMaybe<StringFilter>;
  maxEnergy?: InputMaybe<IntFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableFilter>;
  playSessions?: InputMaybe<PlaySessionListRelationFilter>;
  position?: InputMaybe<IntNullableFilter>;
  state?: InputMaybe<EnumNftStateFilter>;
  upperCabinetSubCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
};

export type ArcadePart = {
  __typename?: 'ArcadePart';
  category: ArcadePartCategory;
  craft?: Maybe<Craft>;
  craftId?: Maybe<Scalars['String']>;
  createDismantle?: Maybe<Dismantle>;
  createDismantleId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  destroyedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  metadata: Metadata;
  state: NftState;
  subCategory: Scalars['String'];
  usedJunks?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type ArcadePartCraftArgs = {
  where?: InputMaybe<CraftWhereInput>;
};


export type ArcadePartCreateDismantleArgs = {
  where?: InputMaybe<DismantleWhereInput>;
};


export type ArcadePartUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type ArcadePartAvgAggregate = {
  __typename?: 'ArcadePartAvgAggregate';
  lastBlock?: Maybe<Scalars['Float']>;
  lastTransactionIndex?: Maybe<Scalars['Float']>;
  usedJunks?: Maybe<Scalars['Float']>;
};

export type ArcadePartAvgOrderByAggregateInput = {
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  usedJunks?: InputMaybe<SortOrder>;
};

export enum ArcadePartCategory {
  Accumulator = 'ACCUMULATOR',
  LowerCabinet = 'LOWER_CABINET',
  Rom = 'ROM',
  UpperCabinet = 'UPPER_CABINET'
}

export type ArcadePartCountAggregate = {
  __typename?: 'ArcadePartCountAggregate';
  _all: Scalars['Int'];
  category: Scalars['Int'];
  craftId: Scalars['Int'];
  createDismantleId: Scalars['Int'];
  createdAt: Scalars['Int'];
  destroyedAt: Scalars['Int'];
  id: Scalars['Int'];
  lastBlock: Scalars['Int'];
  lastTransactionIndex: Scalars['Int'];
  ownerWalletAddress: Scalars['Int'];
  physicalWalletAddress: Scalars['Int'];
  state: Scalars['Int'];
  subCategory: Scalars['Int'];
  updatedAt: Scalars['Int'];
  usedJunks: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ArcadePartCountOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  craftId?: InputMaybe<SortOrder>;
  createDismantleId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  usedJunks?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ArcadePartGroupBy = {
  __typename?: 'ArcadePartGroupBy';
  _avg?: Maybe<ArcadePartAvgAggregate>;
  _count?: Maybe<ArcadePartCountAggregate>;
  _max?: Maybe<ArcadePartMaxAggregate>;
  _min?: Maybe<ArcadePartMinAggregate>;
  _sum?: Maybe<ArcadePartSumAggregate>;
  category: ArcadePartCategory;
  craftId?: Maybe<Scalars['String']>;
  createDismantleId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  destroyedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  lastBlock: Scalars['Int'];
  lastTransactionIndex: Scalars['Int'];
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  state: NftState;
  subCategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  usedJunks?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type ArcadePartListRelationFilter = {
  every?: InputMaybe<ArcadePartWhereInput>;
  none?: InputMaybe<ArcadePartWhereInput>;
  some?: InputMaybe<ArcadePartWhereInput>;
};

export type ArcadePartMaxAggregate = {
  __typename?: 'ArcadePartMaxAggregate';
  category?: Maybe<ArcadePartCategory>;
  craftId?: Maybe<Scalars['String']>;
  createDismantleId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destroyedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  state?: Maybe<NftState>;
  subCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedJunks?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type ArcadePartMaxOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  craftId?: InputMaybe<SortOrder>;
  createDismantleId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  usedJunks?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ArcadePartMinAggregate = {
  __typename?: 'ArcadePartMinAggregate';
  category?: Maybe<ArcadePartCategory>;
  craftId?: Maybe<Scalars['String']>;
  createDismantleId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destroyedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  state?: Maybe<NftState>;
  subCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedJunks?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type ArcadePartMinOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  craftId?: InputMaybe<SortOrder>;
  createDismantleId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  usedJunks?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ArcadePartOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ArcadePartOrderByWithAggregationInput = {
  _avg?: InputMaybe<ArcadePartAvgOrderByAggregateInput>;
  _count?: InputMaybe<ArcadePartCountOrderByAggregateInput>;
  _max?: InputMaybe<ArcadePartMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArcadePartMinOrderByAggregateInput>;
  _sum?: InputMaybe<ArcadePartSumOrderByAggregateInput>;
  category?: InputMaybe<SortOrder>;
  craftId?: InputMaybe<SortOrderInput>;
  createDismantleId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  usedJunks?: InputMaybe<SortOrderInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export type ArcadePartOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  craft?: InputMaybe<CraftOrderByWithRelationInput>;
  craftId?: InputMaybe<SortOrderInput>;
  createDismantle?: InputMaybe<DismantleOrderByWithRelationInput>;
  createDismantleId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  destroyedAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  usedJunks?: InputMaybe<SortOrderInput>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum ArcadePartScalarFieldEnum {
  Category = 'category',
  CraftId = 'craftId',
  CreateDismantleId = 'createDismantleId',
  CreatedAt = 'createdAt',
  DestroyedAt = 'destroyedAt',
  Id = 'id',
  LastBlock = 'lastBlock',
  LastTransactionIndex = 'lastTransactionIndex',
  OwnerWalletAddress = 'ownerWalletAddress',
  PhysicalWalletAddress = 'physicalWalletAddress',
  State = 'state',
  SubCategory = 'subCategory',
  UpdatedAt = 'updatedAt',
  UsedJunks = 'usedJunks',
  UserId = 'userId'
}

export type ArcadePartScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ArcadePartScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ArcadePartScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ArcadePartScalarWhereWithAggregatesInput>>;
  category?: InputMaybe<EnumArcadePartCategoryWithAggregatesFilter>;
  craftId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  createDismantleId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  destroyedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastBlock?: InputMaybe<IntWithAggregatesFilter>;
  lastTransactionIndex?: InputMaybe<IntWithAggregatesFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableWithAggregatesFilter>;
  state?: InputMaybe<EnumNftStateWithAggregatesFilter>;
  subCategory?: InputMaybe<StringWithAggregatesFilter>;
  usedJunks?: InputMaybe<IntNullableWithAggregatesFilter>;
  userId?: InputMaybe<UuidNullableWithAggregatesFilter>;
};

export type ArcadePartSumAggregate = {
  __typename?: 'ArcadePartSumAggregate';
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  usedJunks?: Maybe<Scalars['Int']>;
};

export type ArcadePartSumOrderByAggregateInput = {
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  usedJunks?: InputMaybe<SortOrder>;
};

export type ArcadePartWhereInput = {
  AND?: InputMaybe<Array<ArcadePartWhereInput>>;
  NOT?: InputMaybe<Array<ArcadePartWhereInput>>;
  OR?: InputMaybe<Array<ArcadePartWhereInput>>;
  category?: InputMaybe<EnumArcadePartCategoryFilter>;
  craft?: InputMaybe<CraftNullableRelationFilter>;
  craftId?: InputMaybe<UuidNullableFilter>;
  createDismantle?: InputMaybe<DismantleNullableRelationFilter>;
  createDismantleId?: InputMaybe<UuidNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destroyedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastBlock?: InputMaybe<IntFilter>;
  lastTransactionIndex?: InputMaybe<IntFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<EnumNftStateFilter>;
  subCategory?: InputMaybe<StringFilter>;
  usedJunks?: InputMaybe<IntNullableFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
};

export type ArcadePartWhereUniqueInput = {
  AND?: InputMaybe<Array<ArcadePartWhereInput>>;
  NOT?: InputMaybe<Array<ArcadePartWhereInput>>;
  OR?: InputMaybe<Array<ArcadePartWhereInput>>;
  category?: InputMaybe<EnumArcadePartCategoryFilter>;
  craft?: InputMaybe<CraftNullableRelationFilter>;
  craftId?: InputMaybe<UuidNullableFilter>;
  createDismantle?: InputMaybe<DismantleNullableRelationFilter>;
  createDismantleId?: InputMaybe<UuidNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destroyedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  lastBlock?: InputMaybe<IntFilter>;
  lastTransactionIndex?: InputMaybe<IntFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<EnumNftStateFilter>;
  subCategory?: InputMaybe<StringFilter>;
  usedJunks?: InputMaybe<IntNullableFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
};

export type Banner = {
  __typename?: 'Banner';
  bgImageUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  display: Scalars['Boolean'];
  endAt?: Maybe<Scalars['DateTime']>;
  externalLink?: Maybe<Scalars['String']>;
  frontEndType: FrontEndType;
  id: Scalars['Int'];
  mainImageUrl: Scalars['String'];
  startAt?: Maybe<Scalars['DateTime']>;
};

export type BannerOrderByWithRelationInput = {
  bgImageUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  display?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrderInput>;
  externalLink?: InputMaybe<SortOrderInput>;
  frontEndType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainImageUrl?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrderInput>;
};

export enum BannerScalarFieldEnum {
  BgImageUrl = 'bgImageUrl',
  CreatedAt = 'createdAt',
  Description = 'description',
  Display = 'display',
  EndAt = 'endAt',
  ExternalLink = 'externalLink',
  FrontEndType = 'frontEndType',
  Id = 'id',
  MainImageUrl = 'mainImageUrl',
  StartAt = 'startAt',
  TargetArea = 'targetArea',
  UpdatedAt = 'updatedAt'
}

export type BannerWhereInput = {
  AND?: InputMaybe<Array<BannerWhereInput>>;
  NOT?: InputMaybe<Array<BannerWhereInput>>;
  OR?: InputMaybe<Array<BannerWhereInput>>;
  bgImageUrl?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  display?: InputMaybe<BoolFilter>;
  endAt?: InputMaybe<DateTimeNullableFilter>;
  externalLink?: InputMaybe<StringNullableFilter>;
  frontEndType?: InputMaybe<EnumFrontEndTypeFilter>;
  id?: InputMaybe<IntFilter>;
  mainImageUrl?: InputMaybe<StringFilter>;
  startAt?: InputMaybe<DateTimeNullableFilter>;
};

export type BannerWhereUniqueInput = {
  AND?: InputMaybe<Array<BannerWhereInput>>;
  NOT?: InputMaybe<Array<BannerWhereInput>>;
  OR?: InputMaybe<Array<BannerWhereInput>>;
  bgImageUrl?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  display?: InputMaybe<BoolFilter>;
  endAt?: InputMaybe<DateTimeNullableFilter>;
  externalLink?: InputMaybe<StringNullableFilter>;
  frontEndType?: InputMaybe<EnumFrontEndTypeFilter>;
  id?: InputMaybe<Scalars['Int']>;
  mainImageUrl?: InputMaybe<StringFilter>;
  startAt?: InputMaybe<DateTimeNullableFilter>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export enum BoosterCategory {
  EasyMode = 'EASY_MODE',
  GameSwap = 'GAME_SWAP',
  SparkTerasUp = 'SPARK_TERAS_UP'
}

export type BoosterMaster = {
  __typename?: 'BoosterMaster';
  _count?: Maybe<BoosterMasterCount>;
  category: BoosterCategory;
  createdAt: Scalars['DateTime'];
  effectiveMinutes: Scalars['Int'];
  feeTickets: Scalars['Int'];
  id: Scalars['String'];
  paidTournamentBoosterAvailables: Array<PaidTournamentBoosterAvailable>;
  requireTournament: Scalars['Boolean'];
  subCategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  variant: Scalars['String'];
};


export type BoosterMasterPaidTournamentBoosterAvailablesArgs = {
  cursor?: InputMaybe<PaidTournamentBoosterAvailableWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaidTournamentBoosterAvailableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaidTournamentBoosterAvailableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaidTournamentBoosterAvailableWhereInput>;
};

export type BoosterMasterCount = {
  __typename?: 'BoosterMasterCount';
  paidTournamentBoosterAvailables: Scalars['Int'];
};


export type BoosterMasterCountPaidTournamentBoosterAvailablesArgs = {
  where?: InputMaybe<PaidTournamentBoosterAvailableWhereInput>;
};

export type BoosterMasterOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  effectiveMinutes?: InputMaybe<SortOrder>;
  feeTickets?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paidTournamentBoosterAvailables?: InputMaybe<PaidTournamentBoosterAvailableOrderByRelationAggregateInput>;
  requireTournament?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  variant?: InputMaybe<SortOrder>;
};

export type BoosterMasterRelationFilter = {
  is?: InputMaybe<BoosterMasterWhereInput>;
  isNot?: InputMaybe<BoosterMasterWhereInput>;
};

export type BoosterMasterWhereInput = {
  AND?: InputMaybe<Array<BoosterMasterWhereInput>>;
  NOT?: InputMaybe<Array<BoosterMasterWhereInput>>;
  OR?: InputMaybe<Array<BoosterMasterWhereInput>>;
  category?: InputMaybe<EnumBoosterCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  effectiveMinutes?: InputMaybe<IntFilter>;
  feeTickets?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  paidTournamentBoosterAvailables?: InputMaybe<PaidTournamentBoosterAvailableListRelationFilter>;
  requireTournament?: InputMaybe<BoolFilter>;
  subCategory?: InputMaybe<StringFilter>;
  variant?: InputMaybe<StringFilter>;
};

export type BoxItem = {
  __typename?: 'BoxItem';
  amount: Scalars['Float'];
  category: Scalars['String'];
  initialAmount: Scalars['Float'];
  name: Scalars['String'];
  subCategory: Scalars['String'];
};

export type Burn = {
  __typename?: 'Burn';
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  nftType: NftType;
  nonce?: Maybe<Scalars['Int']>;
  response?: Maybe<Scalars['String']>;
  signerAddress?: Maybe<Scalars['String']>;
  state: BurnState;
  tokenId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type BurnListRelationFilter = {
  every?: InputMaybe<BurnWhereInput>;
  none?: InputMaybe<BurnWhereInput>;
  some?: InputMaybe<BurnWhereInput>;
};

export type BurnOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BurnOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  errorMessage?: InputMaybe<SortOrderInput>;
  hash?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  nftType?: InputMaybe<SortOrder>;
  nonce?: InputMaybe<SortOrderInput>;
  response?: InputMaybe<SortOrderInput>;
  signerAddress?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrder>;
  tokenId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum BurnScalarFieldEnum {
  CreatedAt = 'createdAt',
  ErrorMessage = 'errorMessage',
  Hash = 'hash',
  Id = 'id',
  NftType = 'nftType',
  Nonce = 'nonce',
  Response = 'response',
  SignerAddress = 'signerAddress',
  State = 'state',
  TokenId = 'tokenId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export enum BurnState {
  Confirmed = 'CONFIRMED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Unprocessed = 'UNPROCESSED'
}

export type BurnWhereInput = {
  AND?: InputMaybe<Array<BurnWhereInput>>;
  NOT?: InputMaybe<Array<BurnWhereInput>>;
  OR?: InputMaybe<Array<BurnWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  errorMessage?: InputMaybe<StringNullableFilter>;
  hash?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  nftType?: InputMaybe<EnumNftTypeFilter>;
  nonce?: InputMaybe<IntNullableFilter>;
  response?: InputMaybe<StringNullableFilter>;
  signerAddress?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<EnumBurnStateFilter>;
  tokenId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
};

export type BurnWhereUniqueInput = {
  AND?: InputMaybe<Array<BurnWhereInput>>;
  NOT?: InputMaybe<Array<BurnWhereInput>>;
  OR?: InputMaybe<Array<BurnWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  errorMessage?: InputMaybe<StringNullableFilter>;
  hash?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  nftType?: InputMaybe<EnumNftTypeFilter>;
  nonce?: InputMaybe<IntNullableFilter>;
  response?: InputMaybe<StringNullableFilter>;
  signerAddress?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<EnumBurnStateFilter>;
  tokenId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
};

export type CheckTransferFtItem = {
  __typename?: 'CheckTransferFtItem';
  deposits: Array<Scalars['Decimal']>;
  withdraws: Array<Scalars['Decimal']>;
};

export type CheckTransferFtOutput = {
  __typename?: 'CheckTransferFtOutput';
  akir: CheckTransferFtItem;
  akv: CheckTransferFtItem;
};

export type CheckTransferNftItem = {
  __typename?: 'CheckTransferNftItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CheckTransferNftItems = {
  __typename?: 'CheckTransferNftItems';
  deposits: Array<CheckTransferNftItem>;
  withdraws: Array<CheckTransferNftItem>;
};

export type CheckTransferNftOutput = {
  __typename?: 'CheckTransferNftOutput';
  arcadeMachines: CheckTransferNftItems;
  arcadeParts: CheckTransferNftItems;
  gameCenters: CheckTransferNftItems;
};

export type CheckTransfersOutput = {
  __typename?: 'CheckTransfersOutput';
  ft: CheckTransferFtOutput;
  nft: CheckTransferNftOutput;
};

export type CheckVersionOutput = {
  __typename?: 'CheckVersionOutput';
  debug: Scalars['Boolean'];
};

export type ClaimPrizeInput = {
  phoneNumber?: InputMaybe<Scalars['String']>;
  tournamentId?: InputMaybe<Scalars['String']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

export type CollectibleItem = {
  __typename?: 'CollectibleItem';
  category: CollectibleItemCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  subCategory: Scalars['String'];
  userId: Scalars['String'];
};

export enum CollectibleItemCategory {
  Frame = 'FRAME',
  Icon = 'ICON',
  Title = 'TITLE'
}

export type CollectibleItemListRelationFilter = {
  every?: InputMaybe<CollectibleItemWhereInput>;
  none?: InputMaybe<CollectibleItemWhereInput>;
  some?: InputMaybe<CollectibleItemWhereInput>;
};

export type CollectibleItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CollectibleItemOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum CollectibleItemScalarFieldEnum {
  Category = 'category',
  CreatedAt = 'createdAt',
  Id = 'id',
  SubCategory = 'subCategory',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type CollectibleItemUserIdCategorySubCategoryCompoundUniqueInput = {
  category: CollectibleItemCategory;
  subCategory: Scalars['String'];
  userId: Scalars['String'];
};

export type CollectibleItemWhereInput = {
  AND?: InputMaybe<Array<CollectibleItemWhereInput>>;
  NOT?: InputMaybe<Array<CollectibleItemWhereInput>>;
  OR?: InputMaybe<Array<CollectibleItemWhereInput>>;
  category?: InputMaybe<EnumCollectibleItemCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  subCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type CollectibleItemWhereUniqueInput = {
  AND?: InputMaybe<Array<CollectibleItemWhereInput>>;
  NOT?: InputMaybe<Array<CollectibleItemWhereInput>>;
  OR?: InputMaybe<Array<CollectibleItemWhereInput>>;
  category?: InputMaybe<EnumCollectibleItemCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  subCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  userId_category_subCategory?: InputMaybe<CollectibleItemUserIdCategorySubCategoryCompoundUniqueInput>;
};

export type CollectibleItemsWhereInput = {
  category?: InputMaybe<EnumCollectibleItemCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  subCategory?: InputMaybe<StringNullableFilter>;
};

export type Craft = {
  __typename?: 'Craft';
  _count?: Maybe<CraftCount>;
  arcadeParts: Array<ArcadePart>;
  craftedArcadeMachine: ArcadeMachine;
  craftedArcadeMachineId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  usedAkvBalance: Scalars['Decimal'];
  usedTerasBalance: Scalars['Decimal'];
  user: User;
  userId: Scalars['String'];
};


export type CraftArcadePartsArgs = {
  cursor?: InputMaybe<ArcadePartWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArcadePartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArcadePartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadePartWhereInput>;
};

export type CraftAvgAggregate = {
  __typename?: 'CraftAvgAggregate';
  usedAkvBalance?: Maybe<Scalars['Decimal']>;
  usedTerasBalance?: Maybe<Scalars['Decimal']>;
};

export type CraftAvgOrderByAggregateInput = {
  usedAkvBalance?: InputMaybe<SortOrder>;
  usedTerasBalance?: InputMaybe<SortOrder>;
};

export type CraftCount = {
  __typename?: 'CraftCount';
  arcadeParts: Scalars['Int'];
};


export type CraftCountArcadePartsArgs = {
  where?: InputMaybe<ArcadePartWhereInput>;
};

export type CraftCountAggregate = {
  __typename?: 'CraftCountAggregate';
  _all: Scalars['Int'];
  craftedArcadeMachineId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  usedAkvBalance: Scalars['Int'];
  usedTerasBalance: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CraftCountOrderByAggregateInput = {
  craftedArcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  usedAkvBalance?: InputMaybe<SortOrder>;
  usedTerasBalance?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

/** Currency used for crafting */
export enum CraftCurrencyType {
  Akv = 'AKV',
  Teras = 'TERAS'
}

export type CraftGroupBy = {
  __typename?: 'CraftGroupBy';
  _avg?: Maybe<CraftAvgAggregate>;
  _count?: Maybe<CraftCountAggregate>;
  _max?: Maybe<CraftMaxAggregate>;
  _min?: Maybe<CraftMinAggregate>;
  _sum?: Maybe<CraftSumAggregate>;
  craftedArcadeMachineId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  usedAkvBalance: Scalars['Decimal'];
  usedTerasBalance: Scalars['Decimal'];
  userId: Scalars['String'];
};

export type CraftInput = {
  accumulator: CraftPartInput;
  lowerCabinet: CraftPartInput;
  rom: CraftPartInput;
  upperCabinet: CraftPartInput;
  usedCurrency?: InputMaybe<CraftCurrencyType>;
};

export type CraftListRelationFilter = {
  every?: InputMaybe<CraftWhereInput>;
  none?: InputMaybe<CraftWhereInput>;
  some?: InputMaybe<CraftWhereInput>;
};

export type CraftMaxAggregate = {
  __typename?: 'CraftMaxAggregate';
  craftedArcadeMachineId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAkvBalance?: Maybe<Scalars['Decimal']>;
  usedTerasBalance?: Maybe<Scalars['Decimal']>;
  userId?: Maybe<Scalars['String']>;
};

export type CraftMaxOrderByAggregateInput = {
  craftedArcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  usedAkvBalance?: InputMaybe<SortOrder>;
  usedTerasBalance?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CraftMinAggregate = {
  __typename?: 'CraftMinAggregate';
  craftedArcadeMachineId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAkvBalance?: Maybe<Scalars['Decimal']>;
  usedTerasBalance?: Maybe<Scalars['Decimal']>;
  userId?: Maybe<Scalars['String']>;
};

export type CraftMinOrderByAggregateInput = {
  craftedArcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  usedAkvBalance?: InputMaybe<SortOrder>;
  usedTerasBalance?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CraftNullableRelationFilter = {
  is?: InputMaybe<CraftWhereInput>;
  isNot?: InputMaybe<CraftWhereInput>;
};

export type CraftOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CraftOrderByWithAggregationInput = {
  _avg?: InputMaybe<CraftAvgOrderByAggregateInput>;
  _count?: InputMaybe<CraftCountOrderByAggregateInput>;
  _max?: InputMaybe<CraftMaxOrderByAggregateInput>;
  _min?: InputMaybe<CraftMinOrderByAggregateInput>;
  _sum?: InputMaybe<CraftSumOrderByAggregateInput>;
  craftedArcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  usedAkvBalance?: InputMaybe<SortOrder>;
  usedTerasBalance?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CraftOrderByWithRelationInput = {
  arcadeParts?: InputMaybe<ArcadePartOrderByRelationAggregateInput>;
  craftedArcadeMachine?: InputMaybe<ArcadeMachineOrderByWithRelationInput>;
  craftedArcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  usedAkvBalance?: InputMaybe<SortOrder>;
  usedTerasBalance?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type CraftPartInput = {
  subCategory?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
};

export enum CraftScalarFieldEnum {
  CraftedArcadeMachineId = 'craftedArcadeMachineId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UsedAkvBalance = 'usedAkvBalance',
  UsedTerasBalance = 'usedTerasBalance',
  UserId = 'userId'
}

export type CraftScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CraftScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CraftScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CraftScalarWhereWithAggregatesInput>>;
  craftedArcadeMachineId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  usedAkvBalance?: InputMaybe<DecimalWithAggregatesFilter>;
  usedTerasBalance?: InputMaybe<DecimalWithAggregatesFilter>;
  userId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type CraftSumAggregate = {
  __typename?: 'CraftSumAggregate';
  usedAkvBalance?: Maybe<Scalars['Decimal']>;
  usedTerasBalance?: Maybe<Scalars['Decimal']>;
};

export type CraftSumOrderByAggregateInput = {
  usedAkvBalance?: InputMaybe<SortOrder>;
  usedTerasBalance?: InputMaybe<SortOrder>;
};

export type CraftWhereInput = {
  AND?: InputMaybe<Array<CraftWhereInput>>;
  NOT?: InputMaybe<Array<CraftWhereInput>>;
  OR?: InputMaybe<Array<CraftWhereInput>>;
  arcadeParts?: InputMaybe<ArcadePartListRelationFilter>;
  craftedArcadeMachine?: InputMaybe<ArcadeMachineRelationFilter>;
  craftedArcadeMachineId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  usedAkvBalance?: InputMaybe<DecimalFilter>;
  usedTerasBalance?: InputMaybe<DecimalFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type CraftWhereUniqueInput = {
  AND?: InputMaybe<Array<CraftWhereInput>>;
  NOT?: InputMaybe<Array<CraftWhereInput>>;
  OR?: InputMaybe<Array<CraftWhereInput>>;
  arcadeParts?: InputMaybe<ArcadePartListRelationFilter>;
  craftedArcadeMachine?: InputMaybe<ArcadeMachineRelationFilter>;
  craftedArcadeMachineId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  usedAkvBalance?: InputMaybe<DecimalFilter>;
  usedTerasBalance?: InputMaybe<DecimalFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type CreateUserInput = {
  /** Magic didToken */
  didToken?: InputMaybe<Scalars['String']>;
  /** Firebase IDToken */
  idToken?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  /** @deprecated firebase認証に変更後削除 */
  accessToken: Scalars['String'];
  /** @deprecated firebase認証に変更後削除 */
  refreshToken: Scalars['String'];
  user: User;
};

export type CurrencyDeposit = {
  __typename?: 'CurrencyDeposit';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currencyType: CurrencyType;
  hash: Scalars['String'];
  id: Scalars['String'];
  state: DepositState;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type CurrencyDepositListRelationFilter = {
  every?: InputMaybe<CurrencyDepositWhereInput>;
  none?: InputMaybe<CurrencyDepositWhereInput>;
  some?: InputMaybe<CurrencyDepositWhereInput>;
};

export type CurrencyDepositOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CurrencyDepositOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyType?: InputMaybe<SortOrder>;
  hash?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  walletAddress?: InputMaybe<SortOrder>;
};

export enum CurrencyDepositScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  CurrencyType = 'currencyType',
  Hash = 'hash',
  Id = 'id',
  State = 'state',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  WalletAddress = 'walletAddress'
}

export type CurrencyDepositWhereInput = {
  AND?: InputMaybe<Array<CurrencyDepositWhereInput>>;
  NOT?: InputMaybe<Array<CurrencyDepositWhereInput>>;
  OR?: InputMaybe<Array<CurrencyDepositWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyType?: InputMaybe<EnumCurrencyTypeFilter>;
  hash?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  state?: InputMaybe<EnumDepositStateFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  walletAddress?: InputMaybe<StringFilter>;
};

export type CurrencyDepositWhereUniqueInput = {
  AND?: InputMaybe<Array<CurrencyDepositWhereInput>>;
  NOT?: InputMaybe<Array<CurrencyDepositWhereInput>>;
  OR?: InputMaybe<Array<CurrencyDepositWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyType?: InputMaybe<EnumCurrencyTypeFilter>;
  hash?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<EnumDepositStateFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  walletAddress?: InputMaybe<StringFilter>;
};

export enum CurrencyType {
  Akir = 'AKIR',
  Akv = 'AKV'
}

export type CurrencyWithdrawalWhereInput = {
  AND?: InputMaybe<Array<CurrencyWithdrawalWhereInput>>;
  NOT?: InputMaybe<Array<CurrencyWithdrawalWhereInput>>;
  OR?: InputMaybe<Array<CurrencyWithdrawalWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyType?: InputMaybe<EnumCurrencyTypeFilter>;
  errorMessage?: InputMaybe<StringNullableFilter>;
  hash?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  nonce?: InputMaybe<IntNullableFilter>;
  response?: InputMaybe<StringNullableFilter>;
  signerAddress?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<EnumWithdrawalStateFilter>;
  type?: InputMaybe<EnumWithdrawalTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
  walletAddress?: InputMaybe<StringFilter>;
};

export type CurrentUserNotificationWhereInput = {
  createdAt?: InputMaybe<DateTimeFilter>;
  nftType?: InputMaybe<EnumNftTypeFilter>;
  notificationType?: InputMaybe<EnumNotificationTypeFilter>;
  tokenId?: InputMaybe<StringNullableFilter>;
};

export type CurrentUserQuestChainWhereInput = {
  AND?: InputMaybe<Array<CurrentUserQuestChainWhereInput>>;
  NOT?: InputMaybe<Array<CurrentUserQuestChainWhereInput>>;
  OR?: InputMaybe<Array<CurrentUserQuestChainWhereInput>>;
  acceptedAt?: InputMaybe<DateTimeFilter>;
  completed?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  questChainMasterId?: InputMaybe<StringFilter>;
  quests?: InputMaybe<QuestListRelationFilter>;
};

export type CurrentUserRewardWhereInput = {
  AND?: InputMaybe<Array<CurrentUserRewardWhereInput>>;
  NOT?: InputMaybe<Array<CurrentUserRewardWhereInput>>;
  OR?: InputMaybe<Array<CurrentUserRewardWhereInput>>;
  acceptedAt?: InputMaybe<DateTimeNullableFilter>;
  amount?: InputMaybe<IntNullableFilter>;
  availableUntil?: InputMaybe<DateTimeNullableFilter>;
  category?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  rewardItemType?: InputMaybe<EnumRewardItemTypeFilter>;
  subCategory?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type Deposit = {
  __typename?: 'Deposit';
  createdAt: Scalars['DateTime'];
  hash?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  nftType: NftType;
  state: DepositState;
  tokenId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  walletAddress?: Maybe<Scalars['String']>;
};

export type DepositAkvInput = {
  amount: Scalars['Decimal'];
  transactionHash?: InputMaybe<Scalars['String']>;
};

export type DepositArcadeMachineInput = {
  hash: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type DepositArcadePartInput = {
  hash: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type DepositGameCenterInput = {
  hash: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type DepositListRelationFilter = {
  every?: InputMaybe<DepositWhereInput>;
  none?: InputMaybe<DepositWhereInput>;
  some?: InputMaybe<DepositWhereInput>;
};

export type DepositOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DepositOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  hash?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  nftType?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  tokenId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
  walletAddress?: InputMaybe<SortOrderInput>;
};

export enum DepositScalarFieldEnum {
  CreatedAt = 'createdAt',
  Hash = 'hash',
  Id = 'id',
  NftType = 'nftType',
  State = 'state',
  TokenId = 'tokenId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  WalletAddress = 'walletAddress'
}

export enum DepositState {
  Confirmed = 'CONFIRMED',
  Invalidated = 'INVALIDATED',
  Pending = 'PENDING',
  Unprocessed = 'UNPROCESSED'
}

export type DepositWhereInput = {
  AND?: InputMaybe<Array<DepositWhereInput>>;
  NOT?: InputMaybe<Array<DepositWhereInput>>;
  OR?: InputMaybe<Array<DepositWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  hash?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  nftType?: InputMaybe<EnumNftTypeFilter>;
  state?: InputMaybe<EnumDepositStateFilter>;
  tokenId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
  walletAddress?: InputMaybe<StringNullableFilter>;
};

export type DepositWhereUniqueInput = {
  AND?: InputMaybe<Array<DepositWhereInput>>;
  NOT?: InputMaybe<Array<DepositWhereInput>>;
  OR?: InputMaybe<Array<DepositWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  hash?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  nftType?: InputMaybe<EnumNftTypeFilter>;
  state?: InputMaybe<EnumDepositStateFilter>;
  tokenId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
  walletAddress?: InputMaybe<StringNullableFilter>;
};

export type Dismantle = {
  __typename?: 'Dismantle';
  _count?: Maybe<DismantleCount>;
  arcadeMachineId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  feverSparkRemain: Scalars['Int'];
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type DismantleCount = {
  __typename?: 'DismantleCount';
  createdArcadeParts: Scalars['Int'];
};


export type DismantleCountCreatedArcadePartsArgs = {
  where?: InputMaybe<ArcadePartWhereInput>;
};

/** Currency used for dismantle */
export enum DismantleCurrencyType {
  Akv = 'AKV',
  Teras = 'TERAS'
}

export type DismantleInput = {
  arcadeMachineId?: InputMaybe<Scalars['String']>;
  usedCurrency?: InputMaybe<DismantleCurrencyType>;
};

export type DismantleListRelationFilter = {
  every?: InputMaybe<DismantleWhereInput>;
  none?: InputMaybe<DismantleWhereInput>;
  some?: InputMaybe<DismantleWhereInput>;
};

export type DismantleNullableRelationFilter = {
  is?: InputMaybe<DismantleWhereInput>;
  isNot?: InputMaybe<DismantleWhereInput>;
};

export type DismantleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DismantleOrderByWithRelationInput = {
  arcadeMachine?: InputMaybe<ArcadeMachineOrderByWithRelationInput>;
  arcadeMachineId?: InputMaybe<SortOrder>;
  createdArcadeParts?: InputMaybe<ArcadePartOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  feverSparkRemain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type DismantleOutput = {
  __typename?: 'DismantleOutput';
  lowerCabinet: ArcadePart;
  lowerCabinetGradeUp: Scalars['Boolean'];
  rom: ArcadePart;
  upperCabinet: ArcadePart;
  upperCabinetGradeUp: Scalars['Boolean'];
};

export enum DismantleScalarFieldEnum {
  ArcadeMachineId = 'arcadeMachineId',
  CreatedAt = 'createdAt',
  FeverSparkRemain = 'feverSparkRemain',
  Id = 'id',
  UserId = 'userId'
}

export type DismantleWhereInput = {
  AND?: InputMaybe<Array<DismantleWhereInput>>;
  NOT?: InputMaybe<Array<DismantleWhereInput>>;
  OR?: InputMaybe<Array<DismantleWhereInput>>;
  arcadeMachine?: InputMaybe<ArcadeMachineRelationFilter>;
  arcadeMachineId?: InputMaybe<StringFilter>;
  createdArcadeParts?: InputMaybe<ArcadePartListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  feverSparkRemain?: InputMaybe<IntFilter>;
  id?: InputMaybe<UuidFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type DismantleWhereUniqueInput = {
  AND?: InputMaybe<Array<DismantleWhereInput>>;
  NOT?: InputMaybe<Array<DismantleWhereInput>>;
  OR?: InputMaybe<Array<DismantleWhereInput>>;
  arcadeMachine?: InputMaybe<ArcadeMachineRelationFilter>;
  arcadeMachineId?: InputMaybe<Scalars['String']>;
  createdArcadeParts?: InputMaybe<ArcadePartListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  feverSparkRemain?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type EnterTournamentInput = {
  tournamentId?: InputMaybe<Scalars['String']>;
};

export type EnumArcadePartCategoryFilter = {
  equals?: InputMaybe<ArcadePartCategory>;
  in?: InputMaybe<Array<ArcadePartCategory>>;
  not?: InputMaybe<NestedEnumArcadePartCategoryFilter>;
  notIn?: InputMaybe<Array<ArcadePartCategory>>;
};

export type EnumArcadePartCategoryWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumArcadePartCategoryFilter>;
  _min?: InputMaybe<NestedEnumArcadePartCategoryFilter>;
  equals?: InputMaybe<ArcadePartCategory>;
  in?: InputMaybe<Array<ArcadePartCategory>>;
  not?: InputMaybe<NestedEnumArcadePartCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ArcadePartCategory>>;
};

export type EnumBoosterCategoryFilter = {
  equals?: InputMaybe<BoosterCategory>;
  in?: InputMaybe<Array<BoosterCategory>>;
  not?: InputMaybe<NestedEnumBoosterCategoryFilter>;
  notIn?: InputMaybe<Array<BoosterCategory>>;
};

export type EnumBurnStateFilter = {
  equals?: InputMaybe<BurnState>;
  in?: InputMaybe<Array<BurnState>>;
  not?: InputMaybe<NestedEnumBurnStateFilter>;
  notIn?: InputMaybe<Array<BurnState>>;
};

export type EnumCollectibleItemCategoryFilter = {
  equals?: InputMaybe<CollectibleItemCategory>;
  in?: InputMaybe<Array<CollectibleItemCategory>>;
  not?: InputMaybe<NestedEnumCollectibleItemCategoryFilter>;
  notIn?: InputMaybe<Array<CollectibleItemCategory>>;
};

export type EnumCurrencyTypeFilter = {
  equals?: InputMaybe<CurrencyType>;
  in?: InputMaybe<Array<CurrencyType>>;
  not?: InputMaybe<NestedEnumCurrencyTypeFilter>;
  notIn?: InputMaybe<Array<CurrencyType>>;
};

export type EnumDepositStateFilter = {
  equals?: InputMaybe<DepositState>;
  in?: InputMaybe<Array<DepositState>>;
  not?: InputMaybe<NestedEnumDepositStateFilter>;
  notIn?: InputMaybe<Array<DepositState>>;
};

export type EnumExtractableItemTypeFilter = {
  equals?: InputMaybe<ExtractableItemType>;
  in?: InputMaybe<Array<ExtractableItemType>>;
  not?: InputMaybe<NestedEnumExtractableItemTypeFilter>;
  notIn?: InputMaybe<Array<ExtractableItemType>>;
};

export type EnumExtractableItemTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumExtractableItemTypeFilter>;
  _min?: InputMaybe<NestedEnumExtractableItemTypeFilter>;
  equals?: InputMaybe<ExtractableItemType>;
  in?: InputMaybe<Array<ExtractableItemType>>;
  not?: InputMaybe<NestedEnumExtractableItemTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ExtractableItemType>>;
};

export type EnumFrontEndTypeFilter = {
  equals?: InputMaybe<FrontEndType>;
  in?: InputMaybe<Array<FrontEndType>>;
  not?: InputMaybe<NestedEnumFrontEndTypeFilter>;
  notIn?: InputMaybe<Array<FrontEndType>>;
};

export type EnumGameCenterAreaFilter = {
  equals?: InputMaybe<GameCenterArea>;
  in?: InputMaybe<Array<GameCenterArea>>;
  not?: InputMaybe<NestedEnumGameCenterAreaFilter>;
  notIn?: InputMaybe<Array<GameCenterArea>>;
};

export type EnumGameCenterAreaWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumGameCenterAreaFilter>;
  _min?: InputMaybe<NestedEnumGameCenterAreaFilter>;
  equals?: InputMaybe<GameCenterArea>;
  in?: InputMaybe<Array<GameCenterArea>>;
  not?: InputMaybe<NestedEnumGameCenterAreaWithAggregatesFilter>;
  notIn?: InputMaybe<Array<GameCenterArea>>;
};

export type EnumGameCenterSizeFilter = {
  equals?: InputMaybe<GameCenterSize>;
  in?: InputMaybe<Array<GameCenterSize>>;
  not?: InputMaybe<NestedEnumGameCenterSizeFilter>;
  notIn?: InputMaybe<Array<GameCenterSize>>;
};

export type EnumGameCenterSizeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumGameCenterSizeFilter>;
  _min?: InputMaybe<NestedEnumGameCenterSizeFilter>;
  equals?: InputMaybe<GameCenterSize>;
  in?: InputMaybe<Array<GameCenterSize>>;
  not?: InputMaybe<NestedEnumGameCenterSizeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<GameCenterSize>>;
};

export type EnumIconTypeFilter = {
  equals?: InputMaybe<IconType>;
  in?: InputMaybe<Array<IconType>>;
  not?: InputMaybe<NestedEnumIconTypeFilter>;
  notIn?: InputMaybe<Array<IconType>>;
};

export type EnumIconTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumIconTypeFilter>;
  _min?: InputMaybe<NestedEnumIconTypeFilter>;
  equals?: InputMaybe<IconType>;
  in?: InputMaybe<Array<IconType>>;
  not?: InputMaybe<NestedEnumIconTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<IconType>>;
};

export type EnumNewsCategoryFilter = {
  equals?: InputMaybe<NewsCategory>;
  in?: InputMaybe<Array<NewsCategory>>;
  not?: InputMaybe<NestedEnumNewsCategoryFilter>;
  notIn?: InputMaybe<Array<NewsCategory>>;
};

export type EnumNftStateFilter = {
  equals?: InputMaybe<NftState>;
  in?: InputMaybe<Array<NftState>>;
  not?: InputMaybe<NestedEnumNftStateFilter>;
  notIn?: InputMaybe<Array<NftState>>;
};

export type EnumNftStateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNftStateFilter>;
  _min?: InputMaybe<NestedEnumNftStateFilter>;
  equals?: InputMaybe<NftState>;
  in?: InputMaybe<Array<NftState>>;
  not?: InputMaybe<NestedEnumNftStateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NftState>>;
};

export type EnumNftTypeFilter = {
  equals?: InputMaybe<NftType>;
  in?: InputMaybe<Array<NftType>>;
  not?: InputMaybe<NestedEnumNftTypeFilter>;
  notIn?: InputMaybe<Array<NftType>>;
};

export type EnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumOperatingSystemFilter = {
  equals?: InputMaybe<OperatingSystem>;
  in?: InputMaybe<Array<OperatingSystem>>;
  not?: InputMaybe<NestedEnumOperatingSystemFilter>;
  notIn?: InputMaybe<Array<OperatingSystem>>;
};

export type EnumPlayResultNullableFilter = {
  equals?: InputMaybe<PlayResult>;
  in?: InputMaybe<Array<PlayResult>>;
  not?: InputMaybe<NestedEnumPlayResultNullableFilter>;
  notIn?: InputMaybe<Array<PlayResult>>;
};

export type EnumPlayResultNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumPlayResultNullableFilter>;
  _min?: InputMaybe<NestedEnumPlayResultNullableFilter>;
  equals?: InputMaybe<PlayResult>;
  in?: InputMaybe<Array<PlayResult>>;
  not?: InputMaybe<NestedEnumPlayResultNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PlayResult>>;
};

export type EnumPlaySessionStateFilter = {
  equals?: InputMaybe<PlaySessionState>;
  in?: InputMaybe<Array<PlaySessionState>>;
  not?: InputMaybe<NestedEnumPlaySessionStateFilter>;
  notIn?: InputMaybe<Array<PlaySessionState>>;
};

export type EnumPrizeSendStatusNullableFilter = {
  equals?: InputMaybe<PrizeSendStatus>;
  in?: InputMaybe<Array<PrizeSendStatus>>;
  not?: InputMaybe<NestedEnumPrizeSendStatusNullableFilter>;
  notIn?: InputMaybe<Array<PrizeSendStatus>>;
};

export type EnumPurchaseStatusFilter = {
  equals?: InputMaybe<PurchaseStatus>;
  in?: InputMaybe<Array<PurchaseStatus>>;
  not?: InputMaybe<NestedEnumPurchaseStatusFilter>;
  notIn?: InputMaybe<Array<PurchaseStatus>>;
};

export type EnumRewardCategoryFilter = {
  equals?: InputMaybe<RewardCategory>;
  in?: InputMaybe<Array<RewardCategory>>;
  not?: InputMaybe<NestedEnumRewardCategoryFilter>;
  notIn?: InputMaybe<Array<RewardCategory>>;
};

export type EnumRewardItemTypeFilter = {
  equals?: InputMaybe<RewardItemType>;
  in?: InputMaybe<Array<RewardItemType>>;
  not?: InputMaybe<NestedEnumRewardItemTypeFilter>;
  notIn?: InputMaybe<Array<RewardItemType>>;
};

export type EnumTicketTransactionTypeFilter = {
  equals?: InputMaybe<TicketTransactionType>;
  in?: InputMaybe<Array<TicketTransactionType>>;
  not?: InputMaybe<NestedEnumTicketTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TicketTransactionType>>;
};

export type EnumWithdrawalStateFilter = {
  equals?: InputMaybe<WithdrawalState>;
  in?: InputMaybe<Array<WithdrawalState>>;
  not?: InputMaybe<NestedEnumWithdrawalStateFilter>;
  notIn?: InputMaybe<Array<WithdrawalState>>;
};

export type EnumWithdrawalTypeNullableFilter = {
  equals?: InputMaybe<WithdrawalType>;
  in?: InputMaybe<Array<WithdrawalType>>;
  not?: InputMaybe<NestedEnumWithdrawalTypeNullableFilter>;
  notIn?: InputMaybe<Array<WithdrawalType>>;
};

export type ExecuteExtractInput = {
  arcadeMachineId?: InputMaybe<Scalars['String']>;
  extractCode?: InputMaybe<Scalars['Float']>;
  usedCurrency?: InputMaybe<ExtractCurrencyType>;
};

export type ExecuteExtractOutput = ArcadePart | Junk;

export type Extract = {
  __typename?: 'Extract';
  arcadeMachine: ArcadeMachine;
  arcadeMachineId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  extractArcadePartsCount: Scalars['Int'];
  extractDetail: Scalars['JSON'];
  extractJunkPartsCount: Scalars['Int'];
  id: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

export type ExtractAvgAggregate = {
  __typename?: 'ExtractAvgAggregate';
  extractArcadePartsCount?: Maybe<Scalars['Float']>;
  extractJunkPartsCount?: Maybe<Scalars['Float']>;
};

export type ExtractAvgOrderByAggregateInput = {
  extractArcadePartsCount?: InputMaybe<SortOrder>;
  extractJunkPartsCount?: InputMaybe<SortOrder>;
};

export type ExtractCountAggregate = {
  __typename?: 'ExtractCountAggregate';
  _all: Scalars['Int'];
  arcadeMachineId: Scalars['Int'];
  createdAt: Scalars['Int'];
  extractArcadePartsCount: Scalars['Int'];
  extractDetail: Scalars['Int'];
  extractJunkPartsCount: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ExtractCountOrderByAggregateInput = {
  arcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  extractArcadePartsCount?: InputMaybe<SortOrder>;
  extractDetail?: InputMaybe<SortOrder>;
  extractJunkPartsCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

/** Currency used for extracting */
export enum ExtractCurrencyType {
  Akv = 'AKV',
  Teras = 'TERAS'
}

export type ExtractGroupBy = {
  __typename?: 'ExtractGroupBy';
  _avg?: Maybe<ExtractAvgAggregate>;
  _count?: Maybe<ExtractCountAggregate>;
  _max?: Maybe<ExtractMaxAggregate>;
  _min?: Maybe<ExtractMinAggregate>;
  _sum?: Maybe<ExtractSumAggregate>;
  arcadeMachineId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  extractArcadePartsCount: Scalars['Int'];
  extractDetail: Scalars['JSON'];
  extractJunkPartsCount: Scalars['Int'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ExtractInfoOutput = {
  __typename?: 'ExtractInfoOutput';
  count: Scalars['Float'];
  extractCode?: Maybe<Scalars['Float']>;
};

export type ExtractInitialInventory = {
  __typename?: 'ExtractInitialInventory';
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  featuredItem: Scalars['Boolean'];
  id: Scalars['String'];
  initialAmount: Scalars['Int'];
  itemType: ExtractableItemType;
  seasonId: Scalars['String'];
  subCategory: Scalars['String'];
};

export type ExtractInitialInventoryAvgAggregate = {
  __typename?: 'ExtractInitialInventoryAvgAggregate';
  initialAmount?: Maybe<Scalars['Float']>;
};

export type ExtractInitialInventoryAvgOrderByAggregateInput = {
  initialAmount?: InputMaybe<SortOrder>;
};

export type ExtractInitialInventoryCountAggregate = {
  __typename?: 'ExtractInitialInventoryCountAggregate';
  _all: Scalars['Int'];
  category: Scalars['Int'];
  createdAt: Scalars['Int'];
  featuredItem: Scalars['Int'];
  id: Scalars['Int'];
  initialAmount: Scalars['Int'];
  itemType: Scalars['Int'];
  seasonId: Scalars['Int'];
  subCategory: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ExtractInitialInventoryCountOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  featuredItem?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initialAmount?: InputMaybe<SortOrder>;
  itemType?: InputMaybe<SortOrder>;
  seasonId?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export type ExtractInitialInventoryGroupBy = {
  __typename?: 'ExtractInitialInventoryGroupBy';
  _avg?: Maybe<ExtractInitialInventoryAvgAggregate>;
  _count?: Maybe<ExtractInitialInventoryCountAggregate>;
  _max?: Maybe<ExtractInitialInventoryMaxAggregate>;
  _min?: Maybe<ExtractInitialInventoryMinAggregate>;
  _sum?: Maybe<ExtractInitialInventorySumAggregate>;
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  featuredItem: Scalars['Boolean'];
  id: Scalars['String'];
  initialAmount: Scalars['Int'];
  itemType: ExtractableItemType;
  seasonId: Scalars['String'];
  subCategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ExtractInitialInventoryMaxAggregate = {
  __typename?: 'ExtractInitialInventoryMaxAggregate';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  featuredItem?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  initialAmount?: Maybe<Scalars['Int']>;
  itemType?: Maybe<ExtractableItemType>;
  seasonId?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtractInitialInventoryMaxOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  featuredItem?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initialAmount?: InputMaybe<SortOrder>;
  itemType?: InputMaybe<SortOrder>;
  seasonId?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export type ExtractInitialInventoryMinAggregate = {
  __typename?: 'ExtractInitialInventoryMinAggregate';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  featuredItem?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  initialAmount?: Maybe<Scalars['Int']>;
  itemType?: Maybe<ExtractableItemType>;
  seasonId?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtractInitialInventoryMinOrderByAggregateInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  featuredItem?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initialAmount?: InputMaybe<SortOrder>;
  itemType?: InputMaybe<SortOrder>;
  seasonId?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export type ExtractInitialInventoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<ExtractInitialInventoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<ExtractInitialInventoryCountOrderByAggregateInput>;
  _max?: InputMaybe<ExtractInitialInventoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<ExtractInitialInventoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<ExtractInitialInventorySumOrderByAggregateInput>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  featuredItem?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initialAmount?: InputMaybe<SortOrder>;
  itemType?: InputMaybe<SortOrder>;
  seasonId?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export type ExtractInitialInventoryOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  featuredItem?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  initialAmount?: InputMaybe<SortOrder>;
  itemType?: InputMaybe<SortOrder>;
  seasonId?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export enum ExtractInitialInventoryScalarFieldEnum {
  Category = 'category',
  CreatedAt = 'createdAt',
  FeaturedItem = 'featuredItem',
  Id = 'id',
  InitialAmount = 'initialAmount',
  ItemType = 'itemType',
  SeasonId = 'seasonId',
  SubCategory = 'subCategory',
  UpdatedAt = 'updatedAt'
}

export type ExtractInitialInventoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ExtractInitialInventoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ExtractInitialInventoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ExtractInitialInventoryScalarWhereWithAggregatesInput>>;
  category?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  featuredItem?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  initialAmount?: InputMaybe<IntWithAggregatesFilter>;
  itemType?: InputMaybe<EnumExtractableItemTypeWithAggregatesFilter>;
  seasonId?: InputMaybe<StringWithAggregatesFilter>;
  subCategory?: InputMaybe<StringWithAggregatesFilter>;
};

export type ExtractInitialInventorySeasonIdItemTypeCategorySubCategoryCompoundUniqueInput = {
  category: Scalars['String'];
  itemType: ExtractableItemType;
  seasonId: Scalars['String'];
  subCategory: Scalars['String'];
};

export type ExtractInitialInventorySumAggregate = {
  __typename?: 'ExtractInitialInventorySumAggregate';
  initialAmount?: Maybe<Scalars['Int']>;
};

export type ExtractInitialInventorySumOrderByAggregateInput = {
  initialAmount?: InputMaybe<SortOrder>;
};

export type ExtractInitialInventoryWhereInput = {
  AND?: InputMaybe<Array<ExtractInitialInventoryWhereInput>>;
  NOT?: InputMaybe<Array<ExtractInitialInventoryWhereInput>>;
  OR?: InputMaybe<Array<ExtractInitialInventoryWhereInput>>;
  category?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  featuredItem?: InputMaybe<BoolFilter>;
  id?: InputMaybe<UuidFilter>;
  initialAmount?: InputMaybe<IntFilter>;
  itemType?: InputMaybe<EnumExtractableItemTypeFilter>;
  seasonId?: InputMaybe<StringFilter>;
  subCategory?: InputMaybe<StringFilter>;
};

export type ExtractInitialInventoryWhereUniqueInput = {
  AND?: InputMaybe<Array<ExtractInitialInventoryWhereInput>>;
  NOT?: InputMaybe<Array<ExtractInitialInventoryWhereInput>>;
  OR?: InputMaybe<Array<ExtractInitialInventoryWhereInput>>;
  category?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  featuredItem?: InputMaybe<BoolFilter>;
  id?: InputMaybe<Scalars['String']>;
  initialAmount?: InputMaybe<IntFilter>;
  itemType?: InputMaybe<EnumExtractableItemTypeFilter>;
  seasonId?: InputMaybe<StringFilter>;
  seasonId_itemType_category_subCategory?: InputMaybe<ExtractInitialInventorySeasonIdItemTypeCategorySubCategoryCompoundUniqueInput>;
  subCategory?: InputMaybe<StringFilter>;
};

export type ExtractJunkInventory = {
  __typename?: 'ExtractJunkInventory';
  amount: Scalars['Int'];
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  subCategory: Scalars['String'];
};

export type ExtractJunkInventoryAvgAggregate = {
  __typename?: 'ExtractJunkInventoryAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type ExtractJunkInventoryAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type ExtractJunkInventoryCategorySubCategoryCompoundUniqueInput = {
  category: Scalars['String'];
  subCategory: Scalars['String'];
};

export type ExtractJunkInventoryCountAggregate = {
  __typename?: 'ExtractJunkInventoryCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  category: Scalars['Int'];
  createdAt: Scalars['Int'];
  subCategory: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type ExtractJunkInventoryCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export type ExtractJunkInventoryGroupBy = {
  __typename?: 'ExtractJunkInventoryGroupBy';
  _avg?: Maybe<ExtractJunkInventoryAvgAggregate>;
  _count?: Maybe<ExtractJunkInventoryCountAggregate>;
  _max?: Maybe<ExtractJunkInventoryMaxAggregate>;
  _min?: Maybe<ExtractJunkInventoryMinAggregate>;
  _sum?: Maybe<ExtractJunkInventorySumAggregate>;
  amount: Scalars['Int'];
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  subCategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ExtractJunkInventoryMaxAggregate = {
  __typename?: 'ExtractJunkInventoryMaxAggregate';
  amount?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  subCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtractJunkInventoryMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export type ExtractJunkInventoryMinAggregate = {
  __typename?: 'ExtractJunkInventoryMinAggregate';
  amount?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  subCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtractJunkInventoryMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export type ExtractJunkInventoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<ExtractJunkInventoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<ExtractJunkInventoryCountOrderByAggregateInput>;
  _max?: InputMaybe<ExtractJunkInventoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<ExtractJunkInventoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<ExtractJunkInventorySumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export type ExtractJunkInventoryOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
};

export enum ExtractJunkInventoryScalarFieldEnum {
  Amount = 'amount',
  Category = 'category',
  CreatedAt = 'createdAt',
  SubCategory = 'subCategory',
  UpdatedAt = 'updatedAt'
}

export type ExtractJunkInventoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ExtractJunkInventoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ExtractJunkInventoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ExtractJunkInventoryScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<IntWithAggregatesFilter>;
  category?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  subCategory?: InputMaybe<StringWithAggregatesFilter>;
};

export type ExtractJunkInventorySumAggregate = {
  __typename?: 'ExtractJunkInventorySumAggregate';
  amount?: Maybe<Scalars['Int']>;
};

export type ExtractJunkInventorySumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type ExtractJunkInventoryWhereInput = {
  AND?: InputMaybe<Array<ExtractJunkInventoryWhereInput>>;
  NOT?: InputMaybe<Array<ExtractJunkInventoryWhereInput>>;
  OR?: InputMaybe<Array<ExtractJunkInventoryWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  category?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  subCategory?: InputMaybe<StringFilter>;
};

export type ExtractJunkInventoryWhereUniqueInput = {
  AND?: InputMaybe<Array<ExtractJunkInventoryWhereInput>>;
  NOT?: InputMaybe<Array<ExtractJunkInventoryWhereInput>>;
  OR?: InputMaybe<Array<ExtractJunkInventoryWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  category?: InputMaybe<StringFilter>;
  category_subCategory?: InputMaybe<ExtractJunkInventoryCategorySubCategoryCompoundUniqueInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  subCategory?: InputMaybe<StringFilter>;
};

export type ExtractListRelationFilter = {
  every?: InputMaybe<ExtractWhereInput>;
  none?: InputMaybe<ExtractWhereInput>;
  some?: InputMaybe<ExtractWhereInput>;
};

export type ExtractMaxAggregate = {
  __typename?: 'ExtractMaxAggregate';
  arcadeMachineId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extractArcadePartsCount?: Maybe<Scalars['Int']>;
  extractJunkPartsCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ExtractMaxOrderByAggregateInput = {
  arcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  extractArcadePartsCount?: InputMaybe<SortOrder>;
  extractJunkPartsCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ExtractMinAggregate = {
  __typename?: 'ExtractMinAggregate';
  arcadeMachineId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extractArcadePartsCount?: Maybe<Scalars['Int']>;
  extractJunkPartsCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type ExtractMinOrderByAggregateInput = {
  arcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  extractArcadePartsCount?: InputMaybe<SortOrder>;
  extractJunkPartsCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ExtractOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ExtractOrderByWithAggregationInput = {
  _avg?: InputMaybe<ExtractAvgOrderByAggregateInput>;
  _count?: InputMaybe<ExtractCountOrderByAggregateInput>;
  _max?: InputMaybe<ExtractMaxOrderByAggregateInput>;
  _min?: InputMaybe<ExtractMinOrderByAggregateInput>;
  _sum?: InputMaybe<ExtractSumOrderByAggregateInput>;
  arcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  extractArcadePartsCount?: InputMaybe<SortOrder>;
  extractDetail?: InputMaybe<SortOrder>;
  extractJunkPartsCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ExtractOrderByWithRelationInput = {
  arcadeMachine?: InputMaybe<ArcadeMachineOrderByWithRelationInput>;
  arcadeMachineId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  extractArcadePartsCount?: InputMaybe<SortOrder>;
  extractDetail?: InputMaybe<SortOrder>;
  extractJunkPartsCount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum ExtractScalarFieldEnum {
  ArcadeMachineId = 'arcadeMachineId',
  CreatedAt = 'createdAt',
  ExtractArcadePartsCount = 'extractArcadePartsCount',
  ExtractDetail = 'extractDetail',
  ExtractJunkPartsCount = 'extractJunkPartsCount',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ExtractScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ExtractScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ExtractScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ExtractScalarWhereWithAggregatesInput>>;
  arcadeMachineId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  extractArcadePartsCount?: InputMaybe<IntWithAggregatesFilter>;
  extractDetail?: InputMaybe<JsonWithAggregatesFilter>;
  extractJunkPartsCount?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  userId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type ExtractSumAggregate = {
  __typename?: 'ExtractSumAggregate';
  extractArcadePartsCount?: Maybe<Scalars['Int']>;
  extractJunkPartsCount?: Maybe<Scalars['Int']>;
};

export type ExtractSumOrderByAggregateInput = {
  extractArcadePartsCount?: InputMaybe<SortOrder>;
  extractJunkPartsCount?: InputMaybe<SortOrder>;
};

export type ExtractWhereInput = {
  AND?: InputMaybe<Array<ExtractWhereInput>>;
  NOT?: InputMaybe<Array<ExtractWhereInput>>;
  OR?: InputMaybe<Array<ExtractWhereInput>>;
  arcadeMachine?: InputMaybe<ArcadeMachineRelationFilter>;
  arcadeMachineId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  extractArcadePartsCount?: InputMaybe<IntFilter>;
  extractDetail?: InputMaybe<JsonFilter>;
  extractJunkPartsCount?: InputMaybe<IntFilter>;
  id?: InputMaybe<UuidFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type ExtractWhereUniqueInput = {
  AND?: InputMaybe<Array<ExtractWhereInput>>;
  NOT?: InputMaybe<Array<ExtractWhereInput>>;
  OR?: InputMaybe<Array<ExtractWhereInput>>;
  arcadeMachine?: InputMaybe<ArcadeMachineRelationFilter>;
  arcadeMachineId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  extractArcadePartsCount?: InputMaybe<IntFilter>;
  extractDetail?: InputMaybe<JsonFilter>;
  extractJunkPartsCount?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export enum ExtractableItemType {
  ArcadePart = 'ARCADE_PART',
  JunkPart = 'JUNK_PART'
}

export type FinishPlayInput = {
  playSessionToken?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
  signature?: InputMaybe<Scalars['String']>;
  timeStamp?: InputMaybe<Scalars['DateTime']>;
};

export type FinishPlayOutput = {
  __typename?: 'FinishPlayOutput';
  success: Scalars['Boolean'];
};

export type FinishPlaySessionInput = {
  playSessionToken?: InputMaybe<Scalars['String']>;
};

export type FinishPlaySessionOutput = {
  __typename?: 'FinishPlaySessionOutput';
  session: PlaySession;
};

export type FinishQuestChainInput = {
  questMasterId?: InputMaybe<Scalars['String']>;
};

export type FinishQuestChainOutput = {
  __typename?: 'FinishQuestChainOutput';
  questChain: QuestChain;
  rewards: Array<AcceptReward>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum FrontEndType {
  Gp = 'GP',
  Wm = 'WM'
}

export type Game = {
  __typename?: 'Game';
  category: Scalars['String'];
  craftFee: Scalars['Decimal'];
  enabled: Scalars['Boolean'];
  gamePath: Scalars['String'];
  help: Help;
  hotGame: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  onlyTournament: Scalars['Boolean'];
  order: Scalars['Int'];
  publisherId: Scalars['String'];
  rarity: Rarity;
  recipe: Recipe;
  winCondition: Scalars['String'];
};

export type GameCenter = {
  __typename?: 'GameCenter';
  _count?: Maybe<GameCenterCount>;
  arcadeMachines: Array<ArcadeMachine>;
  area: GameCenterArea;
  createdAt: Scalars['DateTime'];
  dailyInstallationFee: Scalars['Decimal'];
  id: Scalars['String'];
  installationFee: Scalars['Decimal'];
  metadata: Metadata;
  name: Scalars['String'];
  placementAllowed: Scalars['Boolean'];
  playSessions: Array<PlaySession>;
  size: GameCenterSize;
  state: NftState;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  xCoordinate: Scalars['Int'];
  yCoordinate: Scalars['Int'];
};


export type GameCenterArcadeMachinesArgs = {
  cursor?: InputMaybe<ArcadeMachineWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArcadeMachineScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArcadeMachineOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadeMachineWhereInput>;
};


export type GameCenterPlaySessionsArgs = {
  cursor?: InputMaybe<PlaySessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaySessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaySessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type GameCenterUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export enum GameCenterArea {
  Akihabara = 'AKIHABARA',
  Shibuya = 'SHIBUYA'
}

export type GameCenterAvgAggregate = {
  __typename?: 'GameCenterAvgAggregate';
  lastBlock?: Maybe<Scalars['Float']>;
  lastTransactionIndex?: Maybe<Scalars['Float']>;
  xCoordinate?: Maybe<Scalars['Float']>;
  yCoordinate?: Maybe<Scalars['Float']>;
};

export type GameCenterAvgOrderByAggregateInput = {
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  xCoordinate?: InputMaybe<SortOrder>;
  yCoordinate?: InputMaybe<SortOrder>;
};

export type GameCenterCount = {
  __typename?: 'GameCenterCount';
  arcadeMachines: Scalars['Int'];
  playSessions: Scalars['Int'];
};


export type GameCenterCountArcadeMachinesArgs = {
  where?: InputMaybe<ArcadeMachineWhereInput>;
};


export type GameCenterCountPlaySessionsArgs = {
  where?: InputMaybe<PlaySessionWhereInput>;
};

export type GameCenterCountAggregate = {
  __typename?: 'GameCenterCountAggregate';
  _all: Scalars['Int'];
  area: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  lastBlock: Scalars['Int'];
  lastTransactionIndex: Scalars['Int'];
  name: Scalars['Int'];
  ownerWalletAddress: Scalars['Int'];
  physicalWalletAddress: Scalars['Int'];
  placementAllowed: Scalars['Int'];
  size: Scalars['Int'];
  state: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
  xCoordinate: Scalars['Int'];
  yCoordinate: Scalars['Int'];
};

export type GameCenterCountOrderByAggregateInput = {
  area?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  placementAllowed?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  xCoordinate?: InputMaybe<SortOrder>;
  yCoordinate?: InputMaybe<SortOrder>;
};

export type GameCenterGroupBy = {
  __typename?: 'GameCenterGroupBy';
  _avg?: Maybe<GameCenterAvgAggregate>;
  _count?: Maybe<GameCenterCountAggregate>;
  _max?: Maybe<GameCenterMaxAggregate>;
  _min?: Maybe<GameCenterMinAggregate>;
  _sum?: Maybe<GameCenterSumAggregate>;
  area: GameCenterArea;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lastBlock: Scalars['Int'];
  lastTransactionIndex: Scalars['Int'];
  name: Scalars['String'];
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  placementAllowed: Scalars['Boolean'];
  size: GameCenterSize;
  state: NftState;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  xCoordinate: Scalars['Int'];
  yCoordinate: Scalars['Int'];
};

export type GameCenterListRelationFilter = {
  every?: InputMaybe<GameCenterWhereInput>;
  none?: InputMaybe<GameCenterWhereInput>;
  some?: InputMaybe<GameCenterWhereInput>;
};

export type GameCenterMaxAggregate = {
  __typename?: 'GameCenterMaxAggregate';
  area?: Maybe<GameCenterArea>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  placementAllowed?: Maybe<Scalars['Boolean']>;
  size?: Maybe<GameCenterSize>;
  state?: Maybe<NftState>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  xCoordinate?: Maybe<Scalars['Int']>;
  yCoordinate?: Maybe<Scalars['Int']>;
};

export type GameCenterMaxOrderByAggregateInput = {
  area?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  placementAllowed?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  xCoordinate?: InputMaybe<SortOrder>;
  yCoordinate?: InputMaybe<SortOrder>;
};

export type GameCenterMinAggregate = {
  __typename?: 'GameCenterMinAggregate';
  area?: Maybe<GameCenterArea>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  ownerWalletAddress?: Maybe<Scalars['String']>;
  physicalWalletAddress?: Maybe<Scalars['String']>;
  placementAllowed?: Maybe<Scalars['Boolean']>;
  size?: Maybe<GameCenterSize>;
  state?: Maybe<NftState>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  xCoordinate?: Maybe<Scalars['Int']>;
  yCoordinate?: Maybe<Scalars['Int']>;
};

export type GameCenterMinOrderByAggregateInput = {
  area?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrder>;
  placementAllowed?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  xCoordinate?: InputMaybe<SortOrder>;
  yCoordinate?: InputMaybe<SortOrder>;
};

export type GameCenterNullableRelationFilter = {
  is?: InputMaybe<GameCenterWhereInput>;
  isNot?: InputMaybe<GameCenterWhereInput>;
};

export type GameCenterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GameCenterOrderByWithAggregationInput = {
  _avg?: InputMaybe<GameCenterAvgOrderByAggregateInput>;
  _count?: InputMaybe<GameCenterCountOrderByAggregateInput>;
  _max?: InputMaybe<GameCenterMaxOrderByAggregateInput>;
  _min?: InputMaybe<GameCenterMinOrderByAggregateInput>;
  _sum?: InputMaybe<GameCenterSumOrderByAggregateInput>;
  area?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrderInput>;
  placementAllowed?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
  xCoordinate?: InputMaybe<SortOrder>;
  yCoordinate?: InputMaybe<SortOrder>;
};

export type GameCenterOrderByWithRelationInput = {
  arcadeMachines?: InputMaybe<ArcadeMachineOrderByRelationAggregateInput>;
  area?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerWalletAddress?: InputMaybe<SortOrderInput>;
  placementAllowed?: InputMaybe<SortOrder>;
  playSessions?: InputMaybe<PlaySessionOrderByRelationAggregateInput>;
  size?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
  xCoordinate?: InputMaybe<SortOrder>;
  yCoordinate?: InputMaybe<SortOrder>;
};

export enum GameCenterScalarFieldEnum {
  Area = 'area',
  CreatedAt = 'createdAt',
  Id = 'id',
  LastBlock = 'lastBlock',
  LastTransactionIndex = 'lastTransactionIndex',
  Name = 'name',
  OwnerWalletAddress = 'ownerWalletAddress',
  PhysicalWalletAddress = 'physicalWalletAddress',
  PlacementAllowed = 'placementAllowed',
  Size = 'size',
  State = 'state',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  XCoordinate = 'xCoordinate',
  YCoordinate = 'yCoordinate'
}

export type GameCenterScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<GameCenterScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<GameCenterScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<GameCenterScalarWhereWithAggregatesInput>>;
  area?: InputMaybe<EnumGameCenterAreaWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  lastBlock?: InputMaybe<IntWithAggregatesFilter>;
  lastTransactionIndex?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableWithAggregatesFilter>;
  placementAllowed?: InputMaybe<BoolWithAggregatesFilter>;
  size?: InputMaybe<EnumGameCenterSizeWithAggregatesFilter>;
  state?: InputMaybe<EnumNftStateWithAggregatesFilter>;
  userId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  xCoordinate?: InputMaybe<IntWithAggregatesFilter>;
  yCoordinate?: InputMaybe<IntWithAggregatesFilter>;
};

export enum GameCenterSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type GameCenterSumAggregate = {
  __typename?: 'GameCenterSumAggregate';
  lastBlock?: Maybe<Scalars['Int']>;
  lastTransactionIndex?: Maybe<Scalars['Int']>;
  xCoordinate?: Maybe<Scalars['Int']>;
  yCoordinate?: Maybe<Scalars['Int']>;
};

export type GameCenterSumOrderByAggregateInput = {
  lastBlock?: InputMaybe<SortOrder>;
  lastTransactionIndex?: InputMaybe<SortOrder>;
  xCoordinate?: InputMaybe<SortOrder>;
  yCoordinate?: InputMaybe<SortOrder>;
};

export type GameCenterWhereInput = {
  AND?: InputMaybe<Array<GameCenterWhereInput>>;
  NOT?: InputMaybe<Array<GameCenterWhereInput>>;
  OR?: InputMaybe<Array<GameCenterWhereInput>>;
  arcadeMachines?: InputMaybe<ArcadeMachineListRelationFilter>;
  area?: InputMaybe<EnumGameCenterAreaFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lastBlock?: InputMaybe<IntFilter>;
  lastTransactionIndex?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableFilter>;
  placementAllowed?: InputMaybe<BoolFilter>;
  playSessions?: InputMaybe<PlaySessionListRelationFilter>;
  size?: InputMaybe<EnumGameCenterSizeFilter>;
  state?: InputMaybe<EnumNftStateFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
  xCoordinate?: InputMaybe<IntFilter>;
  yCoordinate?: InputMaybe<IntFilter>;
};

export type GameCenterWhereUniqueInput = {
  AND?: InputMaybe<Array<GameCenterWhereInput>>;
  NOT?: InputMaybe<Array<GameCenterWhereInput>>;
  OR?: InputMaybe<Array<GameCenterWhereInput>>;
  arcadeMachines?: InputMaybe<ArcadeMachineListRelationFilter>;
  area?: InputMaybe<EnumGameCenterAreaFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  lastBlock?: InputMaybe<IntFilter>;
  lastTransactionIndex?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  ownerWalletAddress?: InputMaybe<StringNullableFilter>;
  placementAllowed?: InputMaybe<BoolFilter>;
  playSessions?: InputMaybe<PlaySessionListRelationFilter>;
  size?: InputMaybe<EnumGameCenterSizeFilter>;
  state?: InputMaybe<EnumNftStateFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
  xCoordinate?: InputMaybe<IntFilter>;
  yCoordinate?: InputMaybe<IntFilter>;
};

export type Games = {
  __typename?: 'Games';
  games: Array<Game>;
  version: Scalars['String'];
};

export type GoogleOneTimePurchaseWhereInput = {
  AND?: InputMaybe<Array<GoogleOneTimePurchaseWhereInput>>;
  NOT?: InputMaybe<Array<GoogleOneTimePurchaseWhereInput>>;
  OR?: InputMaybe<Array<GoogleOneTimePurchaseWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  errorDetail?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  productId?: InputMaybe<StringFilter>;
  purchaseDetail?: InputMaybe<StringNullableFilter>;
  purchaseToken?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPurchaseStatusFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type GooglePurchaseInput = {
  productId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type Help = {
  __typename?: 'Help';
  description: Scalars['String'];
  howTo: Scalars['String'];
};

export enum IconType {
  InWorld = 'IN_WORLD',
  Nft = 'NFT'
}

export type InProgressInput = {
  playSessionToken?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
  signature?: InputMaybe<Scalars['String']>;
  timeStamp?: InputMaybe<Scalars['DateTime']>;
};

export type InProgressOutput = {
  __typename?: 'InProgressOutput';
  success: Scalars['Boolean'];
};

export type InstallArcadeMachineInput = {
  arcadeMachineId: Scalars['String'];
  autoRenewLease: Scalars['Boolean'];
  gameCenterId: Scalars['String'];
};

export type InstallArcadeMachineOutput = {
  __typename?: 'InstallArcadeMachineOutput';
  arcadeMachine: ArcadeMachine;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InterstitialBanner = {
  __typename?: 'InterstitialBanner';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  display: Scalars['Boolean'];
  endAt?: Maybe<Scalars['DateTime']>;
  externalLink?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  remainingTimeVisible: Scalars['Boolean'];
  startAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type InterstitialBannerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  display?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrderInput>;
  externalLink?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  remainingTimeVisible?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrderInput>;
};

export enum InterstitialBannerScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Display = 'display',
  EndAt = 'endAt',
  ExternalLink = 'externalLink',
  Id = 'id',
  ImageUrl = 'imageUrl',
  RemainingTimeVisible = 'remainingTimeVisible',
  StartAt = 'startAt',
  TargetArea = 'targetArea',
  UpdatedAt = 'updatedAt'
}

export type InterstitialBannerWhereInput = {
  AND?: InputMaybe<Array<InterstitialBannerWhereInput>>;
  NOT?: InputMaybe<Array<InterstitialBannerWhereInput>>;
  OR?: InputMaybe<Array<InterstitialBannerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  display?: InputMaybe<BoolFilter>;
  endAt?: InputMaybe<DateTimeNullableFilter>;
  externalLink?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  remainingTimeVisible?: InputMaybe<BoolFilter>;
  startAt?: InputMaybe<DateTimeNullableFilter>;
};

export type InterstitialBannerWhereUniqueInput = {
  AND?: InputMaybe<Array<InterstitialBannerWhereInput>>;
  NOT?: InputMaybe<Array<InterstitialBannerWhereInput>>;
  OR?: InputMaybe<Array<InterstitialBannerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  display?: InputMaybe<BoolFilter>;
  endAt?: InputMaybe<DateTimeNullableFilter>;
  externalLink?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<StringFilter>;
  remainingTimeVisible?: InputMaybe<BoolFilter>;
  startAt?: InputMaybe<DateTimeNullableFilter>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type Junk = {
  __typename?: 'Junk';
  amount: Scalars['Int'];
  category: ArcadePartCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  metadata: JunkMetadata;
  subCategory: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

export type JunkAvgAggregate = {
  __typename?: 'JunkAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
};

export type JunkAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type JunkCountAggregate = {
  __typename?: 'JunkCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  category: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  subCategory: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type JunkCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type JunkGroupBy = {
  __typename?: 'JunkGroupBy';
  _avg?: Maybe<JunkAvgAggregate>;
  _count?: Maybe<JunkCountAggregate>;
  _max?: Maybe<JunkMaxAggregate>;
  _min?: Maybe<JunkMinAggregate>;
  _sum?: Maybe<JunkSumAggregate>;
  amount: Scalars['Int'];
  category: ArcadePartCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  subCategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type JunkListRelationFilter = {
  every?: InputMaybe<JunkWhereInput>;
  none?: InputMaybe<JunkWhereInput>;
  some?: InputMaybe<JunkWhereInput>;
};

export type JunkMaxAggregate = {
  __typename?: 'JunkMaxAggregate';
  amount?: Maybe<Scalars['Int']>;
  category?: Maybe<ArcadePartCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type JunkMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type JunkMetadata = {
  __typename?: 'JunkMetadata';
  arcadePartMetadata: Metadata;
  image: Scalars['String'];
  junksPerPart: Scalars['Int'];
  name: Scalars['String'];
  rarity: Scalars['Int'];
};

export type JunkMinAggregate = {
  __typename?: 'JunkMinAggregate';
  amount?: Maybe<Scalars['Int']>;
  category?: Maybe<ArcadePartCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type JunkMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type JunkOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type JunkOrderByWithAggregationInput = {
  _avg?: InputMaybe<JunkAvgOrderByAggregateInput>;
  _count?: InputMaybe<JunkCountOrderByAggregateInput>;
  _max?: InputMaybe<JunkMaxOrderByAggregateInput>;
  _min?: InputMaybe<JunkMinOrderByAggregateInput>;
  _sum?: InputMaybe<JunkSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type JunkOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum JunkScalarFieldEnum {
  Amount = 'amount',
  Category = 'category',
  CreatedAt = 'createdAt',
  Id = 'id',
  SubCategory = 'subCategory',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type JunkScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<JunkScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<JunkScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<JunkScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<IntWithAggregatesFilter>;
  category?: InputMaybe<EnumArcadePartCategoryWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  subCategory?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type JunkSumAggregate = {
  __typename?: 'JunkSumAggregate';
  amount?: Maybe<Scalars['Int']>;
};

export type JunkSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
};

export type JunkUserIdCategorySubCategoryCompoundUniqueInput = {
  category: ArcadePartCategory;
  subCategory: Scalars['String'];
  userId: Scalars['String'];
};

export type JunkWhereInput = {
  AND?: InputMaybe<Array<JunkWhereInput>>;
  NOT?: InputMaybe<Array<JunkWhereInput>>;
  OR?: InputMaybe<Array<JunkWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  category?: InputMaybe<EnumArcadePartCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  subCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type JunkWhereUniqueInput = {
  AND?: InputMaybe<Array<JunkWhereInput>>;
  NOT?: InputMaybe<Array<JunkWhereInput>>;
  OR?: InputMaybe<Array<JunkWhereInput>>;
  amount?: InputMaybe<IntFilter>;
  category?: InputMaybe<EnumArcadePartCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  subCategory?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  userId_category_subCategory?: InputMaybe<JunkUserIdCategorySubCategoryCompoundUniqueInput>;
};

export type ListBoxItemsOutput = {
  __typename?: 'ListBoxItemsOutput';
  hotItems: Array<BoxItem>;
  otherItems: Array<BoxItem>;
  totalAmount: Scalars['Float'];
};

export type ListInAppPurchaseItemsOutput = {
  __typename?: 'ListInAppPurchaseItemsOutput';
  bonus?: Maybe<Scalars['Float']>;
  imageUrl: Scalars['String'];
  isSale: Scalars['Boolean'];
  offerText?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  saleTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ListNfTsOutput = {
  __typename?: 'ListNFTsOutput';
  name: Scalars['String'];
  tokenId: Scalars['String'];
};

export type ListPaidTournamentRankingsOutput = {
  __typename?: 'ListPaidTournamentRankingsOutput';
  myself?: Maybe<RankingItem>;
  ownedPrize?: Maybe<OwnedPrize>;
  topList: Array<RankingItem>;
};

export type ListRandomArcadeMachinesOutput = {
  __typename?: 'ListRandomArcadeMachinesOutput';
  arcadeMachines: Array<ArcadeMachine>;
};

export type ListRankingsOutput = {
  __typename?: 'ListRankingsOutput';
  myself?: Maybe<RankingItem>;
  topList: Array<RankingItem>;
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  /** @deprecated firebase実装後削除 */
  accessToken: Scalars['String'];
  firebaseCustomToken: Scalars['String'];
  /** @deprecated firebase実装後削除 */
  refreshToken: Scalars['String'];
  user: User;
};

export type MagicSessionWhereInput = {
  AND?: InputMaybe<Array<MagicSessionWhereInput>>;
  NOT?: InputMaybe<Array<MagicSessionWhereInput>>;
  OR?: InputMaybe<Array<MagicSessionWhereInput>>;
  issuer?: InputMaybe<StringFilter>;
  lastLoginAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
};

export type Metadata = {
  __typename?: 'Metadata';
  animationUrl: Scalars['String'];
  attributes: Array<MetadataAttribute>;
  description: Scalars['String'];
  externalUrl: Scalars['String'];
  image: Scalars['String'];
  name: Scalars['String'];
  rarity: Scalars['Int'];
  transparentImageUrl?: Maybe<Scalars['String']>;
  withoutAccImageUrl?: Maybe<Scalars['String']>;
};

export type MetadataAttribute = {
  __typename?: 'MetadataAttribute';
  displayType: Scalars['String'];
  traitType: Scalars['String'];
  value: Scalars['String'];
};

export type MinimumAppVersion = {
  __typename?: 'MinimumAppVersion';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  minimumVersion: Scalars['String'];
  os: OperatingSystem;
  updatedAt: Scalars['DateTime'];
};

export type MinimumAppVersionWhereInput = {
  AND?: InputMaybe<Array<MinimumAppVersionWhereInput>>;
  NOT?: InputMaybe<Array<MinimumAppVersionWhereInput>>;
  OR?: InputMaybe<Array<MinimumAppVersionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  minimumVersion?: InputMaybe<StringFilter>;
  os?: InputMaybe<EnumOperatingSystemFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MinimumAppVersionWhereUniqueInput = {
  AND?: InputMaybe<Array<MinimumAppVersionWhereInput>>;
  NOT?: InputMaybe<Array<MinimumAppVersionWhereInput>>;
  OR?: InputMaybe<Array<MinimumAppVersionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  minimumVersion?: InputMaybe<StringFilter>;
  os?: InputMaybe<OperatingSystem>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  RequestWeb3Challenge: RequestWeb3ChallengeOutput;
  acceptRewardAll: AcceptRewardsOutput;
  applyBoosterItem: ActiveBoosterUnionOutput;
  claimPrize: Scalars['Boolean'];
  craft: ArcadeMachine;
  createUser: CreateUserOutput;
  depositAKV: Scalars['Boolean'];
  depositArcadeMachine: Array<ArcadeMachine>;
  depositArcadePart: Array<ArcadePart>;
  depositGameCenter: Array<GameCenter>;
  dismantle: DismantleOutput;
  enterPaidTournament: Scalars['Boolean'];
  executeExtract: Array<ExecuteExtractOutput>;
  finishPlay: FinishPlayOutput;
  finishPlaySession: FinishPlaySessionOutput;
  finishQuestChain: FinishQuestChainOutput;
  inProgress: InProgressOutput;
  installArcadeMachine: InstallArcadeMachineOutput;
  purchaseOneTime: Scalars['Boolean'];
  registerWalletAddress: RegisterWalletAddressOutput;
  roviGameFinish: RoviGameFinishOutput;
  roviGameStart: RobiGameStartOutput;
  startPlay: StartPlayOutput;
  startPlaySession: StartPlaySessionOutput;
  startQuestChain: QuestChain;
  startRecruitArcadeMachine: GameCenter;
  stopRecruitArcadeMachine: GameCenter;
  swapJunkToArcadePart: SwapJunkToArcadePartsOutput;
  tempReviewToken: LoginOutput;
  uninstallArcadeMachine: UninstallArcadeMachineOutput;
  updateArcadeMachine: ArcadeMachine;
  updateUser: User;
  withdrawAKV: Scalars['Boolean'];
  withdrawArcadeMachine: Array<ArcadeMachine>;
  withdrawArcadePart: Array<ArcadePart>;
  withdrawGameCenter: Array<GameCenter>;
};


export type MutationRequestWeb3ChallengeArgs = {
  input: RequestWeb3ChallengeInput;
};


export type MutationApplyBoosterItemArgs = {
  input: ApplyBoosterItemInput;
};


export type MutationClaimPrizeArgs = {
  input: ClaimPrizeInput;
};


export type MutationCraftArgs = {
  input: CraftInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDepositAkvArgs = {
  input: DepositAkvInput;
};


export type MutationDepositArcadeMachineArgs = {
  input: DepositArcadeMachineInput;
};


export type MutationDepositArcadePartArgs = {
  input: DepositArcadePartInput;
};


export type MutationDepositGameCenterArgs = {
  input: DepositGameCenterInput;
};


export type MutationDismantleArgs = {
  input: DismantleInput;
};


export type MutationEnterPaidTournamentArgs = {
  input: EnterTournamentInput;
};


export type MutationExecuteExtractArgs = {
  input: ExecuteExtractInput;
};


export type MutationFinishPlayArgs = {
  input: FinishPlayInput;
};


export type MutationFinishPlaySessionArgs = {
  input: FinishPlaySessionInput;
};


export type MutationFinishQuestChainArgs = {
  input: FinishQuestChainInput;
};


export type MutationInProgressArgs = {
  input: InProgressInput;
};


export type MutationInstallArcadeMachineArgs = {
  input: InstallArcadeMachineInput;
};


export type MutationPurchaseOneTimeArgs = {
  input: OneTimePurchaseInput;
};


export type MutationRegisterWalletAddressArgs = {
  input: RegisterWalletAddressInput;
};


export type MutationRoviGameFinishArgs = {
  input: RoviGameFinishInput;
};


export type MutationRoviGameStartArgs = {
  input: RoviGameStartInput;
};


export type MutationStartPlayArgs = {
  input: StartPlayInput;
};


export type MutationStartPlaySessionArgs = {
  input: StartPlaySessionInput;
};


export type MutationStartQuestChainArgs = {
  input: StartQuestChainInput;
};


export type MutationStartRecruitArcadeMachineArgs = {
  input: StartRecruitArcadeMachineInput;
};


export type MutationStopRecruitArcadeMachineArgs = {
  input: StopRecruitArcadeMachineInput;
};


export type MutationSwapJunkToArcadePartArgs = {
  input: SwapJunkToArcadePartsInput;
};


export type MutationTempReviewTokenArgs = {
  input: TempReviewTokenInput;
};


export type MutationUninstallArcadeMachineArgs = {
  input: UninstallArcadeMachineInput;
};


export type MutationUpdateArcadeMachineArgs = {
  input: UpdateArcadeMachineInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationWithdrawAkvArgs = {
  input: WithdrawAkvInput;
};


export type MutationWithdrawArcadeMachineArgs = {
  input: WithdrawArcadeMachineInput;
};


export type MutationWithdrawArcadePartArgs = {
  input: WithdrawArcadePartInput;
};


export type MutationWithdrawGameCenterArgs = {
  input: WithdrawGameCenterInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumArcadePartCategoryFilter = {
  equals?: InputMaybe<ArcadePartCategory>;
  in?: InputMaybe<Array<ArcadePartCategory>>;
  not?: InputMaybe<NestedEnumArcadePartCategoryFilter>;
  notIn?: InputMaybe<Array<ArcadePartCategory>>;
};

export type NestedEnumArcadePartCategoryWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumArcadePartCategoryFilter>;
  _min?: InputMaybe<NestedEnumArcadePartCategoryFilter>;
  equals?: InputMaybe<ArcadePartCategory>;
  in?: InputMaybe<Array<ArcadePartCategory>>;
  not?: InputMaybe<NestedEnumArcadePartCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ArcadePartCategory>>;
};

export type NestedEnumBoosterCategoryFilter = {
  equals?: InputMaybe<BoosterCategory>;
  in?: InputMaybe<Array<BoosterCategory>>;
  not?: InputMaybe<NestedEnumBoosterCategoryFilter>;
  notIn?: InputMaybe<Array<BoosterCategory>>;
};

export type NestedEnumBurnStateFilter = {
  equals?: InputMaybe<BurnState>;
  in?: InputMaybe<Array<BurnState>>;
  not?: InputMaybe<NestedEnumBurnStateFilter>;
  notIn?: InputMaybe<Array<BurnState>>;
};

export type NestedEnumCollectibleItemCategoryFilter = {
  equals?: InputMaybe<CollectibleItemCategory>;
  in?: InputMaybe<Array<CollectibleItemCategory>>;
  not?: InputMaybe<NestedEnumCollectibleItemCategoryFilter>;
  notIn?: InputMaybe<Array<CollectibleItemCategory>>;
};

export type NestedEnumCurrencyTypeFilter = {
  equals?: InputMaybe<CurrencyType>;
  in?: InputMaybe<Array<CurrencyType>>;
  not?: InputMaybe<NestedEnumCurrencyTypeFilter>;
  notIn?: InputMaybe<Array<CurrencyType>>;
};

export type NestedEnumDepositStateFilter = {
  equals?: InputMaybe<DepositState>;
  in?: InputMaybe<Array<DepositState>>;
  not?: InputMaybe<NestedEnumDepositStateFilter>;
  notIn?: InputMaybe<Array<DepositState>>;
};

export type NestedEnumExtractableItemTypeFilter = {
  equals?: InputMaybe<ExtractableItemType>;
  in?: InputMaybe<Array<ExtractableItemType>>;
  not?: InputMaybe<NestedEnumExtractableItemTypeFilter>;
  notIn?: InputMaybe<Array<ExtractableItemType>>;
};

export type NestedEnumExtractableItemTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumExtractableItemTypeFilter>;
  _min?: InputMaybe<NestedEnumExtractableItemTypeFilter>;
  equals?: InputMaybe<ExtractableItemType>;
  in?: InputMaybe<Array<ExtractableItemType>>;
  not?: InputMaybe<NestedEnumExtractableItemTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ExtractableItemType>>;
};

export type NestedEnumFrontEndTypeFilter = {
  equals?: InputMaybe<FrontEndType>;
  in?: InputMaybe<Array<FrontEndType>>;
  not?: InputMaybe<NestedEnumFrontEndTypeFilter>;
  notIn?: InputMaybe<Array<FrontEndType>>;
};

export type NestedEnumGameCenterAreaFilter = {
  equals?: InputMaybe<GameCenterArea>;
  in?: InputMaybe<Array<GameCenterArea>>;
  not?: InputMaybe<NestedEnumGameCenterAreaFilter>;
  notIn?: InputMaybe<Array<GameCenterArea>>;
};

export type NestedEnumGameCenterAreaWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumGameCenterAreaFilter>;
  _min?: InputMaybe<NestedEnumGameCenterAreaFilter>;
  equals?: InputMaybe<GameCenterArea>;
  in?: InputMaybe<Array<GameCenterArea>>;
  not?: InputMaybe<NestedEnumGameCenterAreaWithAggregatesFilter>;
  notIn?: InputMaybe<Array<GameCenterArea>>;
};

export type NestedEnumGameCenterSizeFilter = {
  equals?: InputMaybe<GameCenterSize>;
  in?: InputMaybe<Array<GameCenterSize>>;
  not?: InputMaybe<NestedEnumGameCenterSizeFilter>;
  notIn?: InputMaybe<Array<GameCenterSize>>;
};

export type NestedEnumGameCenterSizeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumGameCenterSizeFilter>;
  _min?: InputMaybe<NestedEnumGameCenterSizeFilter>;
  equals?: InputMaybe<GameCenterSize>;
  in?: InputMaybe<Array<GameCenterSize>>;
  not?: InputMaybe<NestedEnumGameCenterSizeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<GameCenterSize>>;
};

export type NestedEnumIconTypeFilter = {
  equals?: InputMaybe<IconType>;
  in?: InputMaybe<Array<IconType>>;
  not?: InputMaybe<NestedEnumIconTypeFilter>;
  notIn?: InputMaybe<Array<IconType>>;
};

export type NestedEnumIconTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumIconTypeFilter>;
  _min?: InputMaybe<NestedEnumIconTypeFilter>;
  equals?: InputMaybe<IconType>;
  in?: InputMaybe<Array<IconType>>;
  not?: InputMaybe<NestedEnumIconTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<IconType>>;
};

export type NestedEnumNewsCategoryFilter = {
  equals?: InputMaybe<NewsCategory>;
  in?: InputMaybe<Array<NewsCategory>>;
  not?: InputMaybe<NestedEnumNewsCategoryFilter>;
  notIn?: InputMaybe<Array<NewsCategory>>;
};

export type NestedEnumNftStateFilter = {
  equals?: InputMaybe<NftState>;
  in?: InputMaybe<Array<NftState>>;
  not?: InputMaybe<NestedEnumNftStateFilter>;
  notIn?: InputMaybe<Array<NftState>>;
};

export type NestedEnumNftStateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumNftStateFilter>;
  _min?: InputMaybe<NestedEnumNftStateFilter>;
  equals?: InputMaybe<NftState>;
  in?: InputMaybe<Array<NftState>>;
  not?: InputMaybe<NestedEnumNftStateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<NftState>>;
};

export type NestedEnumNftTypeFilter = {
  equals?: InputMaybe<NftType>;
  in?: InputMaybe<Array<NftType>>;
  not?: InputMaybe<NestedEnumNftTypeFilter>;
  notIn?: InputMaybe<Array<NftType>>;
};

export type NestedEnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumOperatingSystemFilter = {
  equals?: InputMaybe<OperatingSystem>;
  in?: InputMaybe<Array<OperatingSystem>>;
  not?: InputMaybe<NestedEnumOperatingSystemFilter>;
  notIn?: InputMaybe<Array<OperatingSystem>>;
};

export type NestedEnumPlayResultNullableFilter = {
  equals?: InputMaybe<PlayResult>;
  in?: InputMaybe<Array<PlayResult>>;
  not?: InputMaybe<NestedEnumPlayResultNullableFilter>;
  notIn?: InputMaybe<Array<PlayResult>>;
};

export type NestedEnumPlayResultNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumPlayResultNullableFilter>;
  _min?: InputMaybe<NestedEnumPlayResultNullableFilter>;
  equals?: InputMaybe<PlayResult>;
  in?: InputMaybe<Array<PlayResult>>;
  not?: InputMaybe<NestedEnumPlayResultNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<PlayResult>>;
};

export type NestedEnumPlaySessionStateFilter = {
  equals?: InputMaybe<PlaySessionState>;
  in?: InputMaybe<Array<PlaySessionState>>;
  not?: InputMaybe<NestedEnumPlaySessionStateFilter>;
  notIn?: InputMaybe<Array<PlaySessionState>>;
};

export type NestedEnumPrizeSendStatusNullableFilter = {
  equals?: InputMaybe<PrizeSendStatus>;
  in?: InputMaybe<Array<PrizeSendStatus>>;
  not?: InputMaybe<NestedEnumPrizeSendStatusNullableFilter>;
  notIn?: InputMaybe<Array<PrizeSendStatus>>;
};

export type NestedEnumPurchaseStatusFilter = {
  equals?: InputMaybe<PurchaseStatus>;
  in?: InputMaybe<Array<PurchaseStatus>>;
  not?: InputMaybe<NestedEnumPurchaseStatusFilter>;
  notIn?: InputMaybe<Array<PurchaseStatus>>;
};

export type NestedEnumRewardCategoryFilter = {
  equals?: InputMaybe<RewardCategory>;
  in?: InputMaybe<Array<RewardCategory>>;
  not?: InputMaybe<NestedEnumRewardCategoryFilter>;
  notIn?: InputMaybe<Array<RewardCategory>>;
};

export type NestedEnumRewardItemTypeFilter = {
  equals?: InputMaybe<RewardItemType>;
  in?: InputMaybe<Array<RewardItemType>>;
  not?: InputMaybe<NestedEnumRewardItemTypeFilter>;
  notIn?: InputMaybe<Array<RewardItemType>>;
};

export type NestedEnumTicketTransactionTypeFilter = {
  equals?: InputMaybe<TicketTransactionType>;
  in?: InputMaybe<Array<TicketTransactionType>>;
  not?: InputMaybe<NestedEnumTicketTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TicketTransactionType>>;
};

export type NestedEnumWithdrawalStateFilter = {
  equals?: InputMaybe<WithdrawalState>;
  in?: InputMaybe<Array<WithdrawalState>>;
  not?: InputMaybe<NestedEnumWithdrawalStateFilter>;
  notIn?: InputMaybe<Array<WithdrawalState>>;
};

export type NestedEnumWithdrawalTypeNullableFilter = {
  equals?: InputMaybe<WithdrawalType>;
  in?: InputMaybe<Array<WithdrawalType>>;
  not?: InputMaybe<NestedEnumWithdrawalTypeNullableFilter>;
  notIn?: InputMaybe<Array<WithdrawalType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedJsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type NestedUuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type NestedUuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type NestedUuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type News = {
  __typename?: 'News';
  category: NewsCategory;
  createdAt: Scalars['DateTime'];
  display: Scalars['Boolean'];
  endAt?: Maybe<Scalars['DateTime']>;
  externalLink?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  startAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
};

export enum NewsCategory {
  Event = 'EVENT',
  Info = 'INFO',
  Promo = 'PROMO'
}

export type NewsOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  display?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrderInput>;
  externalLink?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
};

export enum NewsScalarFieldEnum {
  Category = 'category',
  CreatedAt = 'createdAt',
  Display = 'display',
  EndAt = 'endAt',
  ExternalLink = 'externalLink',
  Id = 'id',
  StartAt = 'startAt',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type NewsWhereInput = {
  AND?: InputMaybe<Array<NewsWhereInput>>;
  NOT?: InputMaybe<Array<NewsWhereInput>>;
  OR?: InputMaybe<Array<NewsWhereInput>>;
  category?: InputMaybe<EnumNewsCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  display?: InputMaybe<BoolFilter>;
  endAt?: InputMaybe<DateTimeNullableFilter>;
  externalLink?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  startAt?: InputMaybe<DateTimeNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

export type NewsWhereUniqueInput = {
  AND?: InputMaybe<Array<NewsWhereInput>>;
  NOT?: InputMaybe<Array<NewsWhereInput>>;
  OR?: InputMaybe<Array<NewsWhereInput>>;
  category?: InputMaybe<EnumNewsCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  display?: InputMaybe<BoolFilter>;
  endAt?: InputMaybe<DateTimeNullableFilter>;
  externalLink?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['Int']>;
  startAt?: InputMaybe<DateTimeNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum NftState {
  Burned = 'BURNED',
  Burning = 'BURNING',
  InAkiverse = 'IN_AKIVERSE',
  InWallet = 'IN_WALLET',
  MovingToAkiverse = 'MOVING_TO_AKIVERSE',
  MovingToWallet = 'MOVING_TO_WALLET'
}

export enum NftType {
  ArcadeMachine = 'ARCADE_MACHINE',
  ArcadePart = 'ARCADE_PART',
  GameCenter = 'GAME_CENTER'
}

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  messageDetailJson?: Maybe<Scalars['JSON']>;
  messageJson: Scalars['JSON'];
  nftType: NftType;
  notificationType: NotificationType;
  tokenId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type NotificationCountOutput = {
  __typename?: 'NotificationCountOutput';
  count: Scalars['Float'];
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  nftType?: InputMaybe<SortOrder>;
  notificationType?: InputMaybe<SortOrder>;
  tokenId?: InputMaybe<SortOrderInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum NotificationScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MessageDetailJson = 'messageDetailJson',
  MessageJson = 'messageJson',
  NftType = 'nftType',
  NotificationType = 'notificationType',
  TokenId = 'tokenId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export enum NotificationType {
  Activity = 'ACTIVITY',
  Information = 'INFORMATION'
}

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  nftType?: InputMaybe<EnumNftTypeFilter>;
  notificationType?: InputMaybe<EnumNotificationTypeFilter>;
  tokenId?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type NotificationWhereUniqueInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  nftType?: InputMaybe<EnumNftTypeFilter>;
  notificationType?: InputMaybe<EnumNotificationTypeFilter>;
  tokenId?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type OneTimePurchaseInput = {
  google?: InputMaybe<GooglePurchaseInput>;
};

export enum OperatingSystem {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type OwnedPrize = {
  __typename?: 'OwnedPrize';
  claimType: PrizeClaimType;
  crypt?: Maybe<Scalars['Float']>;
  localCurrency?: Maybe<Scalars['Float']>;
  teras: Scalars['Decimal'];
};

export type PaidTournament = {
  __typename?: 'PaidTournament';
  _count?: Maybe<PaidTournamentCount>;
  activeBoosterForTournaments: Array<ActiveBoosterForTournament>;
  badge: Scalars['Boolean'];
  claimStatus: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  entries: Array<PaidTournamentEntry>;
  entryFeeTickets: Scalars['Int'];
  gameId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  paidTournamentBoosterAvailable: Array<PaidTournamentBoosterAvailable>;
  prizeInfo: PrizeInfoOutput;
  prizeTerasOnly: Scalars['Boolean'];
  resultRecorded: Scalars['Boolean'];
  startAt: Scalars['DateTime'];
  title: Scalars['String'];
};


export type PaidTournamentActiveBoosterForTournamentsArgs = {
  cursor?: InputMaybe<ActiveBoosterForTournamentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActiveBoosterForTournamentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActiveBoosterForTournamentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActiveBoosterForTournamentWhereInput>;
};


export type PaidTournamentEntriesArgs = {
  cursor?: InputMaybe<PaidTournamentEntryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaidTournamentEntryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaidTournamentEntryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaidTournamentEntryWhereInput>;
};


export type PaidTournamentPaidTournamentBoosterAvailableArgs = {
  cursor?: InputMaybe<PaidTournamentBoosterAvailableWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaidTournamentBoosterAvailableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaidTournamentBoosterAvailableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaidTournamentBoosterAvailableWhereInput>;
};

export type PaidTournamentBoosterAvailable = {
  __typename?: 'PaidTournamentBoosterAvailable';
  boosterMaster: BoosterMaster;
  boosterMasterId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  paidTournament: PaidTournament;
  paidTournamentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PaidTournamentBoosterAvailableListRelationFilter = {
  every?: InputMaybe<PaidTournamentBoosterAvailableWhereInput>;
  none?: InputMaybe<PaidTournamentBoosterAvailableWhereInput>;
  some?: InputMaybe<PaidTournamentBoosterAvailableWhereInput>;
};

export type PaidTournamentBoosterAvailableOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaidTournamentBoosterAvailableOrderByWithRelationInput = {
  boosterMaster?: InputMaybe<BoosterMasterOrderByWithRelationInput>;
  boosterMasterId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paidTournament?: InputMaybe<PaidTournamentOrderByWithRelationInput>;
  paidTournamentId?: InputMaybe<SortOrder>;
};

export type PaidTournamentBoosterAvailablePaidTournamentIdBoosterMasterIdCompoundUniqueInput = {
  boosterMasterId: Scalars['String'];
  paidTournamentId: Scalars['String'];
};

export enum PaidTournamentBoosterAvailableScalarFieldEnum {
  BoosterMasterId = 'boosterMasterId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PaidTournamentId = 'paidTournamentId',
  UpdatedAt = 'updatedAt'
}

export type PaidTournamentBoosterAvailableWhereInput = {
  AND?: InputMaybe<Array<PaidTournamentBoosterAvailableWhereInput>>;
  NOT?: InputMaybe<Array<PaidTournamentBoosterAvailableWhereInput>>;
  OR?: InputMaybe<Array<PaidTournamentBoosterAvailableWhereInput>>;
  boosterMaster?: InputMaybe<BoosterMasterRelationFilter>;
  boosterMasterId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  paidTournament?: InputMaybe<PaidTournamentRelationFilter>;
  paidTournamentId?: InputMaybe<UuidFilter>;
};

export type PaidTournamentBoosterAvailableWhereUniqueInput = {
  AND?: InputMaybe<Array<PaidTournamentBoosterAvailableWhereInput>>;
  NOT?: InputMaybe<Array<PaidTournamentBoosterAvailableWhereInput>>;
  OR?: InputMaybe<Array<PaidTournamentBoosterAvailableWhereInput>>;
  boosterMaster?: InputMaybe<BoosterMasterRelationFilter>;
  boosterMasterId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  paidTournament?: InputMaybe<PaidTournamentRelationFilter>;
  paidTournamentId?: InputMaybe<UuidFilter>;
  paidTournamentId_boosterMasterId?: InputMaybe<PaidTournamentBoosterAvailablePaidTournamentIdBoosterMasterIdCompoundUniqueInput>;
};

export type PaidTournamentCount = {
  __typename?: 'PaidTournamentCount';
  activeBoosterForTournaments: Scalars['Int'];
  entries: Scalars['Int'];
  paidTournamentBoosterAvailable: Scalars['Int'];
};


export type PaidTournamentCountActiveBoosterForTournamentsArgs = {
  where?: InputMaybe<ActiveBoosterForTournamentWhereInput>;
};


export type PaidTournamentCountEntriesArgs = {
  where?: InputMaybe<PaidTournamentEntryWhereInput>;
};


export type PaidTournamentCountPaidTournamentBoosterAvailableArgs = {
  where?: InputMaybe<PaidTournamentBoosterAvailableWhereInput>;
};

export type PaidTournamentEntry = {
  __typename?: 'PaidTournamentEntry';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  paidTournamentId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  prizeClaimed: Scalars['Boolean'];
  prizeSendStatus?: Maybe<PrizeSendStatus>;
  usedTickets: Scalars['Int'];
  userId: Scalars['String'];
  walletAddress?: Maybe<Scalars['String']>;
};

export type PaidTournamentEntryListRelationFilter = {
  every?: InputMaybe<PaidTournamentEntryWhereInput>;
  none?: InputMaybe<PaidTournamentEntryWhereInput>;
  some?: InputMaybe<PaidTournamentEntryWhereInput>;
};

export type PaidTournamentEntryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaidTournamentEntryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  paidTournament?: InputMaybe<PaidTournamentOrderByWithRelationInput>;
  paidTournamentId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrderInput>;
  prizeClaimed?: InputMaybe<SortOrder>;
  prizeSendStatus?: InputMaybe<SortOrderInput>;
  usedTickets?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  walletAddress?: InputMaybe<SortOrderInput>;
};

export type PaidTournamentEntryPaidTournamentIdUserIdCompoundUniqueInput = {
  paidTournamentId: Scalars['String'];
  userId: Scalars['String'];
};

export enum PaidTournamentEntryScalarFieldEnum {
  CountryFromIp = 'countryFromIp',
  CreatedAt = 'createdAt',
  Id = 'id',
  PaidTournamentId = 'paidTournamentId',
  PhoneNumber = 'phoneNumber',
  PrizeClaimed = 'prizeClaimed',
  PrizeSendStatus = 'prizeSendStatus',
  UpdatedAt = 'updatedAt',
  UsedTickets = 'usedTickets',
  UserId = 'userId',
  WalletAddress = 'walletAddress'
}

export type PaidTournamentEntryWhereInput = {
  AND?: InputMaybe<Array<PaidTournamentEntryWhereInput>>;
  NOT?: InputMaybe<Array<PaidTournamentEntryWhereInput>>;
  OR?: InputMaybe<Array<PaidTournamentEntryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  paidTournament?: InputMaybe<PaidTournamentRelationFilter>;
  paidTournamentId?: InputMaybe<UuidFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  prizeClaimed?: InputMaybe<BoolFilter>;
  prizeSendStatus?: InputMaybe<EnumPrizeSendStatusNullableFilter>;
  usedTickets?: InputMaybe<IntFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  walletAddress?: InputMaybe<StringNullableFilter>;
};

export type PaidTournamentEntryWhereUniqueInput = {
  AND?: InputMaybe<Array<PaidTournamentEntryWhereInput>>;
  NOT?: InputMaybe<Array<PaidTournamentEntryWhereInput>>;
  OR?: InputMaybe<Array<PaidTournamentEntryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  paidTournament?: InputMaybe<PaidTournamentRelationFilter>;
  paidTournamentId?: InputMaybe<UuidFilter>;
  paidTournamentId_userId?: InputMaybe<PaidTournamentEntryPaidTournamentIdUserIdCompoundUniqueInput>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  prizeClaimed?: InputMaybe<BoolFilter>;
  prizeSendStatus?: InputMaybe<EnumPrizeSendStatusNullableFilter>;
  usedTickets?: InputMaybe<IntFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  walletAddress?: InputMaybe<StringNullableFilter>;
};

export type PaidTournamentOrderByWithRelationInput = {
  activeBoosterForTournaments?: InputMaybe<ActiveBoosterForTournamentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  entries?: InputMaybe<PaidTournamentEntryOrderByRelationAggregateInput>;
  entryFeeTickets?: InputMaybe<SortOrder>;
  gameId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrderInput>;
  paidTournamentBoosterAvailable?: InputMaybe<PaidTournamentBoosterAvailableOrderByRelationAggregateInput>;
  resultRecorded?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type PaidTournamentPrizeClaimIgnoreUser = {
  __typename?: 'PaidTournamentPrizeClaimIgnoreUser';
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type PaidTournamentPrizeClaimIgnoreUserNullableRelationFilter = {
  is?: InputMaybe<PaidTournamentPrizeClaimIgnoreUserWhereInput>;
  isNot?: InputMaybe<PaidTournamentPrizeClaimIgnoreUserWhereInput>;
};

export type PaidTournamentPrizeClaimIgnoreUserOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type PaidTournamentPrizeClaimIgnoreUserWhereInput = {
  AND?: InputMaybe<Array<PaidTournamentPrizeClaimIgnoreUserWhereInput>>;
  NOT?: InputMaybe<Array<PaidTournamentPrizeClaimIgnoreUserWhereInput>>;
  OR?: InputMaybe<Array<PaidTournamentPrizeClaimIgnoreUserWhereInput>>;
  id?: InputMaybe<UuidFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type PaidTournamentRelationFilter = {
  is?: InputMaybe<PaidTournamentWhereInput>;
  isNot?: InputMaybe<PaidTournamentWhereInput>;
};

export enum PaidTournamentScalarFieldEnum {
  CreatedAt = 'createdAt',
  EndAt = 'endAt',
  EntryFeeTickets = 'entryFeeTickets',
  GameId = 'gameId',
  Id = 'id',
  ImageUrl = 'imageUrl',
  PrizeTerasAmount = 'prizeTerasAmount',
  PrizeTerasOnly = 'prizeTerasOnly',
  ResultRecorded = 'resultRecorded',
  StartAt = 'startAt',
  TargetArea = 'targetArea',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type PaidTournamentWhereInput = {
  AND?: InputMaybe<Array<PaidTournamentWhereInput>>;
  NOT?: InputMaybe<Array<PaidTournamentWhereInput>>;
  OR?: InputMaybe<Array<PaidTournamentWhereInput>>;
  activeBoosterForTournaments?: InputMaybe<ActiveBoosterForTournamentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  entries?: InputMaybe<PaidTournamentEntryListRelationFilter>;
  entryFeeTickets?: InputMaybe<IntFilter>;
  gameId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  paidTournamentBoosterAvailable?: InputMaybe<PaidTournamentBoosterAvailableListRelationFilter>;
  resultRecorded?: InputMaybe<BoolFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
  title?: InputMaybe<StringFilter>;
};

export type PaidTournamentWhereUniqueInput = {
  AND?: InputMaybe<Array<PaidTournamentWhereInput>>;
  NOT?: InputMaybe<Array<PaidTournamentWhereInput>>;
  OR?: InputMaybe<Array<PaidTournamentWhereInput>>;
  activeBoosterForTournaments?: InputMaybe<ActiveBoosterForTournamentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  entries?: InputMaybe<PaidTournamentEntryListRelationFilter>;
  entryFeeTickets?: InputMaybe<IntFilter>;
  gameId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  paidTournamentBoosterAvailable?: InputMaybe<PaidTournamentBoosterAvailableListRelationFilter>;
  resultRecorded?: InputMaybe<BoolFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
  title?: InputMaybe<StringFilter>;
};

export type Play = {
  __typename?: 'Play';
  createdAt: Scalars['DateTime'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  megaSpark: Scalars['Boolean'];
  ownerTerasReward?: Maybe<Scalars['Decimal']>;
  playSession: PlaySession;
  playSessionId: Scalars['String'];
  playerTerasReward?: Maybe<Scalars['Decimal']>;
  result?: Maybe<PlayResult>;
  score?: Maybe<Scalars['Int']>;
  terasBoosterRatio?: Maybe<Scalars['Float']>;
};

export type PlayAvgAggregate = {
  __typename?: 'PlayAvgAggregate';
  ownerTerasReward?: Maybe<Scalars['Decimal']>;
  playerTerasReward?: Maybe<Scalars['Decimal']>;
  score?: Maybe<Scalars['Float']>;
  terasBoosterRatio?: Maybe<Scalars['Float']>;
};

export type PlayAvgOrderByAggregateInput = {
  ownerTerasReward?: InputMaybe<SortOrder>;
  playerTerasReward?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  terasBoosterRatio?: InputMaybe<SortOrder>;
};

export type PlayCountAggregate = {
  __typename?: 'PlayCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  endedAt: Scalars['Int'];
  id: Scalars['Int'];
  megaSpark: Scalars['Int'];
  ownerTerasReward: Scalars['Int'];
  playSessionId: Scalars['Int'];
  playerTerasReward: Scalars['Int'];
  result: Scalars['Int'];
  score: Scalars['Int'];
  terasBoosterRatio: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PlayCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  endedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  megaSpark?: InputMaybe<SortOrder>;
  ownerTerasReward?: InputMaybe<SortOrder>;
  playSessionId?: InputMaybe<SortOrder>;
  playerTerasReward?: InputMaybe<SortOrder>;
  result?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  terasBoosterRatio?: InputMaybe<SortOrder>;
};

export type PlayGroupBy = {
  __typename?: 'PlayGroupBy';
  _avg?: Maybe<PlayAvgAggregate>;
  _count?: Maybe<PlayCountAggregate>;
  _max?: Maybe<PlayMaxAggregate>;
  _min?: Maybe<PlayMinAggregate>;
  _sum?: Maybe<PlaySumAggregate>;
  createdAt: Scalars['DateTime'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  megaSpark: Scalars['Boolean'];
  ownerTerasReward?: Maybe<Scalars['Decimal']>;
  playSessionId: Scalars['String'];
  playerTerasReward?: Maybe<Scalars['Decimal']>;
  result?: Maybe<PlayResult>;
  score?: Maybe<Scalars['Int']>;
  terasBoosterRatio?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type PlayListRelationFilter = {
  every?: InputMaybe<PlayWhereInput>;
  none?: InputMaybe<PlayWhereInput>;
  some?: InputMaybe<PlayWhereInput>;
};

export type PlayMaxAggregate = {
  __typename?: 'PlayMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  megaSpark?: Maybe<Scalars['Boolean']>;
  ownerTerasReward?: Maybe<Scalars['Decimal']>;
  playSessionId?: Maybe<Scalars['String']>;
  playerTerasReward?: Maybe<Scalars['Decimal']>;
  result?: Maybe<PlayResult>;
  score?: Maybe<Scalars['Int']>;
  terasBoosterRatio?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlayMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  endedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  megaSpark?: InputMaybe<SortOrder>;
  ownerTerasReward?: InputMaybe<SortOrder>;
  playSessionId?: InputMaybe<SortOrder>;
  playerTerasReward?: InputMaybe<SortOrder>;
  result?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  terasBoosterRatio?: InputMaybe<SortOrder>;
};

export type PlayMinAggregate = {
  __typename?: 'PlayMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  megaSpark?: Maybe<Scalars['Boolean']>;
  ownerTerasReward?: Maybe<Scalars['Decimal']>;
  playSessionId?: Maybe<Scalars['String']>;
  playerTerasReward?: Maybe<Scalars['Decimal']>;
  result?: Maybe<PlayResult>;
  score?: Maybe<Scalars['Int']>;
  terasBoosterRatio?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlayMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  endedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  megaSpark?: InputMaybe<SortOrder>;
  ownerTerasReward?: InputMaybe<SortOrder>;
  playSessionId?: InputMaybe<SortOrder>;
  playerTerasReward?: InputMaybe<SortOrder>;
  result?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  terasBoosterRatio?: InputMaybe<SortOrder>;
};

export type PlayOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PlayOrderByWithAggregationInput = {
  _avg?: InputMaybe<PlayAvgOrderByAggregateInput>;
  _count?: InputMaybe<PlayCountOrderByAggregateInput>;
  _max?: InputMaybe<PlayMaxOrderByAggregateInput>;
  _min?: InputMaybe<PlayMinOrderByAggregateInput>;
  _sum?: InputMaybe<PlaySumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  endedAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  megaSpark?: InputMaybe<SortOrder>;
  ownerTerasReward?: InputMaybe<SortOrderInput>;
  playSessionId?: InputMaybe<SortOrder>;
  playerTerasReward?: InputMaybe<SortOrderInput>;
  result?: InputMaybe<SortOrderInput>;
  score?: InputMaybe<SortOrderInput>;
  terasBoosterRatio?: InputMaybe<SortOrderInput>;
};

export type PlayOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  endedAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  megaSpark?: InputMaybe<SortOrder>;
  ownerTerasReward?: InputMaybe<SortOrderInput>;
  playSession?: InputMaybe<PlaySessionOrderByWithRelationInput>;
  playSessionId?: InputMaybe<SortOrder>;
  playerTerasReward?: InputMaybe<SortOrderInput>;
  result?: InputMaybe<SortOrderInput>;
  score?: InputMaybe<SortOrderInput>;
  terasBoosterRatio?: InputMaybe<SortOrderInput>;
};

export enum PlayResult {
  Disconnected = 'DISCONNECTED',
  Loss = 'LOSS',
  Win = 'WIN'
}

export enum PlayScalarFieldEnum {
  CreatedAt = 'createdAt',
  EndedAt = 'endedAt',
  Id = 'id',
  MegaSpark = 'megaSpark',
  OwnerTerasReward = 'ownerTerasReward',
  PlaySessionId = 'playSessionId',
  PlayerTerasReward = 'playerTerasReward',
  Result = 'result',
  Score = 'score',
  TerasBoosterRatio = 'terasBoosterRatio',
  UpdatedAt = 'updatedAt'
}

export type PlayScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PlayScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PlayScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PlayScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  endedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  megaSpark?: InputMaybe<BoolWithAggregatesFilter>;
  ownerTerasReward?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  playSessionId?: InputMaybe<UuidWithAggregatesFilter>;
  playerTerasReward?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  result?: InputMaybe<EnumPlayResultNullableWithAggregatesFilter>;
  score?: InputMaybe<IntNullableWithAggregatesFilter>;
  terasBoosterRatio?: InputMaybe<FloatNullableWithAggregatesFilter>;
};

export type PlaySession = {
  __typename?: 'PlaySession';
  _count?: Maybe<PlaySessionCount>;
  arcadeMachine: ArcadeMachine;
  arcadeMachineId: Scalars['String'];
  arcadeMachineOwner?: Maybe<User>;
  arcadeMachineOwnerId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  difficulty?: Maybe<Scalars['Int']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  fever: Scalars['Boolean'];
  gameCenter?: Maybe<GameCenter>;
  gameCenterId?: Maybe<Scalars['String']>;
  gameCenterOwner?: Maybe<User>;
  gameCenterOwnerId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  maxPlayCount?: Maybe<Scalars['Int']>;
  player: User;
  playerId: Scalars['String'];
  plays: Array<Play>;
  state: PlaySessionState;
  targetScore?: Maybe<Scalars['Int']>;
};


export type PlaySessionArcadeMachineOwnerArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type PlaySessionGameCenterArgs = {
  where?: InputMaybe<GameCenterWhereInput>;
};


export type PlaySessionGameCenterOwnerArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type PlaySessionPlaysArgs = {
  cursor?: InputMaybe<PlayWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayWhereInput>;
};

export type PlaySessionAvgAggregate = {
  __typename?: 'PlaySessionAvgAggregate';
  difficulty?: Maybe<Scalars['Float']>;
  maxPlayCount?: Maybe<Scalars['Float']>;
  targetScore?: Maybe<Scalars['Float']>;
};

export type PlaySessionCount = {
  __typename?: 'PlaySessionCount';
  plays: Scalars['Int'];
};


export type PlaySessionCountPlaysArgs = {
  where?: InputMaybe<PlayWhereInput>;
};

export type PlaySessionCountAggregate = {
  __typename?: 'PlaySessionCountAggregate';
  _all: Scalars['Int'];
  arcadeMachineId: Scalars['Int'];
  arcadeMachineOwnerId: Scalars['Int'];
  authToken: Scalars['Int'];
  createdAt: Scalars['Int'];
  difficulty: Scalars['Int'];
  endedAt: Scalars['Int'];
  fever: Scalars['Int'];
  gameCenterId: Scalars['Int'];
  gameCenterOwnerId: Scalars['Int'];
  id: Scalars['Int'];
  maxPlayCount: Scalars['Int'];
  playerId: Scalars['Int'];
  state: Scalars['Int'];
  targetScore: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PlaySessionListRelationFilter = {
  every?: InputMaybe<PlaySessionWhereInput>;
  none?: InputMaybe<PlaySessionWhereInput>;
  some?: InputMaybe<PlaySessionWhereInput>;
};

export type PlaySessionMaxAggregate = {
  __typename?: 'PlaySessionMaxAggregate';
  arcadeMachineId?: Maybe<Scalars['String']>;
  arcadeMachineOwnerId?: Maybe<Scalars['String']>;
  authToken?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  difficulty?: Maybe<Scalars['Int']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  fever?: Maybe<Scalars['Boolean']>;
  gameCenterId?: Maybe<Scalars['String']>;
  gameCenterOwnerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxPlayCount?: Maybe<Scalars['Int']>;
  playerId?: Maybe<Scalars['String']>;
  state?: Maybe<PlaySessionState>;
  targetScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlaySessionMinAggregate = {
  __typename?: 'PlaySessionMinAggregate';
  arcadeMachineId?: Maybe<Scalars['String']>;
  arcadeMachineOwnerId?: Maybe<Scalars['String']>;
  authToken?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  difficulty?: Maybe<Scalars['Int']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  fever?: Maybe<Scalars['Boolean']>;
  gameCenterId?: Maybe<Scalars['String']>;
  gameCenterOwnerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  maxPlayCount?: Maybe<Scalars['Int']>;
  playerId?: Maybe<Scalars['String']>;
  state?: Maybe<PlaySessionState>;
  targetScore?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlaySessionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PlaySessionOrderByWithRelationInput = {
  arcadeMachine?: InputMaybe<ArcadeMachineOrderByWithRelationInput>;
  arcadeMachineId?: InputMaybe<SortOrder>;
  arcadeMachineOwner?: InputMaybe<UserOrderByWithRelationInput>;
  arcadeMachineOwnerId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  difficulty?: InputMaybe<SortOrderInput>;
  endedAt?: InputMaybe<SortOrderInput>;
  fever?: InputMaybe<SortOrder>;
  gameCenter?: InputMaybe<GameCenterOrderByWithRelationInput>;
  gameCenterId?: InputMaybe<SortOrderInput>;
  gameCenterOwner?: InputMaybe<UserOrderByWithRelationInput>;
  gameCenterOwnerId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  maxPlayCount?: InputMaybe<SortOrderInput>;
  player?: InputMaybe<UserOrderByWithRelationInput>;
  playerId?: InputMaybe<SortOrder>;
  plays?: InputMaybe<PlayOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrder>;
  targetScore?: InputMaybe<SortOrderInput>;
};

export type PlaySessionRelationFilter = {
  is?: InputMaybe<PlaySessionWhereInput>;
  isNot?: InputMaybe<PlaySessionWhereInput>;
};

export enum PlaySessionScalarFieldEnum {
  ArcadeMachineId = 'arcadeMachineId',
  ArcadeMachineOwnerId = 'arcadeMachineOwnerId',
  AuthToken = 'authToken',
  CreatedAt = 'createdAt',
  Difficulty = 'difficulty',
  EndedAt = 'endedAt',
  Fever = 'fever',
  GameCenterId = 'gameCenterId',
  GameCenterOwnerId = 'gameCenterOwnerId',
  Id = 'id',
  MaxPlayCount = 'maxPlayCount',
  PlayerId = 'playerId',
  State = 'state',
  TargetScore = 'targetScore',
  UpdatedAt = 'updatedAt'
}

export enum PlaySessionState {
  Finished = 'FINISHED',
  Playing = 'PLAYING',
  Ready = 'READY'
}

export type PlaySessionSumAggregate = {
  __typename?: 'PlaySessionSumAggregate';
  difficulty?: Maybe<Scalars['Int']>;
  maxPlayCount?: Maybe<Scalars['Int']>;
  targetScore?: Maybe<Scalars['Int']>;
};

export type PlaySessionWhereInput = {
  AND?: InputMaybe<Array<PlaySessionWhereInput>>;
  NOT?: InputMaybe<Array<PlaySessionWhereInput>>;
  OR?: InputMaybe<Array<PlaySessionWhereInput>>;
  arcadeMachine?: InputMaybe<ArcadeMachineRelationFilter>;
  arcadeMachineId?: InputMaybe<StringFilter>;
  arcadeMachineOwner?: InputMaybe<UserNullableRelationFilter>;
  arcadeMachineOwnerId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  difficulty?: InputMaybe<IntNullableFilter>;
  endedAt?: InputMaybe<DateTimeNullableFilter>;
  fever?: InputMaybe<BoolFilter>;
  gameCenter?: InputMaybe<GameCenterNullableRelationFilter>;
  gameCenterId?: InputMaybe<StringNullableFilter>;
  gameCenterOwner?: InputMaybe<UserNullableRelationFilter>;
  gameCenterOwnerId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  maxPlayCount?: InputMaybe<IntNullableFilter>;
  player?: InputMaybe<UserRelationFilter>;
  playerId?: InputMaybe<UuidFilter>;
  plays?: InputMaybe<PlayListRelationFilter>;
  state?: InputMaybe<EnumPlaySessionStateFilter>;
  targetScore?: InputMaybe<IntNullableFilter>;
};

export type PlaySessionWhereUniqueInput = {
  AND?: InputMaybe<Array<PlaySessionWhereInput>>;
  NOT?: InputMaybe<Array<PlaySessionWhereInput>>;
  OR?: InputMaybe<Array<PlaySessionWhereInput>>;
  arcadeMachine?: InputMaybe<ArcadeMachineRelationFilter>;
  arcadeMachineId?: InputMaybe<StringFilter>;
  arcadeMachineOwner?: InputMaybe<UserNullableRelationFilter>;
  arcadeMachineOwnerId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  difficulty?: InputMaybe<IntNullableFilter>;
  endedAt?: InputMaybe<DateTimeNullableFilter>;
  fever?: InputMaybe<BoolFilter>;
  gameCenter?: InputMaybe<GameCenterNullableRelationFilter>;
  gameCenterId?: InputMaybe<StringNullableFilter>;
  gameCenterOwner?: InputMaybe<UserNullableRelationFilter>;
  gameCenterOwnerId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  maxPlayCount?: InputMaybe<IntNullableFilter>;
  player?: InputMaybe<UserRelationFilter>;
  playerId?: InputMaybe<UuidFilter>;
  plays?: InputMaybe<PlayListRelationFilter>;
  state?: InputMaybe<EnumPlaySessionStateFilter>;
  targetScore?: InputMaybe<IntNullableFilter>;
};

export type PlaySumAggregate = {
  __typename?: 'PlaySumAggregate';
  ownerTerasReward?: Maybe<Scalars['Decimal']>;
  playerTerasReward?: Maybe<Scalars['Decimal']>;
  score?: Maybe<Scalars['Int']>;
  terasBoosterRatio?: Maybe<Scalars['Float']>;
};

export type PlaySumOrderByAggregateInput = {
  ownerTerasReward?: InputMaybe<SortOrder>;
  playerTerasReward?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  terasBoosterRatio?: InputMaybe<SortOrder>;
};

export type PlayWhereInput = {
  AND?: InputMaybe<Array<PlayWhereInput>>;
  NOT?: InputMaybe<Array<PlayWhereInput>>;
  OR?: InputMaybe<Array<PlayWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  megaSpark?: InputMaybe<BoolFilter>;
  ownerTerasReward?: InputMaybe<DecimalNullableFilter>;
  playSession?: InputMaybe<PlaySessionRelationFilter>;
  playSessionId?: InputMaybe<UuidFilter>;
  playerTerasReward?: InputMaybe<DecimalNullableFilter>;
  result?: InputMaybe<EnumPlayResultNullableFilter>;
  score?: InputMaybe<IntNullableFilter>;
  terasBoosterRatio?: InputMaybe<FloatNullableFilter>;
};

export type PlayWhereUniqueInput = {
  AND?: InputMaybe<Array<PlayWhereInput>>;
  NOT?: InputMaybe<Array<PlayWhereInput>>;
  OR?: InputMaybe<Array<PlayWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  megaSpark?: InputMaybe<BoolFilter>;
  ownerTerasReward?: InputMaybe<DecimalNullableFilter>;
  playSession?: InputMaybe<PlaySessionRelationFilter>;
  playSessionId?: InputMaybe<UuidFilter>;
  playerTerasReward?: InputMaybe<DecimalNullableFilter>;
  result?: InputMaybe<EnumPlayResultNullableFilter>;
  score?: InputMaybe<IntNullableFilter>;
  terasBoosterRatio?: InputMaybe<FloatNullableFilter>;
};

export type PrizeByRankOutput = {
  __typename?: 'PrizeByRankOutput';
  order: Scalars['Float'];
  prizes: Array<PrizeOutput>;
  title: Scalars['String'];
};

/** Prize claim type */
export enum PrizeClaimType {
  PhoneNumber = 'PHONE_NUMBER',
  WalletAddress = 'WALLET_ADDRESS'
}

export type PrizeInfoOutput = {
  __typename?: 'PrizeInfoOutput';
  prizeByRank: Array<PrizeByRankOutput>;
  totalPrizePoolTeras: Scalars['Decimal'];
  winnerPrizeTeras: Scalars['Decimal'];
};

export type PrizeOutput = {
  __typename?: 'PrizeOutput';
  amount: Scalars['Float'];
  category?: Maybe<Scalars['String']>;
  itemType: Scalars['String'];
  name: Scalars['String'];
  percentage?: Maybe<Scalars['Float']>;
  subCategory?: Maybe<Scalars['String']>;
};

export enum PrizeSendStatus {
  Confirmed = 'CONFIRMED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Unprocessed = 'UNPROCESSED'
}

export enum PurchaseStatus {
  Canceled = 'CANCELED',
  Granted = 'GRANTED',
  Invalid = 'INVALID',
  Unprocessed = 'UNPROCESSED'
}

export type Query = {
  __typename?: 'Query';
  aggregateArcadeMachine: AggregateArcadeMachine;
  aggregateArcadePart: AggregateArcadePart;
  aggregateCraft: AggregateCraft;
  aggregateGameCenter: AggregateGameCenter;
  aggregatePlay: AggregatePlay;
  aggregatePlaySession: AggregatePlaySession;
  aggregateUser: AggregateUser;
  arcadeMachine?: Maybe<ArcadeMachine>;
  arcadeMachines: Array<ArcadeMachine>;
  arcadePart?: Maybe<ArcadePart>;
  arcadeParts: Array<ArcadePart>;
  banners: Array<Banner>;
  checkTransfers: CheckTransfersOutput;
  checkVersion: CheckVersionOutput;
  craft?: Maybe<Craft>;
  crafts: Array<Craft>;
  currentUser: User;
  currentUserCollectibleItems: Array<CollectibleItem>;
  /** current user's notifications */
  currentUserNotifications: Array<Notification>;
  /** current user's notifications count */
  currentUserNotificationsCount: NotificationCountOutput;
  currentUserQuestChains: Array<QuestChain>;
  currentUserRewards: Array<Reward>;
  extract?: Maybe<Extract>;
  extractInitialInventories: Array<ExtractInitialInventory>;
  extractInitialInventory?: Maybe<ExtractInitialInventory>;
  extractJunkInventories: Array<ExtractJunkInventory>;
  extractJunkInventory?: Maybe<ExtractJunkInventory>;
  extracts: Array<Extract>;
  findFirstArcadeMachine?: Maybe<ArcadeMachine>;
  findFirstArcadePart?: Maybe<ArcadePart>;
  findFirstCraft?: Maybe<Craft>;
  findFirstExtract?: Maybe<Extract>;
  findFirstExtractInitialInventory?: Maybe<ExtractInitialInventory>;
  findFirstExtractJunkInventory?: Maybe<ExtractJunkInventory>;
  findFirstGameCenter?: Maybe<GameCenter>;
  findFirstJunk?: Maybe<Junk>;
  findFirstPlay?: Maybe<Play>;
  findFirstPlaySession?: Maybe<PlaySession>;
  findFirstUser?: Maybe<User>;
  findManyNews: Array<News>;
  gameCenter?: Maybe<GameCenter>;
  gameCenters: Array<GameCenter>;
  groupByArcadeMachine: Array<ArcadeMachineGroupBy>;
  groupByArcadePart: Array<ArcadePartGroupBy>;
  groupByCraft: Array<CraftGroupBy>;
  groupByExtract: Array<ExtractGroupBy>;
  groupByExtractInitialInventory: Array<ExtractInitialInventoryGroupBy>;
  groupByExtractJunkInventory: Array<ExtractJunkInventoryGroupBy>;
  groupByGameCenter: Array<GameCenterGroupBy>;
  groupByJunk: Array<JunkGroupBy>;
  groupByPlay: Array<PlayGroupBy>;
  groupByUser: Array<UserGroupBy>;
  junk?: Maybe<Junk>;
  junks: Array<Junk>;
  listBanners: Array<Banner>;
  listExtractInventory: ListBoxItemsOutput;
  listGames?: Maybe<Games>;
  listInAppPurchaseItems: Array<ListInAppPurchaseItemsOutput>;
  listInterstitialBanners: Array<InterstitialBanner>;
  listOwnedNFTs: Array<ListNfTsOutput>;
  listPaidTournamentRankings: ListPaidTournamentRankingsOutput;
  listPlacementArcadeMachines: GameCenter;
  listRandomArcadeMachines: ListRandomArcadeMachinesOutput;
  listRankings: ListRankingsOutput;
  login: LoginOutput;
  minimumAppVersion?: Maybe<MinimumAppVersion>;
  notifications: Array<Notification>;
  paidTournament?: Maybe<PaidTournament>;
  paidTournamentBoosterAvailables: Array<PaidTournamentBoosterAvailable>;
  paidTournaments: Array<PaidTournament>;
  play?: Maybe<Play>;
  playSession?: Maybe<PlaySession>;
  playSessions: Array<PlaySession>;
  plays: Array<Play>;
  tokenRefresh: TokenRefreshOutput;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryAggregateArcadeMachineArgs = {
  cursor?: InputMaybe<ArcadeMachineWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ArcadeMachineOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadeMachineWhereInput>;
};


export type QueryAggregateArcadePartArgs = {
  cursor?: InputMaybe<ArcadePartWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ArcadePartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadePartWhereInput>;
};


export type QueryAggregateCraftArgs = {
  cursor?: InputMaybe<CraftWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CraftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CraftWhereInput>;
};


export type QueryAggregateGameCenterArgs = {
  cursor?: InputMaybe<GameCenterWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GameCenterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GameCenterWhereInput>;
};


export type QueryAggregatePlayArgs = {
  cursor?: InputMaybe<PlayWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PlayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayWhereInput>;
};


export type QueryAggregatePlaySessionArgs = {
  cursor?: InputMaybe<PlaySessionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PlaySessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryArcadeMachineArgs = {
  where: ArcadeMachineWhereUniqueInput;
};


export type QueryArcadeMachinesArgs = {
  cursor?: InputMaybe<ArcadeMachineWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArcadeMachineScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArcadeMachineOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadeMachineWhereInput>;
};


export type QueryArcadePartArgs = {
  where: ArcadePartWhereUniqueInput;
};


export type QueryArcadePartsArgs = {
  cursor?: InputMaybe<ArcadePartWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArcadePartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArcadePartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadePartWhereInput>;
};


export type QueryBannersArgs = {
  cursor?: InputMaybe<BannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<BannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerWhereInput>;
};


export type QueryCheckVersionArgs = {
  os?: InputMaybe<OperatingSystem>;
  version?: InputMaybe<Scalars['String']>;
};


export type QueryCraftArgs = {
  where: CraftWhereUniqueInput;
};


export type QueryCraftsArgs = {
  cursor?: InputMaybe<CraftWhereUniqueInput>;
  distinct?: InputMaybe<Array<CraftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CraftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CraftWhereInput>;
};


export type QueryCurrentUserCollectibleItemsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<CollectibleItemOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<CollectibleItemsWhereInput>;
};


export type QueryCurrentUserNotificationsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<NotificationOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<CurrentUserNotificationWhereInput>;
};


export type QueryCurrentUserNotificationsCountArgs = {
  where?: InputMaybe<CurrentUserNotificationWhereInput>;
};


export type QueryCurrentUserQuestChainsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<QuestChainOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<CurrentUserQuestChainWhereInput>;
};


export type QueryCurrentUserRewardsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<RewardOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  where?: InputMaybe<CurrentUserRewardWhereInput>;
};


export type QueryExtractArgs = {
  where: ExtractWhereUniqueInput;
};


export type QueryExtractInitialInventoriesArgs = {
  cursor?: InputMaybe<ExtractInitialInventoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExtractInitialInventoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExtractInitialInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractInitialInventoryWhereInput>;
};


export type QueryExtractInitialInventoryArgs = {
  where: ExtractInitialInventoryWhereUniqueInput;
};


export type QueryExtractJunkInventoriesArgs = {
  cursor?: InputMaybe<ExtractJunkInventoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExtractJunkInventoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExtractJunkInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractJunkInventoryWhereInput>;
};


export type QueryExtractJunkInventoryArgs = {
  where: ExtractJunkInventoryWhereUniqueInput;
};


export type QueryExtractsArgs = {
  cursor?: InputMaybe<ExtractWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExtractScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExtractOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractWhereInput>;
};


export type QueryFindFirstArcadeMachineArgs = {
  cursor?: InputMaybe<ArcadeMachineWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArcadeMachineScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArcadeMachineOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadeMachineWhereInput>;
};


export type QueryFindFirstArcadePartArgs = {
  cursor?: InputMaybe<ArcadePartWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArcadePartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArcadePartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadePartWhereInput>;
};


export type QueryFindFirstCraftArgs = {
  cursor?: InputMaybe<CraftWhereUniqueInput>;
  distinct?: InputMaybe<Array<CraftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CraftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CraftWhereInput>;
};


export type QueryFindFirstExtractArgs = {
  cursor?: InputMaybe<ExtractWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExtractScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExtractOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractWhereInput>;
};


export type QueryFindFirstExtractInitialInventoryArgs = {
  cursor?: InputMaybe<ExtractInitialInventoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExtractInitialInventoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExtractInitialInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractInitialInventoryWhereInput>;
};


export type QueryFindFirstExtractJunkInventoryArgs = {
  cursor?: InputMaybe<ExtractJunkInventoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExtractJunkInventoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExtractJunkInventoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractJunkInventoryWhereInput>;
};


export type QueryFindFirstGameCenterArgs = {
  cursor?: InputMaybe<GameCenterWhereUniqueInput>;
  distinct?: InputMaybe<Array<GameCenterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GameCenterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GameCenterWhereInput>;
};


export type QueryFindFirstJunkArgs = {
  cursor?: InputMaybe<JunkWhereUniqueInput>;
  distinct?: InputMaybe<Array<JunkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<JunkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<JunkWhereInput>;
};


export type QueryFindFirstPlayArgs = {
  cursor?: InputMaybe<PlayWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayWhereInput>;
};


export type QueryFindFirstPlaySessionArgs = {
  cursor?: InputMaybe<PlaySessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaySessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaySessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindManyNewsArgs = {
  cursor?: InputMaybe<NewsWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsWhereInput>;
};


export type QueryGameCenterArgs = {
  where: GameCenterWhereUniqueInput;
};


export type QueryGameCentersArgs = {
  cursor?: InputMaybe<GameCenterWhereUniqueInput>;
  distinct?: InputMaybe<Array<GameCenterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GameCenterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GameCenterWhereInput>;
};


export type QueryGroupByArcadeMachineArgs = {
  by: Array<ArcadeMachineScalarFieldEnum>;
  having?: InputMaybe<ArcadeMachineScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ArcadeMachineOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadeMachineWhereInput>;
};


export type QueryGroupByArcadePartArgs = {
  by: Array<ArcadePartScalarFieldEnum>;
  having?: InputMaybe<ArcadePartScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ArcadePartOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadePartWhereInput>;
};


export type QueryGroupByCraftArgs = {
  by: Array<CraftScalarFieldEnum>;
  having?: InputMaybe<CraftScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CraftOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CraftWhereInput>;
};


export type QueryGroupByExtractArgs = {
  by: Array<ExtractScalarFieldEnum>;
  having?: InputMaybe<ExtractScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ExtractOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractWhereInput>;
};


export type QueryGroupByExtractInitialInventoryArgs = {
  by: Array<ExtractInitialInventoryScalarFieldEnum>;
  having?: InputMaybe<ExtractInitialInventoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ExtractInitialInventoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractInitialInventoryWhereInput>;
};


export type QueryGroupByExtractJunkInventoryArgs = {
  by: Array<ExtractJunkInventoryScalarFieldEnum>;
  having?: InputMaybe<ExtractJunkInventoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ExtractJunkInventoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractJunkInventoryWhereInput>;
};


export type QueryGroupByGameCenterArgs = {
  by: Array<GameCenterScalarFieldEnum>;
  having?: InputMaybe<GameCenterScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<GameCenterOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GameCenterWhereInput>;
};


export type QueryGroupByJunkArgs = {
  by: Array<JunkScalarFieldEnum>;
  having?: InputMaybe<JunkScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<JunkOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<JunkWhereInput>;
};


export type QueryGroupByPlayArgs = {
  by: Array<PlayScalarFieldEnum>;
  having?: InputMaybe<PlayScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PlayOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryJunkArgs = {
  where: JunkWhereUniqueInput;
};


export type QueryJunksArgs = {
  cursor?: InputMaybe<JunkWhereUniqueInput>;
  distinct?: InputMaybe<Array<JunkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<JunkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<JunkWhereInput>;
};


export type QueryListBannersArgs = {
  cursor?: InputMaybe<BannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<BannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerWhereInput>;
};


export type QueryListGamesArgs = {
  version?: InputMaybe<Scalars['String']>;
};


export type QueryListInAppPurchaseItemsArgs = {
  os?: InputMaybe<OperatingSystem>;
};


export type QueryListInterstitialBannersArgs = {
  cursor?: InputMaybe<InterstitialBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InterstitialBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InterstitialBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterstitialBannerWhereInput>;
};


export type QueryListPaidTournamentRankingsArgs = {
  tournamentId: Scalars['String'];
};


export type QueryListPlacementArcadeMachinesArgs = {
  id: Scalars['String'];
};


export type QueryListRandomArcadeMachinesArgs = {
  game?: InputMaybe<Scalars['String']>;
  maxPlayingCount?: InputMaybe<Scalars['Float']>;
  requestCount?: InputMaybe<Scalars['Float']>;
};


export type QueryListRankingsArgs = {
  rankingId: Scalars['String'];
};


export type QueryLoginArgs = {
  didToken?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
};


export type QueryMinimumAppVersionArgs = {
  where: MinimumAppVersionWhereUniqueInput;
};


export type QueryNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryPaidTournamentArgs = {
  where: PaidTournamentWhereUniqueInput;
};


export type QueryPaidTournamentBoosterAvailablesArgs = {
  cursor?: InputMaybe<PaidTournamentBoosterAvailableWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaidTournamentBoosterAvailableScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaidTournamentBoosterAvailableOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaidTournamentBoosterAvailableWhereInput>;
};


export type QueryPaidTournamentsArgs = {
  cursor?: InputMaybe<PaidTournamentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaidTournamentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaidTournamentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaidTournamentWhereInput>;
};


export type QueryPlayArgs = {
  where: PlayWhereUniqueInput;
};


export type QueryPlaySessionArgs = {
  where: PlaySessionWhereUniqueInput;
};


export type QueryPlaySessionsArgs = {
  cursor?: InputMaybe<PlaySessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaySessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaySessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type QueryPlaysArgs = {
  cursor?: InputMaybe<PlayWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayWhereInput>;
};


export type QueryTokenRefreshArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
  requestNewAuth?: InputMaybe<Scalars['Boolean']>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Quest = {
  __typename?: 'Quest';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  progress: Scalars['Float'];
  questChain: QuestChain;
  questChainId: Scalars['String'];
  questMasterId: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type QuestChain = {
  __typename?: 'QuestChain';
  _count?: Maybe<QuestChainCount>;
  acceptedAt: Scalars['DateTime'];
  completed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  questChainMasterId: Scalars['String'];
  quests: Array<Quest>;
  user: User;
  userId: Scalars['String'];
};


export type QuestChainQuestsArgs = {
  cursor?: InputMaybe<QuestWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestWhereInput>;
};

export type QuestChainCount = {
  __typename?: 'QuestChainCount';
  quests: Scalars['Int'];
};


export type QuestChainCountQuestsArgs = {
  where?: InputMaybe<QuestWhereInput>;
};

export type QuestChainListRelationFilter = {
  every?: InputMaybe<QuestChainWhereInput>;
  none?: InputMaybe<QuestChainWhereInput>;
  some?: InputMaybe<QuestChainWhereInput>;
};

export type QuestChainOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QuestChainOrderByWithRelationInput = {
  acceptedAt?: InputMaybe<SortOrder>;
  completed?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  questChainMasterId?: InputMaybe<SortOrder>;
  quests?: InputMaybe<QuestOrderByRelationAggregateInput>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type QuestChainRelationFilter = {
  is?: InputMaybe<QuestChainWhereInput>;
  isNot?: InputMaybe<QuestChainWhereInput>;
};

export enum QuestChainScalarFieldEnum {
  AcceptedAt = 'acceptedAt',
  Completed = 'completed',
  CreatedAt = 'createdAt',
  ExpiredAt = 'expiredAt',
  Id = 'id',
  QuestChainMasterId = 'questChainMasterId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type QuestChainUserIdQuestChainMasterIdCompoundUniqueInput = {
  questChainMasterId: Scalars['String'];
  userId: Scalars['String'];
};

export type QuestChainWhereInput = {
  AND?: InputMaybe<Array<QuestChainWhereInput>>;
  NOT?: InputMaybe<Array<QuestChainWhereInput>>;
  OR?: InputMaybe<Array<QuestChainWhereInput>>;
  acceptedAt?: InputMaybe<DateTimeFilter>;
  completed?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  questChainMasterId?: InputMaybe<StringFilter>;
  quests?: InputMaybe<QuestListRelationFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type QuestChainWhereUniqueInput = {
  AND?: InputMaybe<Array<QuestChainWhereInput>>;
  NOT?: InputMaybe<Array<QuestChainWhereInput>>;
  OR?: InputMaybe<Array<QuestChainWhereInput>>;
  acceptedAt?: InputMaybe<DateTimeFilter>;
  completed?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']>;
  questChainMasterId?: InputMaybe<StringFilter>;
  quests?: InputMaybe<QuestListRelationFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
  userId_questChainMasterId?: InputMaybe<QuestChainUserIdQuestChainMasterIdCompoundUniqueInput>;
};

export type QuestListRelationFilter = {
  every?: InputMaybe<QuestWhereInput>;
  none?: InputMaybe<QuestWhereInput>;
  some?: InputMaybe<QuestWhereInput>;
};

export type QuestOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QuestOrderByWithRelationInput = {
  completedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questChain?: InputMaybe<QuestChainOrderByWithRelationInput>;
  questChainId?: InputMaybe<SortOrder>;
  questMasterId?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
};

export type QuestQuestChainIdQuestMasterIdCompoundUniqueInput = {
  questChainId: Scalars['String'];
  questMasterId: Scalars['String'];
};

export enum QuestScalarFieldEnum {
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  QuestChainId = 'questChainId',
  QuestMasterId = 'questMasterId',
  StartAt = 'startAt',
  UpdatedAt = 'updatedAt'
}

export type QuestWhereInput = {
  AND?: InputMaybe<Array<QuestWhereInput>>;
  NOT?: InputMaybe<Array<QuestWhereInput>>;
  OR?: InputMaybe<Array<QuestWhereInput>>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  questChain?: InputMaybe<QuestChainRelationFilter>;
  questChainId?: InputMaybe<UuidFilter>;
  questMasterId?: InputMaybe<StringFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
};

export type QuestWhereUniqueInput = {
  AND?: InputMaybe<Array<QuestWhereInput>>;
  NOT?: InputMaybe<Array<QuestWhereInput>>;
  OR?: InputMaybe<Array<QuestWhereInput>>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  questChain?: InputMaybe<QuestChainRelationFilter>;
  questChainId?: InputMaybe<UuidFilter>;
  questChainId_questMasterId?: InputMaybe<QuestQuestChainIdQuestMasterIdCompoundUniqueInput>;
  questMasterId?: InputMaybe<StringFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
};

export type RankingItem = {
  __typename?: 'RankingItem';
  frameSubCategory: Scalars['String'];
  iconSubCategory: Scalars['String'];
  iconType: Scalars['String'];
  name: Scalars['String'];
  rank: Scalars['Float'];
  score: Scalars['Float'];
  titleSubCategory: Scalars['String'];
  userId: Scalars['String'];
};

export type Rarity = {
  __typename?: 'Rarity';
  junk: Scalars['Float'];
  rom: Scalars['Float'];
};

export type Recipe = {
  __typename?: 'Recipe';
  minLowerCabinetGrade: Scalars['Float'];
  minUpperCabinetGrade: Scalars['Float'];
};

export type RefreshTokenWhereInput = {
  AND?: InputMaybe<Array<RefreshTokenWhereInput>>;
  NOT?: InputMaybe<Array<RefreshTokenWhereInput>>;
  OR?: InputMaybe<Array<RefreshTokenWhereInput>>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  tokenHash?: InputMaybe<StringNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type RegisterWalletAddressInput = {
  message?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
};

export type RegisterWalletAddressOutput = {
  __typename?: 'RegisterWalletAddressOutput';
  user: User;
};

export type RequestWeb3ChallengeInput = {
  chain?: InputMaybe<Scalars['String']>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

export type RequestWeb3ChallengeOutput = {
  __typename?: 'RequestWeb3ChallengeOutput';
  message: Scalars['String'];
  sessionToken: Scalars['String'];
};

export type Reward = {
  __typename?: 'Reward';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Int'];
  availableUntil?: Maybe<Scalars['DateTime']>;
  category: RewardCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  rewardItemType: RewardItemType;
  subCategory?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userId: Scalars['String'];
};

export enum RewardCategory {
  Accumulator = 'ACCUMULATOR',
  Frame = 'FRAME',
  Icon = 'ICON',
  LowerCabinet = 'LOWER_CABINET',
  Rom = 'ROM',
  Teras = 'TERAS',
  Title = 'TITLE',
  UpperCabinet = 'UPPER_CABINET'
}

export enum RewardItemType {
  ArcadePart = 'ARCADE_PART',
  CollectibleItem = 'COLLECTIBLE_ITEM',
  JunkPart = 'JUNK_PART',
  Teras = 'TERAS'
}

export type RewardListRelationFilter = {
  every?: InputMaybe<RewardWhereInput>;
  none?: InputMaybe<RewardWhereInput>;
  some?: InputMaybe<RewardWhereInput>;
};

export type RewardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RewardOrderByWithRelationInput = {
  acceptedAt?: InputMaybe<SortOrderInput>;
  amount?: InputMaybe<SortOrder>;
  availableUntil?: InputMaybe<SortOrderInput>;
  category?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rewardItemType?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum RewardScalarFieldEnum {
  AcceptedAt = 'acceptedAt',
  Amount = 'amount',
  AvailableUntil = 'availableUntil',
  Category = 'category',
  CreatedAt = 'createdAt',
  Id = 'id',
  RewardItemType = 'rewardItemType',
  SubCategory = 'subCategory',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type RewardWhereInput = {
  AND?: InputMaybe<Array<RewardWhereInput>>;
  NOT?: InputMaybe<Array<RewardWhereInput>>;
  OR?: InputMaybe<Array<RewardWhereInput>>;
  acceptedAt?: InputMaybe<DateTimeNullableFilter>;
  amount?: InputMaybe<IntFilter>;
  availableUntil?: InputMaybe<DateTimeNullableFilter>;
  category?: InputMaybe<EnumRewardCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  rewardItemType?: InputMaybe<EnumRewardItemTypeFilter>;
  subCategory?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type RewardWhereUniqueInput = {
  AND?: InputMaybe<Array<RewardWhereInput>>;
  NOT?: InputMaybe<Array<RewardWhereInput>>;
  OR?: InputMaybe<Array<RewardWhereInput>>;
  acceptedAt?: InputMaybe<DateTimeNullableFilter>;
  amount?: InputMaybe<IntFilter>;
  availableUntil?: InputMaybe<DateTimeNullableFilter>;
  category?: InputMaybe<EnumRewardCategoryFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']>;
  rewardItemType?: InputMaybe<EnumRewardItemTypeFilter>;
  subCategory?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type RobiGameStartOutput = {
  __typename?: 'RobiGameStartOutput';
  playToken: Scalars['String'];
};

export type RoviGameFinishInput = {
  duration?: InputMaybe<Scalars['Float']>;
  score?: InputMaybe<Scalars['Float']>;
  token?: InputMaybe<Scalars['String']>;
};

export type RoviGameFinishOutput = {
  __typename?: 'RoviGameFinishOutput';
  success: Scalars['Boolean'];
};

export type RoviGameStartInput = {
  data?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StartPlayInput = {
  playSessionToken?: InputMaybe<Scalars['String']>;
};

export type StartPlayOutput = {
  __typename?: 'StartPlayOutput';
  success: Scalars['Boolean'];
};

export type StartPlaySessionInput = {
  arcadeMachineId: Scalars['String'];
};

export type StartPlaySessionOutput = {
  __typename?: 'StartPlaySessionOutput';
  playSessionToken: Scalars['String'];
  session: PlaySession;
  winCondition: Scalars['String'];
};

/** Currency used for start quest chain */
export enum StartQuestChainCurrencyType {
  Akv = 'AKV',
  Teras = 'TERAS',
  Ticket = 'TICKET'
}

export type StartQuestChainInput = {
  questMasterId?: InputMaybe<Scalars['String']>;
  usedCurrency?: InputMaybe<StartQuestChainCurrencyType>;
};

export type StartRecruitArcadeMachineInput = {
  id: Scalars['String'];
};

export type StopRecruitArcadeMachineInput = {
  id: Scalars['String'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SwapJunkToArcadePartsInput = {
  category?: InputMaybe<ArcadePartCategory>;
  quantity?: InputMaybe<Scalars['Int']>;
  subCategory?: InputMaybe<Scalars['String']>;
};

export type SwapJunkToArcadePartsOutput = {
  __typename?: 'SwapJunkToArcadePartsOutput';
  arcadeParts: Array<ArcadePart>;
};

export type TempReviewTokenInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<OperatingSystem>;
  version?: InputMaybe<Scalars['String']>;
};

export enum TicketTransactionType {
  EnterTournament = 'ENTER_TOURNAMENT',
  OpenQuest = 'OPEN_QUEST',
  Purchase = 'PURCHASE',
  TournamentBooster = 'TOURNAMENT_BOOSTER'
}

export type TicketTransactionWhereInput = {
  AND?: InputMaybe<Array<TicketTransactionWhereInput>>;
  NOT?: InputMaybe<Array<TicketTransactionWhereInput>>;
  OR?: InputMaybe<Array<TicketTransactionWhereInput>>;
  balance?: InputMaybe<IntFilter>;
  changeAmount?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  transactionDetail?: InputMaybe<StringNullableFilter>;
  transactionType?: InputMaybe<EnumTicketTransactionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type TokenRefreshOutput = {
  __typename?: 'TokenRefreshOutput';
  /** @deprecated Firebase実装後削除 */
  accessToken: Scalars['String'];
  firebaseCustomToken: Scalars['String'];
};

export type UninstallArcadeMachineInput = {
  id: Scalars['String'];
};

export type UninstallArcadeMachineOutput = {
  __typename?: 'UninstallArcadeMachineOutput';
  arcadeMachine: ArcadeMachine;
};

export type UpdateArcadeMachineInput = {
  arcadeMachineId?: InputMaybe<Scalars['String']>;
  autoRenewLease?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  frameSubCategory?: InputMaybe<Scalars['String']>;
  iconSubCategory?: InputMaybe<Scalars['String']>;
  iconType?: InputMaybe<IconType>;
  name?: InputMaybe<Scalars['String']>;
  titleSubCategory?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  Burn: Array<Burn>;
  Dismantle: Array<Dismantle>;
  _count?: Maybe<UserCount>;
  activeBoosterForTournaments: Array<ActiveBoosterForTournament>;
  activeBoosters: Array<ActiveBooster>;
  akirBalance: Scalars['Decimal'];
  akvBalance: Scalars['Decimal'];
  arcadeMachines: Array<ArcadeMachine>;
  arcadeParts: Array<ArcadePart>;
  collectibleItem: Array<CollectibleItem>;
  crafts: Array<Craft>;
  createdAt: Scalars['DateTime'];
  currencyDeposits: Array<CurrencyDeposit>;
  deposits: Array<Deposit>;
  email: Scalars['String'];
  extracts: Array<Extract>;
  frameSubCategory: Scalars['String'];
  gameCenters: Array<GameCenter>;
  iconSubCategory: Scalars['String'];
  iconType: IconType;
  id: Scalars['String'];
  junks: Array<Junk>;
  lockedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  notifications: Array<Notification>;
  ownedArcadeMachinePlaySessions: Array<PlaySession>;
  ownedGameCenterPlaySessions: Array<PlaySession>;
  paidTournamentEntries: Array<PaidTournamentEntry>;
  paidTournamentPrizeClaimIgnoreUser?: Maybe<PaidTournamentPrizeClaimIgnoreUser>;
  playSessions: Array<PlaySession>;
  questChains: Array<QuestChain>;
  rewards: Array<Reward>;
  terasBalance: Scalars['Decimal'];
  tickets: Scalars['Int'];
  titleSubCategory: Scalars['String'];
  walletAddress?: Maybe<Scalars['String']>;
};


export type UserBurnArgs = {
  cursor?: InputMaybe<BurnWhereUniqueInput>;
  distinct?: InputMaybe<Array<BurnScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BurnOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BurnWhereInput>;
};


export type UserDismantleArgs = {
  cursor?: InputMaybe<DismantleWhereUniqueInput>;
  distinct?: InputMaybe<Array<DismantleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DismantleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DismantleWhereInput>;
};


export type UserActiveBoosterForTournamentsArgs = {
  cursor?: InputMaybe<ActiveBoosterForTournamentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActiveBoosterForTournamentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActiveBoosterForTournamentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActiveBoosterForTournamentWhereInput>;
};


export type UserActiveBoostersArgs = {
  cursor?: InputMaybe<ActiveBoosterWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActiveBoosterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActiveBoosterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActiveBoosterWhereInput>;
};


export type UserArcadeMachinesArgs = {
  cursor?: InputMaybe<ArcadeMachineWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArcadeMachineScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArcadeMachineOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadeMachineWhereInput>;
};


export type UserArcadePartsArgs = {
  cursor?: InputMaybe<ArcadePartWhereUniqueInput>;
  distinct?: InputMaybe<Array<ArcadePartScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ArcadePartOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArcadePartWhereInput>;
};


export type UserCollectibleItemArgs = {
  cursor?: InputMaybe<CollectibleItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<CollectibleItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CollectibleItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CollectibleItemWhereInput>;
};


export type UserCraftsArgs = {
  cursor?: InputMaybe<CraftWhereUniqueInput>;
  distinct?: InputMaybe<Array<CraftScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CraftOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CraftWhereInput>;
};


export type UserCurrencyDepositsArgs = {
  cursor?: InputMaybe<CurrencyDepositWhereUniqueInput>;
  distinct?: InputMaybe<Array<CurrencyDepositScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CurrencyDepositOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CurrencyDepositWhereInput>;
};


export type UserDepositsArgs = {
  cursor?: InputMaybe<DepositWhereUniqueInput>;
  distinct?: InputMaybe<Array<DepositScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DepositOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DepositWhereInput>;
};


export type UserExtractsArgs = {
  cursor?: InputMaybe<ExtractWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExtractScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExtractOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExtractWhereInput>;
};


export type UserGameCentersArgs = {
  cursor?: InputMaybe<GameCenterWhereUniqueInput>;
  distinct?: InputMaybe<Array<GameCenterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GameCenterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GameCenterWhereInput>;
};


export type UserJunksArgs = {
  cursor?: InputMaybe<JunkWhereUniqueInput>;
  distinct?: InputMaybe<Array<JunkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<JunkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<JunkWhereInput>;
};


export type UserNotificationsArgs = {
  cursor?: InputMaybe<NotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserOwnedArcadeMachinePlaySessionsArgs = {
  cursor?: InputMaybe<PlaySessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaySessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaySessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type UserOwnedGameCenterPlaySessionsArgs = {
  cursor?: InputMaybe<PlaySessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaySessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaySessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type UserPaidTournamentEntriesArgs = {
  cursor?: InputMaybe<PaidTournamentEntryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaidTournamentEntryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaidTournamentEntryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaidTournamentEntryWhereInput>;
};


export type UserPaidTournamentPrizeClaimIgnoreUserArgs = {
  where?: InputMaybe<PaidTournamentPrizeClaimIgnoreUserWhereInput>;
};


export type UserPlaySessionsArgs = {
  cursor?: InputMaybe<PlaySessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlaySessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlaySessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type UserQuestChainsArgs = {
  cursor?: InputMaybe<QuestChainWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestChainScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestChainOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestChainWhereInput>;
};


export type UserRewardsArgs = {
  cursor?: InputMaybe<RewardWhereUniqueInput>;
  distinct?: InputMaybe<Array<RewardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RewardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RewardWhereInput>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  akirBalance?: Maybe<Scalars['Decimal']>;
  akvBalance?: Maybe<Scalars['Decimal']>;
  terasBalance?: Maybe<Scalars['Decimal']>;
  tickets?: Maybe<Scalars['Float']>;
};

export type UserAvgOrderByAggregateInput = {
  akirBalance?: InputMaybe<SortOrder>;
  akvBalance?: InputMaybe<SortOrder>;
  terasBalance?: InputMaybe<SortOrder>;
  tickets?: InputMaybe<SortOrder>;
};

export type UserCount = {
  __typename?: 'UserCount';
  Burn: Scalars['Int'];
  Dismantle: Scalars['Int'];
  activeBoosterForTournaments: Scalars['Int'];
  activeBoosters: Scalars['Int'];
  arcadeMachines: Scalars['Int'];
  arcadeParts: Scalars['Int'];
  collectibleItem: Scalars['Int'];
  crafts: Scalars['Int'];
  currencyDeposits: Scalars['Int'];
  currencyWithdrawals: Scalars['Int'];
  deposits: Scalars['Int'];
  extracts: Scalars['Int'];
  gameCenters: Scalars['Int'];
  googleOneTimePurchases: Scalars['Int'];
  junks: Scalars['Int'];
  magicSessions: Scalars['Int'];
  notifications: Scalars['Int'];
  ownedArcadeMachinePlaySessions: Scalars['Int'];
  ownedGameCenterPlaySessions: Scalars['Int'];
  paidTournamentEntries: Scalars['Int'];
  playSessions: Scalars['Int'];
  questChains: Scalars['Int'];
  refreshTokens: Scalars['Int'];
  rewards: Scalars['Int'];
  ticketTransactions: Scalars['Int'];
  withdrawals: Scalars['Int'];
};


export type UserCountBurnArgs = {
  where?: InputMaybe<BurnWhereInput>;
};


export type UserCountDismantleArgs = {
  where?: InputMaybe<DismantleWhereInput>;
};


export type UserCountActiveBoosterForTournamentsArgs = {
  where?: InputMaybe<ActiveBoosterForTournamentWhereInput>;
};


export type UserCountActiveBoostersArgs = {
  where?: InputMaybe<ActiveBoosterWhereInput>;
};


export type UserCountArcadeMachinesArgs = {
  where?: InputMaybe<ArcadeMachineWhereInput>;
};


export type UserCountArcadePartsArgs = {
  where?: InputMaybe<ArcadePartWhereInput>;
};


export type UserCountCollectibleItemArgs = {
  where?: InputMaybe<CollectibleItemWhereInput>;
};


export type UserCountCraftsArgs = {
  where?: InputMaybe<CraftWhereInput>;
};


export type UserCountCurrencyDepositsArgs = {
  where?: InputMaybe<CurrencyDepositWhereInput>;
};


export type UserCountCurrencyWithdrawalsArgs = {
  where?: InputMaybe<CurrencyWithdrawalWhereInput>;
};


export type UserCountDepositsArgs = {
  where?: InputMaybe<DepositWhereInput>;
};


export type UserCountExtractsArgs = {
  where?: InputMaybe<ExtractWhereInput>;
};


export type UserCountGameCentersArgs = {
  where?: InputMaybe<GameCenterWhereInput>;
};


export type UserCountGoogleOneTimePurchasesArgs = {
  where?: InputMaybe<GoogleOneTimePurchaseWhereInput>;
};


export type UserCountJunksArgs = {
  where?: InputMaybe<JunkWhereInput>;
};


export type UserCountMagicSessionsArgs = {
  where?: InputMaybe<MagicSessionWhereInput>;
};


export type UserCountNotificationsArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserCountOwnedArcadeMachinePlaySessionsArgs = {
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type UserCountOwnedGameCenterPlaySessionsArgs = {
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type UserCountPaidTournamentEntriesArgs = {
  where?: InputMaybe<PaidTournamentEntryWhereInput>;
};


export type UserCountPlaySessionsArgs = {
  where?: InputMaybe<PlaySessionWhereInput>;
};


export type UserCountQuestChainsArgs = {
  where?: InputMaybe<QuestChainWhereInput>;
};


export type UserCountRefreshTokensArgs = {
  where?: InputMaybe<RefreshTokenWhereInput>;
};


export type UserCountRewardsArgs = {
  where?: InputMaybe<RewardWhereInput>;
};


export type UserCountTicketTransactionsArgs = {
  where?: InputMaybe<TicketTransactionWhereInput>;
};


export type UserCountWithdrawalsArgs = {
  where?: InputMaybe<WithdrawalWhereInput>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  akirBalance: Scalars['Int'];
  akvBalance: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  frameSubCategory: Scalars['Int'];
  iconSubCategory: Scalars['Int'];
  iconType: Scalars['Int'];
  id: Scalars['Int'];
  lockedAt: Scalars['Int'];
  name: Scalars['Int'];
  terasBalance: Scalars['Int'];
  tickets: Scalars['Int'];
  titleSubCategory: Scalars['Int'];
  updatedAt: Scalars['Int'];
  walletAddress: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  akirBalance?: InputMaybe<SortOrder>;
  akvBalance?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  frameSubCategory?: InputMaybe<SortOrder>;
  iconSubCategory?: InputMaybe<SortOrder>;
  iconType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lockedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  terasBalance?: InputMaybe<SortOrder>;
  tickets?: InputMaybe<SortOrder>;
  titleSubCategory?: InputMaybe<SortOrder>;
  walletAddress?: InputMaybe<SortOrder>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
  akirBalance: Scalars['Decimal'];
  akvBalance: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  frameSubCategory: Scalars['String'];
  iconSubCategory: Scalars['String'];
  iconType: IconType;
  id: Scalars['String'];
  lockedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  terasBalance: Scalars['Decimal'];
  tickets: Scalars['Int'];
  titleSubCategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  walletAddress?: Maybe<Scalars['String']>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  akirBalance?: Maybe<Scalars['Decimal']>;
  akvBalance?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  frameSubCategory?: Maybe<Scalars['String']>;
  iconSubCategory?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  id?: Maybe<Scalars['String']>;
  lockedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  terasBalance?: Maybe<Scalars['Decimal']>;
  tickets?: Maybe<Scalars['Int']>;
  titleSubCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  walletAddress?: Maybe<Scalars['String']>;
};

export type UserMaxOrderByAggregateInput = {
  akirBalance?: InputMaybe<SortOrder>;
  akvBalance?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  frameSubCategory?: InputMaybe<SortOrder>;
  iconSubCategory?: InputMaybe<SortOrder>;
  iconType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lockedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  terasBalance?: InputMaybe<SortOrder>;
  tickets?: InputMaybe<SortOrder>;
  titleSubCategory?: InputMaybe<SortOrder>;
  walletAddress?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  akirBalance?: Maybe<Scalars['Decimal']>;
  akvBalance?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  frameSubCategory?: Maybe<Scalars['String']>;
  iconSubCategory?: Maybe<Scalars['String']>;
  iconType?: Maybe<IconType>;
  id?: Maybe<Scalars['String']>;
  lockedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  terasBalance?: Maybe<Scalars['Decimal']>;
  tickets?: Maybe<Scalars['Int']>;
  titleSubCategory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  walletAddress?: Maybe<Scalars['String']>;
};

export type UserMinOrderByAggregateInput = {
  akirBalance?: InputMaybe<SortOrder>;
  akvBalance?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  frameSubCategory?: InputMaybe<SortOrder>;
  iconSubCategory?: InputMaybe<SortOrder>;
  iconType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lockedAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  terasBalance?: InputMaybe<SortOrder>;
  tickets?: InputMaybe<SortOrder>;
  titleSubCategory?: InputMaybe<SortOrder>;
  walletAddress?: InputMaybe<SortOrder>;
};

export type UserNullableRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserSumOrderByAggregateInput>;
  akirBalance?: InputMaybe<SortOrder>;
  akvBalance?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  frameSubCategory?: InputMaybe<SortOrder>;
  iconSubCategory?: InputMaybe<SortOrder>;
  iconType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lockedAt?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  terasBalance?: InputMaybe<SortOrder>;
  tickets?: InputMaybe<SortOrder>;
  titleSubCategory?: InputMaybe<SortOrder>;
  walletAddress?: InputMaybe<SortOrderInput>;
};

export type UserOrderByWithRelationInput = {
  Burn?: InputMaybe<BurnOrderByRelationAggregateInput>;
  Dismantle?: InputMaybe<DismantleOrderByRelationAggregateInput>;
  activeBoosterForTournaments?: InputMaybe<ActiveBoosterForTournamentOrderByRelationAggregateInput>;
  activeBoosters?: InputMaybe<ActiveBoosterOrderByRelationAggregateInput>;
  akirBalance?: InputMaybe<SortOrder>;
  akvBalance?: InputMaybe<SortOrder>;
  arcadeMachines?: InputMaybe<ArcadeMachineOrderByRelationAggregateInput>;
  arcadeParts?: InputMaybe<ArcadePartOrderByRelationAggregateInput>;
  collectibleItem?: InputMaybe<CollectibleItemOrderByRelationAggregateInput>;
  crafts?: InputMaybe<CraftOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  currencyDeposits?: InputMaybe<CurrencyDepositOrderByRelationAggregateInput>;
  deposits?: InputMaybe<DepositOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  extracts?: InputMaybe<ExtractOrderByRelationAggregateInput>;
  frameSubCategory?: InputMaybe<SortOrder>;
  gameCenters?: InputMaybe<GameCenterOrderByRelationAggregateInput>;
  iconSubCategory?: InputMaybe<SortOrder>;
  iconType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  junks?: InputMaybe<JunkOrderByRelationAggregateInput>;
  lockedAt?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  notifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  ownedArcadeMachinePlaySessions?: InputMaybe<PlaySessionOrderByRelationAggregateInput>;
  ownedGameCenterPlaySessions?: InputMaybe<PlaySessionOrderByRelationAggregateInput>;
  paidTournamentEntries?: InputMaybe<PaidTournamentEntryOrderByRelationAggregateInput>;
  paidTournamentPrizeClaimIgnoreUser?: InputMaybe<PaidTournamentPrizeClaimIgnoreUserOrderByWithRelationInput>;
  playSessions?: InputMaybe<PlaySessionOrderByRelationAggregateInput>;
  questChains?: InputMaybe<QuestChainOrderByRelationAggregateInput>;
  rewards?: InputMaybe<RewardOrderByRelationAggregateInput>;
  terasBalance?: InputMaybe<SortOrder>;
  tickets?: InputMaybe<SortOrder>;
  titleSubCategory?: InputMaybe<SortOrder>;
  walletAddress?: InputMaybe<SortOrderInput>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  AkirBalance = 'akirBalance',
  AkvBalance = 'akvBalance',
  CreatedAt = 'createdAt',
  Email = 'email',
  FrameSubCategory = 'frameSubCategory',
  IconSubCategory = 'iconSubCategory',
  IconType = 'iconType',
  Id = 'id',
  LockedAt = 'lockedAt',
  Name = 'name',
  TerasBalance = 'terasBalance',
  Tickets = 'tickets',
  TitleSubCategory = 'titleSubCategory',
  UpdatedAt = 'updatedAt',
  WalletAddress = 'walletAddress'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  akirBalance?: InputMaybe<DecimalWithAggregatesFilter>;
  akvBalance?: InputMaybe<DecimalWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  frameSubCategory?: InputMaybe<StringWithAggregatesFilter>;
  iconSubCategory?: InputMaybe<StringWithAggregatesFilter>;
  iconType?: InputMaybe<EnumIconTypeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  lockedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  terasBalance?: InputMaybe<DecimalWithAggregatesFilter>;
  tickets?: InputMaybe<IntWithAggregatesFilter>;
  titleSubCategory?: InputMaybe<StringWithAggregatesFilter>;
  walletAddress?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  akirBalance?: Maybe<Scalars['Decimal']>;
  akvBalance?: Maybe<Scalars['Decimal']>;
  terasBalance?: Maybe<Scalars['Decimal']>;
  tickets?: Maybe<Scalars['Int']>;
};

export type UserSumOrderByAggregateInput = {
  akirBalance?: InputMaybe<SortOrder>;
  akvBalance?: InputMaybe<SortOrder>;
  terasBalance?: InputMaybe<SortOrder>;
  tickets?: InputMaybe<SortOrder>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  Burn?: InputMaybe<BurnListRelationFilter>;
  Dismantle?: InputMaybe<DismantleListRelationFilter>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activeBoosterForTournaments?: InputMaybe<ActiveBoosterForTournamentListRelationFilter>;
  activeBoosters?: InputMaybe<ActiveBoosterListRelationFilter>;
  akirBalance?: InputMaybe<DecimalFilter>;
  akvBalance?: InputMaybe<DecimalFilter>;
  arcadeMachines?: InputMaybe<ArcadeMachineListRelationFilter>;
  arcadeParts?: InputMaybe<ArcadePartListRelationFilter>;
  collectibleItem?: InputMaybe<CollectibleItemListRelationFilter>;
  crafts?: InputMaybe<CraftListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyDeposits?: InputMaybe<CurrencyDepositListRelationFilter>;
  deposits?: InputMaybe<DepositListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  extracts?: InputMaybe<ExtractListRelationFilter>;
  frameSubCategory?: InputMaybe<StringFilter>;
  gameCenters?: InputMaybe<GameCenterListRelationFilter>;
  iconSubCategory?: InputMaybe<StringFilter>;
  iconType?: InputMaybe<EnumIconTypeFilter>;
  id?: InputMaybe<UuidFilter>;
  junks?: InputMaybe<JunkListRelationFilter>;
  lockedAt?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  ownedArcadeMachinePlaySessions?: InputMaybe<PlaySessionListRelationFilter>;
  ownedGameCenterPlaySessions?: InputMaybe<PlaySessionListRelationFilter>;
  paidTournamentEntries?: InputMaybe<PaidTournamentEntryListRelationFilter>;
  paidTournamentPrizeClaimIgnoreUser?: InputMaybe<PaidTournamentPrizeClaimIgnoreUserNullableRelationFilter>;
  playSessions?: InputMaybe<PlaySessionListRelationFilter>;
  questChains?: InputMaybe<QuestChainListRelationFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
  terasBalance?: InputMaybe<DecimalFilter>;
  tickets?: InputMaybe<IntFilter>;
  titleSubCategory?: InputMaybe<StringFilter>;
  walletAddress?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  Burn?: InputMaybe<BurnListRelationFilter>;
  Dismantle?: InputMaybe<DismantleListRelationFilter>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activeBoosterForTournaments?: InputMaybe<ActiveBoosterForTournamentListRelationFilter>;
  activeBoosters?: InputMaybe<ActiveBoosterListRelationFilter>;
  akirBalance?: InputMaybe<DecimalFilter>;
  akvBalance?: InputMaybe<DecimalFilter>;
  arcadeMachines?: InputMaybe<ArcadeMachineListRelationFilter>;
  arcadeParts?: InputMaybe<ArcadePartListRelationFilter>;
  collectibleItem?: InputMaybe<CollectibleItemListRelationFilter>;
  crafts?: InputMaybe<CraftListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyDeposits?: InputMaybe<CurrencyDepositListRelationFilter>;
  deposits?: InputMaybe<DepositListRelationFilter>;
  email?: InputMaybe<Scalars['String']>;
  extracts?: InputMaybe<ExtractListRelationFilter>;
  frameSubCategory?: InputMaybe<StringFilter>;
  gameCenters?: InputMaybe<GameCenterListRelationFilter>;
  iconSubCategory?: InputMaybe<StringFilter>;
  iconType?: InputMaybe<EnumIconTypeFilter>;
  id?: InputMaybe<Scalars['String']>;
  junks?: InputMaybe<JunkListRelationFilter>;
  lockedAt?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  ownedArcadeMachinePlaySessions?: InputMaybe<PlaySessionListRelationFilter>;
  ownedGameCenterPlaySessions?: InputMaybe<PlaySessionListRelationFilter>;
  paidTournamentEntries?: InputMaybe<PaidTournamentEntryListRelationFilter>;
  paidTournamentPrizeClaimIgnoreUser?: InputMaybe<PaidTournamentPrizeClaimIgnoreUserNullableRelationFilter>;
  playSessions?: InputMaybe<PlaySessionListRelationFilter>;
  questChains?: InputMaybe<QuestChainListRelationFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
  terasBalance?: InputMaybe<DecimalFilter>;
  tickets?: InputMaybe<IntFilter>;
  titleSubCategory?: InputMaybe<StringFilter>;
  walletAddress?: InputMaybe<Scalars['String']>;
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type UuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type UuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type UuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

export type WithdrawAkvInput = {
  amount: Scalars['Decimal'];
};

export type WithdrawArcadeMachineInput = {
  ids: Array<Scalars['String']>;
};

export type WithdrawArcadePartInput = {
  ids: Array<Scalars['String']>;
};

export type WithdrawGameCenterInput = {
  ids: Array<Scalars['String']>;
};

export enum WithdrawalState {
  Confirmed = 'CONFIRMED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Unprocessed = 'UNPROCESSED'
}

export enum WithdrawalType {
  Mint = 'MINT',
  Transfer = 'TRANSFER'
}

export type WithdrawalWhereInput = {
  AND?: InputMaybe<Array<WithdrawalWhereInput>>;
  NOT?: InputMaybe<Array<WithdrawalWhereInput>>;
  OR?: InputMaybe<Array<WithdrawalWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  errorMessage?: InputMaybe<StringNullableFilter>;
  hash?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  nftType?: InputMaybe<EnumNftTypeFilter>;
  nonce?: InputMaybe<IntNullableFilter>;
  response?: InputMaybe<StringNullableFilter>;
  signerAddress?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<EnumWithdrawalStateFilter>;
  tokenId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumWithdrawalTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserNullableRelationFilter>;
  userId?: InputMaybe<UuidNullableFilter>;
  walletAddress?: InputMaybe<StringFilter>;
};

export type LatestPlayQueryVariables = Types.Exact<{
  where: Types.PlaySessionWhereUniqueInput;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  orderBy?: Types.InputMaybe<Array<Types.PlayOrderByWithRelationInput> | Types.PlayOrderByWithRelationInput>;
}>;


export type LatestPlayQuery = { __typename?: 'Query', playSession?: { __typename?: 'PlaySession', fever: boolean, plays: Array<{ __typename?: 'Play', score?: number | null | undefined, playerTerasReward?: any | null | undefined, megaSpark: boolean }> } | null | undefined };


export const LatestPlayDocument = gql`
    query latestPlay($where: PlaySessionWhereUniqueInput!, $take: Int, $orderBy: [PlayOrderByWithRelationInput!]) {
  playSession(where: $where) {
    plays(take: $take, orderBy: $orderBy) {
      score
      playerTerasReward
      megaSpark
    }
    fever
  }
}
    `;

/**
 * __useLatestPlayQuery__
 *
 * To run a query within a React component, call `useLatestPlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestPlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestPlayQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLatestPlayQuery(baseOptions: Apollo.QueryHookOptions<LatestPlayQuery, LatestPlayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestPlayQuery, LatestPlayQueryVariables>(LatestPlayDocument, options);
      }
export function useLatestPlayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestPlayQuery, LatestPlayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestPlayQuery, LatestPlayQueryVariables>(LatestPlayDocument, options);
        }
export type LatestPlayQueryHookResult = ReturnType<typeof useLatestPlayQuery>;
export type LatestPlayLazyQueryHookResult = ReturnType<typeof useLatestPlayLazyQuery>;
export type LatestPlayQueryResult = Apollo.QueryResult<LatestPlayQuery, LatestPlayQueryVariables>;