import { Magic } from 'magic-sdk'
import { LocalStorageService } from './LocalStorageService'
import { auth } from './FirebaseService'
import { signOut } from '@firebase/auth'

const getMagicInstance = () => new Magic(process.env.NEXT_PUBLIC_MAGIC_API_KEY)

export const AuthService = {
  isLoggedIn: () => {
    return (
      !!LocalStorageService.loadRefreshToken() ||
      (!!auth.currentUser && !LocalStorageService.loadAccountLock())
    )
  },
  signUp: (email: string) => {
    LocalStorageService.removeAccountLock()
    return getMagicInstance()
      .auth.loginWithEmailOTP({ email })
      .then((didToken) => !!didToken)
      .catch((error) => {
        console.error(error)
        return false
      })
  },
  logOut: async () => {
    await signOut(auth)
    await AuthService.removeTokens()
  },
  getAuthorizationToken: async () => {
    const accessToken = await auth.currentUser?.getIdToken()
    if (accessToken) {
      return `Bearer ${accessToken}`
    }

    return ''
  },
  getRefreshToken: () => {
    return LocalStorageService.loadRefreshToken()
  },
  removeTokens: async () => {
    LocalStorageService.removeAccessToken()
    LocalStorageService.removeRefreshToken()
  },
}
