import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { useListGamesQuery } from './query.generated'

export type GameInfo = {
  id: string
  name: string
  category: string
  rule: string
  help: {
    description: string
    howTo: string
  }
  gamePath: string
  hotGame: boolean
  enabled: boolean
  onlyTournament: boolean
}

const GameContext = React.createContext<{
  games: Array<GameInfo>
  allGames: Array<GameInfo>
  reload: () => Promise<unknown>
}>({} as any)

export const useGameInfo = () => useContext(GameContext)

export const GameProvider = ({ children }: PropsWithChildren) => {
  const [games, setGames] = useState<Array<GameInfo>>([])
  const [version, setVersion] = useState<string | null>(null)

  const { data, refetch } = useListGamesQuery({ variables: { version } })
  const reload = useCallback(() => {
    return refetch()
  }, [refetch])

  useEffect(() => {
    if (!data || !data.listGames) return
    const listGames = data.listGames
    setGames(
      listGames.games.map((game) => ({
        ...game,
        rule: capitalizeFirstLetter(game.winCondition),
      })),
    )
    setVersion(listGames.version)
  }, [data, setGames])

  return (
    <GameContext.Provider value={{ games: games.filter((e) => e.enabled), allGames: games, reload: reload }}>
      {children}
    </GameContext.Provider>
  )
}

const capitalizeFirstLetter = (str: string) => {
  if (str.length === 0) return str
  const firstChar = str.charAt(0).toUpperCase()
  const remainingChars = str.slice(1).toLowerCase()
  return firstChar + remainingChars
}
