import { S3_URL } from '../constants'
import { CollectibleItemCategory, IconType, RewardCategory, RewardItemType } from '../graphql/generated/types'

export const S3URLService = {
  getRewardURL: (
    rewardItemType: RewardItemType | string,
    category?: RewardCategory | string | null,
    subCategory?: string | null,
  ) => {
    if (!rewardItemType || !category || !subCategory) return ''

    // AP
    if (rewardItemType === 'ARCADE_PART') {
      return S3URLService.getArcadePartsURL(category, subCategory)
    }

    // JUNK
    if (rewardItemType === 'JUNK_PART') {
      return S3URLService.getArcadePartsJunkURL(category, subCategory)
    }

    // collectible_items
    if (rewardItemType === 'COLLECTIBLE_ITEM') {
      if (category === 'ICON') {
        return S3URLService.getIconURL(IconType.InWorld, subCategory)
      } else if (category === 'FRAME') {
        return S3URLService.getFrameURL(subCategory)
      } else if (['ROM', 'ACCUMULATOR', 'UPPER_CABINET', 'LOWER_CABINET'].includes(category)) {
        return S3URLService.getArcadePartsURL(category, subCategory)
      } else if (category === 'TITLE') {
        return S3URLService.getTitleURL(subCategory)
      }
    }

    return ''
  },
  getArcadePartsRandomURL: () => `${S3_URL}/arcadeparts/ARCADE_PART_RANDOM.png`,
  getJunkPartsRandomURL: () => `${S3_URL}/arcadeparts/JUNK_PART_RANDOM.png`,
  getArcadePartsURL: (category: string, subCategory: string) =>
    `${S3_URL}/arcadeparts/${category}/${subCategory}.png`,
  getArcadePartsJunkURL: (category: string, subCategory: string) =>
    `${S3_URL}/arcadeparts/${category}/${subCategory}_JUNK.png`,
  getIconURL: (iconType: IconType, subCategory: string) =>
    `${S3_URL}/collectible_items/${CollectibleItemCategory.Icon}/${iconType}/${subCategory}.png`,
  getFrameURL: (subCategory: string) => `${S3_URL}/collectible_items/FRAME/${subCategory}.png`,
  getTitleURL: (subCategory: string) => `${S3_URL}/collectible_items/TITLE/${subCategory}.png`,

  // ゲームリソース取得
  getGameResources: (game: string) => ({
    imageUrl: S3URLService.getGameThumbnailImageURL(game),
    movieUrl: S3URLService.getGamePlayMovieURL(game),
    resultMovie: S3URLService.getGameResultMovieURL(game),
    machineImage: S3URLService.getGameBackgroundImageURL(game),
    inOperationMachineImage: S3URLService.getGameInOperationImageURL(game),
  }),
  getGameThumbnailImageURL: (game: string) => `${S3_URL}/games/${game}/thumbnail.png`,
  getGameBackgroundImageURL: (game: string) => `${S3_URL}/games/${game}/background.png`,
  getGameInOperationImageURL: (game: string) => `${S3_URL}/games/${game}/in_operation.png`,
  getGamePlayMovieURL: (game: string) => `${S3_URL}/games/${game}/play.mp4`,
  getGameResultMovieURL: (game: string) => `${S3_URL}/games/${game}/result.mp4`,
}
