import '../styles/globals.css'

import { RecoilRoot } from 'recoil'
import type { AppProps } from 'next/app'
import { I18nProvider } from '../i18n/I18nProvider'
import Head from 'next/head'
import { Head as TitleHead } from '../components/parts/commons/Head'
import { VStack } from '../components/parts/bases/VStack'
import { Fixed } from '../components/parts/bases/Fixed'
import { SplashProvider } from '../components/providers/SplashProvider'
import { AccessBlockingProvider } from '../components/providers/AccessBlockingProvider'
import { OdencanSDKLoader } from '../libs/OdencanSDK'
import { ApolloProviderWrapper } from '../graphql/ApolloProviderWrapper'
import { LoginUserProvider } from '../stores/LoginUser'
import GoogleTagManager, { GoogleTagManagerId } from '../components/parts/commons/GoogleTagManager'
import { googleTagManagerId } from '@/src/services/AnalyticsService'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'swiper/css'
import 'swiper/css/pagination'
import { FirebaseUserProvider } from '../stores/FirebaseUser'
import { GameProvider } from '../stores/Games/useGames'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(relativeTime)

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" /> */}
        <meta name="viewport" content="width=device-width, user-scalable=no, viewport-fit=contain" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      </Head>

      <GoogleTagManager googleTagManagerId={googleTagManagerId as GoogleTagManagerId} />

      <main className="flex min-h-screen min-w-full justify-center overflow-hidden bg-black bg-[url('/images/grid.png')] bg-repeat p-0">
        <div className="min-h-screen w-full">
          <VStack className="min-h-screen w-full">
            <Fixed className="left-0 top-0 z-0 h-full w-full bg-[url('/images/bgCyan.png')] bg-cover bg-center bg-no-repeat" />
            <Fixed className="right-0 top-0 z-0 h-full w-full bg-[url('/images/bgPurple.png')] bg-contain bg-right-top bg-no-repeat" />
            <OdencanSDKLoader>
              <I18nProvider>
                <RecoilRoot>
                  <ApolloProviderWrapper>
                    <SplashProvider>
                      <AccessBlockingProvider>
                        <VStack className="z-10 h-full w-full">
                          <FirebaseUserProvider>
                            <LoginUserProvider>
                              <GameProvider>
                                <TitleHead />
                                <Component {...pageProps} />
                              </GameProvider>
                            </LoginUserProvider>
                          </FirebaseUserProvider>
                        </VStack>
                      </AccessBlockingProvider>
                    </SplashProvider>
                  </ApolloProviderWrapper>
                </RecoilRoot>
              </I18nProvider>
            </OdencanSDKLoader>
          </VStack>
        </div>
      </main>
    </>
  )
}

export default App
