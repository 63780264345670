const LOCAL_STORAGE_KEYS = {
  PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP: 'PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  EMAIL_SIGNIN: 'EMAIL_SIGNIN',
  EMAIL_LOGIN: 'EMAIL_LOGIN',
  PLAY_CAMPAIGN_DISPLAY: 'PLAY_CAMPAIGN_DISPLAY',
  CRAFT_CAMPAIGN_DISPLAY: 'CRAFT_CAMPAIGN_DISPLAY',
  ACCOUNT_LOCK: 'ACCOUNT_LOCK',
}

export const LocalStorageService = {
  savePrivacyPolicyAgreementKey: (key: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP, key)
  },
  loadPrivacyPolicyAgreementKey: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP)
  },
  removePrivacyPolicyAgreementKey: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.PRIVAY_POLICY_AGREEMENT_UNIXTIMESTAMP)
  },
  saveAccessToken: (token: string | undefined) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token || '')
  },
  loadAccessToken: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || ''
  },
  removeAccessToken: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
  },
  saveRefreshToken: (token: string | undefined) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token || '')
  },
  loadRefreshToken: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || ''
  },
  removeRefreshToken: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
  },
  saveEmailSignin: (token: string | undefined) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL_SIGNIN, token || '')
  },
  loadEmailSignin: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL_SIGNIN) || ''
  },
  removeEmailSignin: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.EMAIL_SIGNIN)
  },
  saveEmailLogin: (token: string | undefined) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL_LOGIN, token || '')
  },
  loadEmailLogin: () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL_LOGIN) || ''
  },
  removeEmailLogin: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.EMAIL_LOGIN)
  },
  savePlayCampaignDisplay: (playCount: number) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.PLAY_CAMPAIGN_DISPLAY, String(playCount))
  },
  loadPlayCampaignDisplay: (): number => {
    return Number(window.localStorage.getItem(LOCAL_STORAGE_KEYS.PLAY_CAMPAIGN_DISPLAY)) || 0
  },
  removePlayCampaignDisplay: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.PLAY_CAMPAIGN_DISPLAY)
  },
  saveCraftCampaignDisplay: (craftCount: number) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.CRAFT_CAMPAIGN_DISPLAY, String(craftCount))
  },
  loadCraftCampaignDisplay: (): number => {
    return Number(window.localStorage.getItem(LOCAL_STORAGE_KEYS.CRAFT_CAMPAIGN_DISPLAY)) || 0
  },
  removeCraftCampaignDisplay: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.CRAFT_CAMPAIGN_DISPLAY)
  },
  saveAccountLock: () => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCOUNT_LOCK, String(true))
  },
  loadAccountLock: (): boolean => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT_LOCK) === 'true'
  },
  removeAccountLock: () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCOUNT_LOCK)
  },
}
